/*----------------------------------------*/
/*  13 - Components - Slider
/*----------------------------------------*/
/* ---Home Slider--- */
.home-slider{
	.swiper-slide{
		@include flexCenter;
		&.bg-1,
		&.bg-2,
		&.bg-3{
			background-image: url('../images/slider/bg/default/1.jpg');
			background-color: $white-color;
			background-repeat: no-repeat;
			background-size: cover;
			height: 620px;
			@include breakpoint(max-sm_device){
				height: 410px;
			}
			&.bg-2{
				background-image: url('../images/slider/bg/default/2.jpg');
			}
			&.bg-3{
				background-image: url('../images/slider/bg/default/3.jpg');
			}
		}
		.slide-content{
			text-align: center;
			h1{
				font-family: 'Work Sans';
				&.heading{
					@include typography(48px, 48px);
					padding-bottom: 5px;
					@include breakpoint(max-sm_device){
						font-size: 25px;
						padding-bottom: 15px;
					}
				}
				&.sub-heading{
					padding-bottom: 35px;
					@include breakpoint(max-sm_device){
						font-size: 25px;
					}
				}
			}
			p{
				&.short-desc{
					@include typography(24px, 24px);
					color: $text-color;
					padding-bottom: 75px;
					@include breakpoint(max-sm_device){
						font-size: 20px;
					}
				}
			}
		}
	}
}

/* ---Baroque Single Item--- */
.ba-single_item{
	.slide-content{
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		@include breakpoint(max-sm_device){
			flex-direction: column;
		}
		.slide-meta{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include breakpoint(max-sm_device){
				padding-bottom: 10px;
			}
			ul{
				&.vertical-social_link{
					position: absolute;
					bottom: 45px;
					left: 80px;
					@include breakpoint(max-lg_device){
						left: 40px;
					}
					@include breakpoint(max-sm_device){
						bottom: 45px;
						left: auto;
						right: 20px;
						li{
							display: inline-block;
							&:not(:last-child){
								padding-right: 25px;
							}
						}
					}
				}
			}
		}
		.slide-text-with_nav{
			display: flex;
			justify-content: space-between;
			text-align: left;
			width: 85%;
			@include breakpoint(max-xs_device){
				width: 100%;
			}
			span{
				&.category{
					@include typography(13px, 13px);
					padding-bottom: 30px;
					display: block;
				}
			}
			.inner-text{
				h1{
					&.heading{
						max-width: 65%;
						@include typography(63px, 63px);
						letter-spacing: -2px;
						padding-bottom: 65px;
						@include breakpoint(max-lg_device){
							font-size: 40px;
							line-height: 1.2;
						}
						@include breakpoint(max-xs_device){
							max-width: 95%;
						}
					}
				}
				.btn-wrap{
					display: inline-block;
					vertical-align: text-bottom;
					padding-right: 15px;
					a{
						@include typography(14px, 14px);
						&:hover{
							color: $white-color;
						}
						i{
							vertical-align: middle;
							font-size: 10px;
						}
					}
				}
			}
			.page-nav {
				position: absolute;
				transform: translateY(120px);
				display: inline-block;
				@include breakpoint(max-sm_device){
					position: relative;
					transform: none;
				}
				a{
					i{
						font-size: 30px;
						@include breakpoint(max-sm_device){
							display: block;
							font-size: 18px;
						}
					}
				}
			}
			.slide-nav_count {
				@include breakpoint(max-sm_device){
					transform: none;
					right: 20px;
					top: 20px;
				}
			}
		}
	}
}

/* ---Minimal Home Slider--- */
.minimal-home_slider{
	.swiper-slide{
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		&.bg-1,
		&.bg-2,
		&.bg-3{
			background-image: url('../images/slider/bg/minimal/1.jpg');
			background-color: $white-color;
			background-repeat: no-repeat;
			background-position: bottom;
			background-size: cover;
			height: 710px;
			@include breakpoint(max-sm_device){
				height: 410px;
			}
			&.bg-2{
				background-image: url('../images/slider/bg/minimal/2.jpg');
			}
			&.bg-3{
				background-image: url('../images/slider/bg/minimal/3.jpg');
			}
		}
	}
}
.mh-additional_content{
	background-color: $white-color;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
	padding: 40px;
	cursor: pointer;
	@include breakpoint(min-xxl_device){
		width: 945px;
	}
	@include breakpoint(min-xl_device){
		width: 865px;
	}
	@include breakpoint(max-lg_device){
		width: 50%;
	}
	@include breakpoint(max-sm_device){
		position: relative;
		width: 100%;
		padding: 40px 20px 0;
		margin-bottom: -10px;
	}
	.swiper-slide{
		a{
			span{
				@extend %basetransition;
				display: block;
				&.heading{
					@include typography(20px, 32px, 600);
					letter-spacing: -0.2px;
					max-width: 71%;
					@include breakpoint(max-lg_device){
						max-width: 90%;
						font-size: 21px;
					}
				}
			}
			&:hover{
				span{
					color: $secondary-color;
				}
			}
		}
		&.swiper-slide-thumb-active{
			a{
				span{
					color: $secondary-color;
				}
			}
		}
	}
}

/* ---Home Verticle Slider--- */
.slider-area-3{
	.container {
		@media (max-width: 1366px){
			width: 100%;
			padding-right: 100px;
			padding-left: 80px;
			max-width: 100% !important;
		}
		@include breakpoint(max-lg_device){
			padding-left: 40px;
			padding-right: 40px;
		}
		@include breakpoint(max-sm_device){
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
.home-verticle_slider{
	width: 100%;
	height: 100vh;
	.swiper-wrapper{
		height: 100% !important;
		.swiper-slide{
			height: 100% !important;
			display: flex;
			.slide-content{
				display: flex;
				flex-direction: column;
				justify-content: center;
				z-index: 2;
				position: relative;
				h2{
					&.heading{
						@include typography(72px, 72px);
						background-color: $white-color;
						margin-bottom: 15px;
						padding: 15px;
						@include breakpoint(max-sm_device){
							font-size: 24px;
							line-height: 24px;
							letter-spacing: -0.5px;
							padding: 10px;
						}
					}
				}
				span{
					&.category{
						@include typography(18px, 28px);
						color: $secondary-color;
						padding-bottom: 65px;
						display: block;
						@include breakpoint(max-sm_device){
							padding-bottom: 15px;
							font-size: 14px;
							line-height: 22.4px;
						}
					}
				}
				p{
					@include breakpoint(max-sm_device){
						display: none;
					}
					&.short-desc{
						padding-bottom: 85px;
						max-width: 53%;
						&.different-width{
							max-width: 42%;
						}
					}
				}
				.btn-wrap{
					a{
						color: $secondary-color;
						font-weight: 500;
						text-transform: uppercase;
						&:hover{
							color: $primary-color;
						}
					}
				}
			}
			.slide-img{
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				@include breakpoint(max-lg_device){
					width: 74%;
				}
				@include breakpoint(max-md_device){
					width: 85%;
				}
				@include breakpoint(max-sm_device){
					width: 80%;
				}
			}
		}
	}
}

/* ---Home Parallax Slider--- */
.parallax-home_slider{
	.swiper-slide{
		display: flex;
		flex-direction: column;
		justify-content: center;
		&.bg-1,
		&.bg-2,
		&.bg-3,
		&.bg-4,
		&.bg-5{
			background-image: url('../images/slider/bg/parallax/1.jpg');
			background-color: $white-color;
			background-repeat: no-repeat;
			background-position: bottom;
			background-size: cover;
			height: 100vh;
			&.bg-2{
				background-image: url('../images/slider/bg/parallax/2.jpg');
			}
			&.bg-3{
				background-image: url('../images/slider/bg/parallax/3.jpg');
			}
			&.bg-4{
				background-image: url('../images/slider/bg/parallax/4.jpg');
			}
			&.bg-5{
				background-image: url('../images/slider/bg/parallax/5.jpg');
			}
		}
		.slide-content{
			.slide-nav_count{
				@include breakpoint(max-lg_device){
					right: 40px;
				}
				@include breakpoint(max-sm_device){
					transform: none;
					right: 20px;
					top: 60%;
				}
			}
			.inner-text{
				h1{
					&.heading{
						@include breakpoint(max-sm_device){
							padding-bottom: 30px;
						}
					}
				}
				.btn-wrap{
					a{
						&:hover{
							color: inherit;
						}
					}
				}
			}
		}
	}
	.swiper-pagination{
		@include breakpoint(max-lg_device){
			display: none;
		}
	}
}

/* ---Home Onepage Slider--- */
.slider-area-5{
	display: flex;
	.home-onepage_slider{
		flex-grow: 1;
		width: calc(100% - 140px);
		@include breakpoint(max-sm_device){
			width: calc(100% - 70px);
		}
	}
	.additional-content{
		width: 140px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		@include breakpoint(max-sm_device){
			width: 70px;
		}
		p{
			&.short-desc{
				writing-mode: tb-rl;
			}
		}
	}
}
.home-onepage_slider{
	.swiper-slide{
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		&.bg-1,
		&.bg-2,
		&.bg-3{
			background-image: url('../images/slider/bg/onepage/1.jpg');
			background-color: $white-color;
			background-repeat: no-repeat;
			background-position: bottom;
			background-size: cover;
			height: 580px;
			position: relative;
			@include breakpoint(max-sm_device){
				height: 410px;
			}
			&:before{
				background-color: $text-color;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				content: "";
				position: absolute;
				opacity: 0.2;
			}
			&.bg-2{
				background-image: url('../images/slider/bg/onepage/2.jpg');
			}
			&.bg-3{
				background-image: url('../images/slider/bg/onepage/3.jpg');
			}
		}
		.slide-content{
			.inner-text{
				h1{
					&.heading{
						@include breakpoint(max-sm_device){
							font-size: 28px;
						}
					}
				}
			}
			.slide-nav_count{
				right: 80px;
				@include breakpoint(max-lg_device){
					right: 40px;
				}
			}
		}
	}
}

/* ---Home Zoom Slider--- */
.home-zoom_slider{
	width: 100%;
	height: 100vh;
	.swiper-slide{
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		&.bg-1,
		&.bg-2,
		&.bg-3{
			height: 100vh;
			&:before{
				background-image: url('../images/slider/bg/zoom/1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
			}
			&.bg-2{
				&:before{
					background-image: url('../images/slider/bg/zoom/2.jpg');
				}
			}
			&.bg-3{
				&:before{
					background-image: url('../images/slider/bg/zoom/3.jpg');
				}
			}
		}
		&.swiper-slide-next{
			&.bg-1,
			&.bg-2,
			&.bg-3{
				&:before{
					transition: 2s ease-in-out;
					transform: scale(0.5);
					
				}
			}
		}
		&.swiper-slide-prev{
			&.bg-1,
			&.bg-2,
			&.bg-3{
				&:before{
					transition: 2s ease-in-out;
					transform: scale(0.5);
					
				}
			}
		}
		&.swiper-slide-active{
			&.bg-1,
			&.bg-2,
			&.bg-3{
				&:before{
					transform: scale(1);
					transition: 2s ease-in-out;
				}
			}
		}
		.slide-content{
			.inner-content{
				max-width: 66%;
				@include breakpoint(min-xxl_device){
					max-width: 39%;
				}
				@include breakpoint(max-xxs_device){
					max-width: 100%;
				}
				h1{
					&.heading{
						@include typography(90px, 90px);
						padding-bottom: 35px;
						@include breakpoint(max-lg_device){
							font-size: 30px;
							line-height: 1.2;
							padding-bottom: 10px;
						}
					}
				}
				span{
					@include typography(24px, 38.4px);
					@include breakpoint(max-lg_device){
						font-size: 18px;
						line-height: 32.4px;
					}
				}
			}
		}
	}
	.swiper-arrow_wrap {
		&.bottom-left{
			bottom: 120px;
			left: 80px;
			@include breakpoint(max-lg_device){
				bottom: 135px;
				left: 40px;
			}
			@include breakpoint(max-sm_device){
				left: 20px;
			}
			@include breakpoint(max-xxs_device){
				bottom: 185px;
			}
			.swiper-button-next{
				left: 50px;
			}
			.swiper-button-next,
			.swiper-button-prev{
				&:after{
					color: $very-light-grey_color;
					font-size: 30px;
				}
			}
		}
	}
}