/*----------------------------------------*/
/*  20 - Components - Project
/*----------------------------------------*/
/* ---Latest Project Area--- */
.latest-project_area{
    .project-title_wrap{
        align-items: flex-end;
        padding-bottom: 40px;
        .project-title{
            h2{
                &.heading{
                    max-width: 35%;
                    @include breakpoint(max-md_device){
                        max-width: 100%;
                    }
                    @include breakpoint(max-xs_device){
                        padding-bottom: 10px;
                    }
                }
            }
        }
        .btn-wrap{
            .project-btn{
                text-transform: uppercase;
                color: $secondary-color;
            }
        }
    }
    .latest-project_slider{
        .swiper-pagination{
            @include breakpoint(max-md_device){
                position: relative;
            }
        }
    }
}
/* ---List Project Item--- */
.list-project_item{
    @include breakpoint(max-md_device){
        padding-bottom: 20px;
    }
    .single-content{
        .inner-content{
            background-color: $white-color;
            margin-top: -150px;
            padding: 40px 50px 0;
            @include breakpoint(max-md_device){
                padding: 20px 0 0;
                margin-top: 0;
            }
            span{
                @include typography(18px, 28.8px);
                padding-bottom: 15px;
                display: block;
            }
            h2{
                &.heading{
                    @include typography(48px, 48px);
                    padding-bottom: 15px;
                    letter-spacing: -1px;
                    @include breakpoint(max-lg_device){
                        font-size: 25px;
                        line-height: 33px;
                    }
                    @include breakpoint(max-sm_device){
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
    .btn-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        @include breakpoint(max-md_device){
            justify-content: flex-start;
            padding-top: 15px;
        }
        .project-btn{
            @include typography(20px, 32px);
            font-family: 'Work Sans';
            color: $secondary-color;
            i{
                color: $secondary-color;
                font-size: 15px;
            }
        }
    }
}

/* ---Project Item--- */
.project-item{
    .single-content{
        span{
            &.project-category{
                @include typography(14px, 22.4px);
                text-transform: uppercase;
                color: $nobel-color;
                padding-top: 15px;
                display: block;
            }
        }
        h3{
            &.heading{
                padding-top: 5px;
                a{
                    @include typography(24px, 28.8px);
                    color: $secondary-color;
                    letter-spacing: -0.5px;
                    @include breakpoint(max-sm_device){
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

/* ---Project Carousel Slider--- */
.project-carousel_slider{
    .swiper-slide {
        width: 60%;
        @include breakpoint(max-md_device){
            width: 100%;
        }
        &:nth-child(2n){
            @include breakpoint(min-lg_device){
                width: 60%;
            }
        }
        &:nth-child(3n){
            @include breakpoint(min-lg_device){
                width: 40%;
            }
        }
        .project-item{
            width: 100%;
            height: 100vh;
            .single-img{
                width: 100%;
                height: 100vh;
                .inner-img{
                    width: 100%;
                    height: 100vh;
                }
            }
        }
    }
    .swiper-scrollbar{
        border-radius: 0;
        height: 8px;
        position: relative;
        left: 0;
        bottom: 0;
        width: 85%;
        margin: 50px auto 0;
        cursor: pointer;
        .swiper-scrollbar-drag{
            background-color: $primary-color;
            border-radius: 0;
        }
    }
}

/* ---Project Parallax Slider--- */
.project-parallax_slider{
    width: 100%;
    height: 100vh;
    .swiper-wrapper{
        height: 100%;
        .swiper-slide{
            height: 100%;
            .project-item{
                &.project-bg-1,
                &.project-bg-2,
                &.project-bg-3,
                &.project-bg-4{
                    background-image: url('../images/project/bg/1-1880x1080.jpg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    height: 100vh;
                    position: relative;
                    &.project-bg-2{
                        background-image: url('../images/project/bg/2-1880x1080.jpg');
                    }
                    &.project-bg-3{
                        background-image: url('../images/project/bg/3-1880x1080.jpg');
                    }
                    &.project-bg-4{
                        background-image: url('../images/project/bg/4-1880x1080.jpg');
                    }
                }
                .single-content{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: 100%;
                    @include breakpoint(max-xs_device){
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-end;
                    }
                    .inner-content{
                        background-color: $white-color;
                        padding: 40px 50px;
                        width: 680px;
                        @include breakpoint(max-md_device){
                            width: 460px;
                        }
                        @include breakpoint(max-sm_device){
                            width: 320px;
                            padding: 20px 25px;
                        }
                        @include breakpoint(max-xs_device){
                            width: 100%;
                        }
                        span{
                            padding-bottom: 15px;
                            display: block;
                        }
                        h1{
                            &.heading{
                                @include typography(40px, 1.2);
                                letter-spacing: -1px;
                                text-transform: uppercase;
                                @include breakpoint(max-md_device){
                                    font-size: 25px;
                                    line-height: 1.4;
                                }
                                @include breakpoint(max-sm_device){
                                    font-size: 18px;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                    .btn-wrap{
                        display: flex;
                        align-items: flex-end;
                        @include breakpoint(max-xs_device){
                            background-color: $white-color;
                            width: 100%;
                        }
                        .project-btn{
                            background-color: $white-color;
                            color: $secondary-color;
                            padding: 0 30px;
                            height: 64px;
                            @include typography(18px, 64px);
                            text-transform: uppercase;
                            @include breakpoint(max-sm_device){
                                font-size: 16px;
                                text-transform: capitalize;
                                i{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Project Details Area--- */
.project-details_area{
    .pd-content{
        background-color: $white-color;
        padding: 40px 50px 0;
        margin-top: -160px;
        width: 680px;
        @include breakpoint(max-md_device){
            padding: 40px 0 0;
        }
        @include breakpoint(max-sm_device){
            padding: 20px 0 0;
            margin-top: 0;
            width: 100%;
        }
        span{
            padding-bottom: 15px;
            display: block;
        }
        h1{
            &.heading{
                padding-bottom: 40px;
            }
        }
        p{
            &.short-desc{
                padding-bottom: 45px;
            }
        }
        h3{
            &.sub-heading{
                @include typography(20px, 24px);
                padding-bottom: 50px;
            }
        }
    }
    .sidebar-area{
        @include breakpoint(max-md_device){
            padding-top: 90px;
        }
        .project-meta{
            .single-item{
                @include breakpoint(max-md_device){
                    text-align: left;
                }
            }
        }
    }
    .single-project_slider{
        padding-top: 95px;
        @include breakpoint(max-md_device){
            padding-top: 100px;
        }
        .single-project{
            &.bg-1,
            &.bg-2,
            &.bg-3{
                background-image: url('../images/project/bg/1-1170x570.jpg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                height: 570px;
                &.bg-2{
                    background-image: url('../images/project/bg/2-1170x570.jpg');
                }
                &.bg-3{
                    background-image: url('../images/project/bg/3-1170x570.jpg');
                }
            }
        }
    }
}

/* ---Product Details Style Two--- */
.pd-style_two{
    padding-bottom: 20px;
}

/* ---Product Details Style Three--- */
.pd-style_three{
    padding-right: 460px;
    padding-top: 85px;
    @include breakpoint(max-md_device){
        padding-top: 0;
        padding-right: 0;
    }
    .project-gallery{
        .single-img{
            cursor: pointer;
            &[class*="single-img"]{
                &:not(:last-child){
                    padding-bottom: 20px;
                }
            }
        }
    }
    .fixed-sidebar{
        background-color: $white-smoke_color;
        position: fixed;
        top: 85px;
        right: 20px;
        width: 440px;
        height: 100%;
        @include breakpoint(max-md_device){
            margin-top: 20px;
            position: unset;
            width: 100%;
        }
        .inner-content{
            padding: 55px 45px 0;
            overflow-y: auto;
            height: 83%;
            @include breakpoint(max-md_device){
                height: 100%;
            }
            @include breakpoint(max-xxs_device){
                padding: 25px 30px 0;
            }
        }
        .pagination-area{
            border-bottom: 1px solid $border-color;
            padding-bottom: 25px;
            margin-bottom: 20px;
            ul{
                li{
                    a{
                        @include typography(16px, 25.6px);
                    }
                }
            }
        }
        h1{
            &.heading{
                @include typography(30px, 36px);
                letter-spacing: -1px;
                padding-bottom: 15px;
                @include breakpoint(max-xxs_device){
                    font-size: 25px;
                    line-height: 31px;
                }
            }
        }
        p{
            &.short-desc{
                @include typography(14px, 22.4px);
                color: $nobel-color;
            }
        }
        .project-meta{
            padding-top: 50px;
            > ul{
                > li{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &:not(:last-child){
                        border-bottom: 1px solid $border-color;
                        padding-bottom: 10px;
                        margin-bottom: 15px;
                    }
                    &.social-link_wrap{
                        @include breakpoint(max-xxs_device){
                            flex-direction: column;
                        }
                    }
                    h3{
                        &.meta-heading{
                            @include typography(14px, 16.8px);
                            text-transform: uppercase;
                        }
                    }
                    span{
                        &.meta-content{
                            @include typography(16px, 25.6px);
                        }
                    }
                    ul{
                        padding-bottom: 20px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        .copyright{
            padding-bottom: 55px;
            @include breakpoint(max-xxs_device){
                padding-bottom: 25px;
            }
        }
    }
}
.empty-space{
    background-color: $white-color;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    z-index: 1;
}

/* ---Product Details Style Four--- */
.pd-style_four{
    .project-meta{
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                width: 25%;
                @include breakpoint(max-sm_device){
                    width: 33.333333%;
                    &:not(:last-child){
                        padding-bottom: 25px;
                    }
                }
                @include breakpoint(max-xxs_device){
                    width: 50%;
                    &:nth-child(3){
                        padding-bottom: 0;
                    }
                }
                span{
                    &.meta-content{
                        @include typography(14px, 16.8px);
                        text-transform: uppercase;
                        color: $nobel-color;
                        padding-bottom: 5px;
                        display: block;
                    }
                }
                h3{
                    &.meta-heading{
                        @include typography(18px, 28.8px, 400);
                    }
                }
            }
        }
    }
}

/* ---Onepage Project Slider--- */
.onepage-project_slider{
    width: 100%;
	height: 100vh;
    .swiper-slide{
        display: flex;
        height: 100%;
        @include breakpoint(max-sm_device){
            flex-direction: column-reverse;
        }
        .slide-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 2;
            h2 {
                &.heading {
                    @include typography(72px, 72px);
                    background-color: $white-color;
                    margin-bottom: 15px;
                    padding: 15px;
                    @include breakpoint(max-sm_device){
                        font-size: 25px;
                        line-height: 1.2;
                        padding-left: 0;
                        margin-bottom: 0;
                    }
                }
            }
            span{
                &.area{
                    color: $secondary-color;
                    padding-bottom: 35px;
                    display: block;
                }
            }
            p{
                &.location,
                &.year{
                    color: $secondary-color;
                    span{
                        color: $text-color;
                    }
                }
            }
            .btn-wrap{
                padding-top: 90px;
                a{
                    color: $secondary-color;
                    i{
                        font-size: 12px;
                    }
                }
            }
        }
        .slide-img{
            position: relative;
            height: 100%;
            @include breakpoint(min-md_device){
                position: absolute;
                top: 0;
                right: 0;
                width: 80%;
            }
            .slide-nav_count{
                top: auto;
                right: auto;
                transform: none;
                bottom: 30px;
                left: 30px;
                @include breakpoint(max-sm_device){
                    left: auto;
                    right: 30px;
                }
                span{
                    color: $white-color;
                    &.data-count{
                        &:before{
                            @include typography(35px, 35px, 600);
                        }
                    }
                    sup{
                        @include typography(16px, 16px);
                        vertical-align: super;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
    .swiper-arrow_wrap{
        @include breakpoint(max-sm_device){
            display: none;
        }
    }
}