/*----------------------------------------*/
/*  18 - Components - Introduction
/*----------------------------------------*/
.intro-content{
    @include breakpoint(max-sm_device){
        padding-bottom: 25px;
    }
    h2{
        &.heading{
            @include typography(2rem, 1.4, 600);
            letter-spacing: -1px;
            padding-bottom: 55px;
            @include breakpoint(max-lg_device){
                font-size: 30px;
            }
        }
    }
    p{
        &.short-desc{
            padding-bottom: 75px;
        }
    }
    .project-info{
        span{
            text-transform: uppercase;
            display: block;
        }
    }
    .intro-btn{
        color: $secondary-color;
        text-transform: uppercase;
        i{
            font-size: 14px;
            vertical-align: middle;
        }
    }
}
.intro-img{
    display: flex;
    justify-content: flex-end;
}

.introduction-area-3{
    &.intro-bg{
        background-image: url('../images/introduction/onepage/1.jpg');
        background-color: $white-color;
        background-repeat: no-repeat;
        background-size: cover;
        height: 935px;
        @include breakpoint(max-md_device){
            height: auto;
        }
    }
    .intro-item{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
        @include breakpoint(max-md_device){
            display: block;
            padding-top: 50px;
            padding-bottom: 40px;
        }
        .left-side{
            width: 41.666667%;
            @include breakpoint(max-md_device){
                width: 100%;
            }
            .inner-img{
                padding-bottom: 95px;
                @include breakpoint(max-md_device){
                    padding-bottom: 45px;
                }
            }
            h3{
                &.ba-regular{
                    color: $white-color;
                    @include typography(48px, 48px, 300);
                    font-family: 'Work Sans', sans-serif;
                    max-width: 85%;
                    @include breakpoint(max-md_device){
                        padding-bottom: 35px;
                    }
                    @include breakpoint(max-sm_device){
                        font-size: 28px;
                        line-height: 1.2;
                    }
                    span{
                        font-weight: 600;
                        display: block;
                    }
                }
            }
        }
        .right-side{
            width: 58.333333%;
            @include breakpoint(max-md_device){
                width: 100%;
            }
            h2{
                &.heading{
                    @include typography(60px, 60px);
                    padding-bottom: 45px;
                    color: $white-color;
                    @include breakpoint(max-sm_device){
                        font-size: 28px;
                        line-height: 1.2;
                    }
                }
            }
            p{
                &.short-desc{
                    color: $white-color;
                }
            }
            .project-info{
                .counter-item{
                    @include breakpoint(max-xs_device){
                        flex-wrap: wrap;
                        li{
                            width: 50%;
                            &:not(:nth-child(n+3)){
                                padding-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.introduction-area-4{
    position: relative;
    &.intro-bg-2{
        background-image: url('../images/introduction/onepage/2.jpg');
        background-color: $white-color;
        background-repeat: no-repeat;
        background-size: cover;
        height: 580px;
        @include breakpoint(max-sm_device){
            height: auto;
        }
    }
    .intro-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        @include breakpoint(max-sm_device){
            padding-top: 45px;
            padding-bottom: 45px;
            height: auto;
        }
        > span{
            padding-bottom: 30px;
            display: block;
        }
        h2{
            &.contact-number{
                padding-bottom: 60px;
                a{
                    @include typography(60px, 72px);
                    @include breakpoint(max-xs_device){
                        font-size: 25px;
                        line-height: 37px;
                    }
                }
            }
        }
        h3{
            &.title{
                @include typography(18px, 21.6px);
                padding-bottom: 30px;
            }
        }
        p{
            &.short-desc{
                max-width: 40%;
                @include breakpoint(max-xs_device){
                    max-width: 100%;
                }
            }
        }
    }
    .additional-content{
        background-color: $white-color;
		width: 140px;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        @include breakpoint(max-sm_device){
            position: relative;
            width: 100%;
            height: auto;
            padding-top: 25px;
        }
        .inner-content{
            display: flex;
            justify-content: center;
            ul{
                &.vertical-social_link{
                    li{
                        @include breakpoint(max-sm_device){
                            display: inline-block;
                            &:not(:last-child){
                                padding-right: 25px;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
	}
}