/******************************

INDEX:

    00 - Base - Typography
    01 - Base - Variables
    02 - Base - Mixins

    03 - Utilities - Helper
    04 - Utilities - Color

    10 - Components - Navigation
    11 - Components - Animation
    12 - Components - Button
    13 - Components - Slider
    14 - Components - Breadcrumb
    15 - Components - Form
    16 - Components - Newsletter
    17 - Components - Social Link
    18 - Components - Introduction
    19 - Components - Service
    20 - Components - Project
    21 - Components - Testimonials
    22 - Components - News
    23 - Components - Sidebar
    24 - Components - Tab
    25 - Components - Brand
    26 - Components - Team
    27 - Components - My Account
    28 - Components - Home Masonry
    29 - Components - Grid Background
    30 - Components - Approach
    31 - Components - Text Interactive
    32 - Components - Baroque Dark

    35 - Section - Header
    36 - Section - Footer

    37 - Pages - Coming Soon
    38 - Pages - Error 404
    39 - Pages - Contact
    40 - Pages - Shop
    41 - Pages - Product Detail
    42 - Pages - Checkout
    43 - Pages - Cart

******************************/
/*

    Primary Color:       #666600;
    Primary Color-2:     #ee4300;
    Secondary Color:     #000000;
    Text Color:          #666666;
    Border Color:        #e5e5e5;


    Font Family List:-

    font-family: 'Work Sans', sans-serif;
    font-family: 'Libre Baskerville', serif;
    
*/
/* ---Google Font--- */
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
/*----------------------------------------*/
/*  00 - Base - Typography
/*----------------------------------------*/
body {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: #666666;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-weight: 600;
  font-family: sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

.img-full {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  object-fit: cover;
}

a {
  color: #666666;
  font-size: 16px;
  text-transform: capitalize;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.swiper-pagination-bullet,
.swiper-slide,
.swiper-button-next,
.swiper-button-prev {
  text-decoration: none;
  outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input.input-field {
  border: 1px solid #e5e5e5;
}

textarea.textarea-field {
  border: 1px solid #e5e5e5;
}

button,
.btn {
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
}

.overflow-hidden {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.required {
  color: #ff0000;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
  * Remove default fieldset styles.
*/
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
* Allow only vertical resizing of textareas.
*/
textarea {
  resize: vertical;
}

/*----------------------------------------*/
/*  03 - Utilities - Helper
/*----------------------------------------*/
/* ---Scroll Behavior--- */
html {
  scroll-behavior: smooth;
}

/* ---Custom Container--- */
.custom-container.custom-pl-60 {
  padding-left: 60px;
}

@media (max-width: 1199px) {
  .custom-container.custom-pl-60 {
    padding-left: 20px;
  }
}

.custom-container.custom-pr-60 {
  padding-right: 60px;
}

@media (max-width: 1199px) {
  .custom-container.custom-pr-60 {
    padding-right: 20px;
  }
}

@media (max-width: 1199px) {
  .custom-container.max-lg-p-0 {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .custom-container.max-sm-p-0 {
    padding: 0;
  }
}

/* ---Main Wrapper--- */
.main-wrapper .container-fluid {
  padding-left: 80px;
  padding-right: 80px;
}

@media (max-width: 1199px) {
  .main-wrapper .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .main-wrapper .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-wrapper.box-layout .container-fluid {
  padding-left: 60px;
  padding-right: 60px;
}

@media (max-width: 1199px) {
  .main-wrapper.box-layout .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1199px) {
  .main-wrapper.masonry-wrapper .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* ---Box Layout--- */
.box-layout {
  background-color: #ffffff;
  width: calc(100% - 40px);
  position: relative;
  margin: 20px auto;
}

@media (min-width: 1200px) {
  .box-layout.with-footer_sticky {
    margin: 20px auto 350px;
  }
}

/* ---Home Vertical Slider Wrapper--- */
.home-vs_wrapper {
  background-image: url("../images/home-background/vslide-bg-light.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

/* ---Dark Nav--- */
.lightNav .light-header {
  display: block;
}

.lightNav .dark-header {
  display: none;
}

.darkNav .dark-header {
  display: block;
}

.darkNav .light-header {
  display: none;
}

/* ---Baroque Home Video--- */
.baroque-video_area {
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

.baroque-video_area video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

@media (max-width: 767px) {
  .baroque-video_area video {
    height: 60vh;
  }
}

.baroque-video_area .video-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 767px) {
  .baroque-video_area .video-content {
    width: 95%;
  }
}

.baroque-video_area .video-content h1.heading {
  font-size: 100px;
  line-height: 100px;
  color: #ffffff;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .baroque-video_area .video-content h1.heading {
    font-size: 40px;
    line-height: 1.2;
  }
}

.baroque-video_area .video-content p.short-desc {
  font-size: 26px;
  line-height: 41.6px;
  color: #ffffff;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .baroque-video_area .video-content p.short-desc {
    font-size: 16px;
    line-height: 31.6px;
  }
}

.baroque-video_area .video-content #playpause {
  display: inline-flex;
  justify-content: center;
}

.baroque-video_area .video-content #playpause i {
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  font-size: 30px;
  display: block;
}

.baroque-video_area .video-content #playpause.playing i:before {
  content: '\60';
}

.baroque-video_area .video-content .btn-wrap {
  padding-top: 90px;
}

.baroque-video_area .video-content .btn-wrap a {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  display: block;
}

.baroque-video_area .video-content .btn-wrap a span {
  padding-bottom: 10px;
  display: block;
}

.baroque-video_area:hover .video-content #playpause i {
  opacity: 1;
  visibility: visible;
}

/* ---Section Space--- */
/* --Padding Top-- */
.pt-100 {
  padding-top: 100px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-10 {
  padding-top: 10px;
}

/* --Padding Bottom-- */
.pb-100 {
  padding-bottom: 100px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-10 {
  padding-bottom: 10px;
}

/* ---Base Transition--- */
a,
button,
i, .baroque-video_area .video-content #playpause i, .tab-content .tab-pane.active, .scroll-to-top i, .entry-meta ul li a span, .quantity .cart-plus-minus > .qtybutton, .price-filter .ui-slider-handle, .price-filter .price-slider-amount .filter-btn, .feedback-form .feedback-post_box .group-input input.input-field, .feedback-form .feedback-post_box textarea.textarea-field, .img-hover_effect .single-img:before, .img-hover_effect .single-img img, .img-hover_effect-2 .single-img img, .img-hover_effect-3:before, .img-hover_effect-3 .single-img .inner-img .inner-content, .global-overlay, .swiper-pagination.position-middle_right span.swiper-pagination-bullet, .popup_wrapper, .popup_wrapper .test .popup_off, .popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form button, .swiper-button-next,
.swiper-button-prev, .mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span, .mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand, .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_img, .demo-switcher_wrapper .demo-switcher_body ul li, .demo-switcher_wrapper .demo-switcher_body ul li a img, .baroque-btn, .mh-additional_content .swiper-slide a span, form.feedback-form.with-border-bottom .form-field textarea.textarea-field, form.feedback-form.with-border-bottom .form-field input.input-field, .newsletter-form_wrap .subscribe-form .newsletter-input, .newsletter-form_wrap .subscribe-form .newsletter-btn, .single-list_item .single-content, .masonary-item .single-content, .sidebar-area .widgets-area .widgets-item.color-option ul li a span, .brand-item .single-img img, .ba-member .single-content h3.member-name:before, .mouseover-effect ul li .inner-list .single-img .grid-bg, .text-interactive-area ul.text-interactive li .single-content h2.heading, .text-interactive-area ul.text-interactive li .fixed-img, .main-header .main-nav > ul > li > ul.baroque-dropdown, .main-header .main-nav > ul > li > ul.baroque-dropdown li, .main-header .main-nav > ul > li > ul.baroque-dropdown li.submenu-holder ul.baroque-submenu, .main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap, .footer-widgets_area .footer-widget li.language-menu ul.language-dropdown, .contact-info .ba-brand li a:after, .shorting-menu ul li > ul, .product-item .product-img img.secondary-img, .product-item .product-img .add-action, .product-item .product-img .add-action a i, .product-item .product-content h3.product-name a:before, .coupon-accordion span, .coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"], .order-button-payment input, .coupon-all .coupon input.input-btn,
.coupon-all .coupon2 input.input-btn, .cart-page-total a {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* ---Main Page Heading--- */
.main-page_heading {
  max-width: 56%;
  margin: 0 auto;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
}

@media (max-width: 991px) {
  .main-page_heading {
    max-width: 85%;
  }
}

@media (max-width: 767px) {
  .main-page_heading {
    max-width: 100%;
    font-size: 25px;
    line-height: 1.4;
  }
}

/* ---Main Page Decription--- */
.main-page_desc {
  text-align: center;
  max-width: 76%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .main-page_desc {
    max-width: 100%;
  }
}

.main-page_desc-2 {
  font-size: 18px;
  line-height: 28.8px;
  max-width: 40%;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .main-page_desc-2 {
    max-width: 60%;
  }
}

@media (max-width: 1199px) {
  .main-page_desc-2 {
    max-width: 100%;
  }
}

/* ---Slider Nav Count--- */
.slide-nav_count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 150px;
}

.slide-nav_count span {
  display: block;
}

.slide-nav_count span.data-count {
  position: relative;
}

.slide-nav_count span.data-count:before {
  content: attr(data-count);
  font-size: 51px;
  line-height: 51px;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .slide-nav_count span.data-count:before {
    font-size: 40px;
    line-height: 1.2;
  }
}

.slide-nav_count span sup {
  font-size: 21px;
  line-height: 21px;
  vertical-align: super;
}

/* ---Sticker---*/
.sticker {
  background-color: #ff8c46;
  color: #ffffff;
  position: absolute;
  padding: 0 15px;
  height: 25px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
  border-radius: 3px;
  text-align: center;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.sticker.radical-red_color {
  background-color: #ff1d5d;
}

.sticker.secodary-color {
  background-color: #000000;
}

/* ---Tooltip--- */
.tooltip {
  z-index: 10;
}

.tooltip-inner {
  padding: 0 8px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  content: "";
  border-width: 5px 5px 0;
}

.tooltip-inner {
  background-color: #000000;
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #000000;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #000000;
}

.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #000000;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #000000;
}

/* ---Spacing Between Slide Item---*/
.slick-gutter-20 .slick-list {
  margin: 0 -10px;
}

.slick-gutter-20 .slick-list .slick-slide {
  padding: 0 10px;
}

.slick-gutter-30 .slick-list {
  margin: 0 -15px;
}

.slick-gutter-30 .slick-list .slick-slide {
  padding: 0 15px;
}

.slick-gutter-60 .slick-list {
  margin: 0 -30px;
}

.slick-gutter-60 .slick-list .slick-slide {
  padding: 0 30px;
}

/* ---Spacing Between Two Row---*/
.baroque-element-carousel .slick-slide div:not(:last-child) .project-item,
.baroque-element-carousel .slick-slide div:not(:last-child) .single-item {
  padding-bottom: 55px;
}

.baroque-element-carousel.row-space-20 .slick-slide div:not(:last-child) .project-item {
  padding-bottom: 20px;
}

.baroque-element-carousel.row-space-10 .slick-slide div:not(:last-child) .project-item {
  padding-bottom: 100px;
}

/* ---Grid Item--- */
.masonry-grid {
  margin-left: -10px;
  margin-right: -10px;
}

.masonry-grid .grid-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.masonry-grid .grid-item .single-img {
  height: 100%;
}

.masonry-grid .grid-item .single-img .inner-img {
  height: 100%;
}

.masonry-grid .grid-item .single-img .inner-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.masonry-grid .grid-item.grid-item--height2 {
  height: 500px;
}

.masonry-grid .grid-item.grid-item--height3 {
  height: 310px;
}

.masonry-grid .grid-item.grid-item--height4 {
  height: 170px;
}

/* ---Entry Banner--- */
.entry-banner,
.about-bg {
  background-image: url("../images/project/bg/1-1880x1080.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 445px;
  position: relative;
}

.entry-banner.rectangle-house-bg,
.about-bg.rectangle-house-bg {
  background-image: url("../images/project/bg/1-1880x1413.jpg");
  height: 660px;
}

.entry-banner.rectangle-house-bg:before,
.about-bg.rectangle-house-bg:before {
  background-color: rgba(0, 0, 0, 0.35);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.entry-banner.rectangle-house-bg .inner-text,
.about-bg.rectangle-house-bg .inner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.entry-banner.rectangle-house-bg .inner-text span,
.about-bg.rectangle-house-bg .inner-text span {
  font-size: 24px;
  line-height: 38.4px;
  padding-bottom: 35px;
  color: #ffffff;
  display: block;
}

@media (max-width: 767px) {
  .entry-banner.rectangle-house-bg .inner-text span,
  .about-bg.rectangle-house-bg .inner-text span {
    font-size: 20px;
    line-height: 34.4px;
  }
}

.entry-banner.rectangle-house-bg .inner-text h2.heading,
.about-bg.rectangle-house-bg .inner-text h2.heading {
  font-size: 60px;
  line-height: 72px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .entry-banner.rectangle-house-bg .inner-text h2.heading,
  .about-bg.rectangle-house-bg .inner-text h2.heading {
    font-size: 28px;
    line-height: 30px;
  }
}

.entry-banner.rectangle-house-bg.without-parallax,
.about-bg.rectangle-house-bg.without-parallax {
  background-image: url("../images/project/bg/1-1170x613.jpg");
  height: 613px;
}

@media (max-width: 767px) {
  .entry-banner.rectangle-house-bg.without-parallax,
  .about-bg.rectangle-house-bg.without-parallax {
    height: 320px;
  }
}

.entry-banner.about-bg,
.about-bg.about-bg {
  background-image: url("../images/about/bg/1-1880x820.jpg");
  height: 540px;
}

.entry-banner.product-bg,
.about-bg.product-bg {
  background-image: url("../images/product/bg/1-1880x1225.jpg");
}

@media (max-width: 767px) {
  .entry-banner.product-bg,
  .about-bg.product-bg {
    height: 350px;
  }
}

/* ---Entry Gallery--- */
.entry-gallery .single-img.entry-bg-1, .entry-gallery .single-img.entry-bg-2, .entry-gallery .single-img.entry-bg-3 {
  background-image: url("../images/entry-gallery/bg/1-1880x680.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 680px;
}

@media (max-width: 767px) {
  .entry-gallery .single-img.entry-bg-1, .entry-gallery .single-img.entry-bg-2, .entry-gallery .single-img.entry-bg-3 {
    height: 320px;
  }
}

.entry-gallery .single-img.entry-bg-1.entry-bg-2, .entry-gallery .single-img.entry-bg-2.entry-bg-2, .entry-gallery .single-img.entry-bg-3.entry-bg-2 {
  background-image: url("../images/entry-gallery/bg/2-1880x680.jpg");
}

.entry-gallery .single-img.entry-bg-1.entry-bg-3, .entry-gallery .single-img.entry-bg-2.entry-bg-3, .entry-gallery .single-img.entry-bg-3.entry-bg-3 {
  background-image: url("../images/entry-gallery/bg/3-1880x680.jpg");
}

/* ---Blockquote--- */
blockquote {
  font-size: 24px;
  padding: 0 0 30px 120px;
  margin: 0;
  position: relative;
}

@media (max-width: 479px) {
  blockquote {
    padding: 0 0 30px 0;
  }
}

blockquote:before {
  color: #000000;
  content: '\7b';
  font-family: ElegantIcons;
  font-size: 89px;
  position: absolute;
  top: -6px;
  left: -24px;
  line-height: 1;
  z-index: 9;
}

@media (max-width: 479px) {
  blockquote:before {
    position: relative;
  }
}

blockquote:after {
  content: '';
  width: 48px;
  height: 48px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #ced08d;
  position: absolute;
  left: 20px;
  top: 0;
}

blockquote p {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.2px;
  color: #000000;
}

blockquote .user-info span {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
}

blockquote .user-info span.user-name {
  color: #000000;
  font-weight: 600;
}

blockquote .user-info span.cite {
  color: #999999;
}

/* ---Tab Content & Pane Fix--- */
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*-- Scroll To Top --*/
.scroll-to-top {
  background-color: #666600;
  color: #ffffff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  z-index: 999;
  font-size: 20px;
  overflow: hidden;
  display: block;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px;
  animation: barSlideInDown 1s normal;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  animation: barSlideInUp 1s normal;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
}

.scroll-to-top-2 {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  overflow: hidden;
  display: block;
  opacity: 0;
  visibility: hidden;
  animation: barSlideInDown 1s normal;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top-2.show {
  opacity: 1;
  visibility: visible;
  animation: barSlideInUp 1s normal;
}

.scroll-to-top-2 i {
  color: #000000;
  font-size: 48px;
}

/* ---Rating Box--- */
.rating-box ul li {
  display: inline-block;
}

.rating-box ul li i {
  color: #dc9b0e;
  font-size: 12px;
}

.rating-box ul li.silver-color i {
  color: #bbbbbb;
}

/* ---Price Box--- */
.price-box span.new-price {
  font-size: 18px;
  line-height: 18px;
}

.price-box span.new-price.radical-red_color {
  color: #ff1d5d;
}

.price-box span.new-price.primary-color {
  color: #666600;
}

.price-box span.old-price {
  font-size: 14px;
  line-height: 14px;
  text-decoration: line-through;
  color: #999999;
}

/* ---Entry Meta--- */
.entry-meta {
  border-top: 1px solid #e5e5e5;
  padding: 20px 0;
}

.entry-meta ul {
  display: flex;
  justify-content: center;
}

.entry-meta ul li:not(:last-child) {
  padding-right: 25px;
}

@media (max-width: 1199px) {
  .entry-meta ul li:not(:last-child) {
    padding-right: 15px;
  }
}

.entry-meta ul li a {
  font-size: 14px;
  line-height: 24.3px;
  color: #8cc63e;
}

.entry-meta ul li a:hover {
  color: #666600;
}

.entry-meta ul li a i {
  vertical-align: middle;
  color: #999999;
  margin-right: 3px;
}

.entry-meta ul li a span {
  color: #999999;
}

.entry-meta ul li a span:hover {
  color: #666600;
}

/* ---Pagination--- */
.pagination-area {
  padding-bottom: 90px;
}

.pagination-area ul {
  display: flex;
  justify-content: space-between;
}

.pagination-area ul li a {
  font-size: 24px;
  line-height: 38px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

@media (max-width: 479px) {
  .pagination-area ul li a {
    font-size: 18px;
    line-height: 32px;
    text-transform: capitalize;
  }
}

.pagination-area ul li a:hover {
  color: inherit;
}

/* ---Pagination Two--- */
.paginatoin-area-2 {
  padding-top: 45px;
}

.paginatoin-area-2.with-border {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 100px;
}

.paginatoin-area-2.position-center {
  display: flex;
  justify-content: center;
}

.paginatoin-area-2 .pagination-box li {
  display: inline-block;
}

.paginatoin-area-2 .pagination-box li:not(:last-child) {
  margin-right: 5px;
}

.paginatoin-area-2 .pagination-box li > a {
  background-color: #f2f2f2;
  color: #000000;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: block;
}

.paginatoin-area-2 .pagination-box li > a i {
  font-size: 12px;
}

.paginatoin-area-2 .pagination-box li.active > a {
  cursor: not-allowed;
}

/* ---Tag--- */
.tags-list {
  display: flex;
}

@media (max-width: 479px) {
  .tags-list {
    flex-direction: column;
  }
}

.tags-list span {
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
  padding-right: 5px;
}

.tags-list li {
  display: inline-block;
}

.tags-list li a {
  text-transform: lowercase;
}

/* ---Quantity--- */
.quantity .cart-plus-minus {
  position: relative;
  width: 76px;
  height: 55px;
  text-align: left;
}

.quantity .cart-plus-minus > .cart-plus-minus-box {
  border: 1px solid #e5e5e5;
  height: 55px;
  text-align: center;
  width: 48px;
  background: #ffffff;
}

.quantity .cart-plus-minus > .qtybutton {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
  position: absolute;
  text-align: center;
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 10px;
}

.quantity .cart-plus-minus > .qtybutton.dec {
  bottom: 0;
  right: 0;
}

.quantity .cart-plus-minus > .qtybutton.inc {
  border-bottom: none;
  top: 0;
  right: 0;
}

/* ---Range Slider--- */
.price-filter .ui-widget-content {
  background-color: #e5e5e5;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  height: 10px;
  margin: 0 0 25px;
  width: 100%;
  border-top-left-radius: 25px;
  -moz-border-radius-topleft: 25px;
  border-top-right-radius: 25px;
  -moz-border-radius-topright: 25px;
  border-bottom-left-radius: 25px;
  -moz-border-radius-bottomleft: 25px;
  border-bottom-right-radius: 25px;
  -moz-border-radius-bottomright: 25px;
}

.price-filter .ui-slider-range {
  background-color: #666600;
  border: none;
  box-shadow: none;
  height: 100%;
}

.price-filter .ui-slider-handle {
  border: 4px solid #666600;
  background: #ffffff;
  display: block;
  outline: none;
  width: 20px;
  height: 20px;
  line-height: 15px;
  margin: 0;
  text-align: center;
  top: 50%;
  border-radius: 100%;
  box-shadow: 0px 0px 6.65px 0.35px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: e-resize;
}

.price-filter .ui-slider-handle:last-child {
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.price-filter .ui-slider-handle:hover {
  background-color: #666600;
}

.price-filter .price-slider-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-filter .price-slider-amount .filter-btn {
  border: 2px solid #666666;
  width: 110px;
  height: 40px;
  line-height: 39px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .price-filter .price-slider-amount .filter-btn {
    width: 40px;
    font-size: 14px;
  }
}

.price-filter .price-slider-amount .filter-btn:hover {
  background-color: #666600;
  border-color: #666600;
  color: #ffffff;
}

.price-filter .price-slider-amount .label-input label {
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

.price-filter .price-slider-amount .label-input input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  box-shadow: none;
  font-size: 14px;
  height: inherit;
  padding-left: 5px;
  width: 99px;
}

/* ---Counter Item--- */
.counter-item {
  padding-top: 85px;
  display: flex;
}

@media (max-width: 479px) {
  .counter-item {
    flex-wrap: wrap;
  }
}

.counter-item li {
  width: 33.333%;
}

@media (max-width: 479px) {
  .counter-item li {
    width: 50%;
  }
}

.counter-item li:not(:last-child) {
  padding-right: 90px;
}

@media (max-width: 479px) {
  .counter-item li:not(:last-child) {
    padding-bottom: 20px;
  }
}

.counter-item li span.count {
  font-size: 56px;
  line-height: 56px;
  font-weight: 600;
  padding-bottom: 5px;
  display: block;
}

.counter-item li span.count-title {
  font-size: 14px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.counter-item.with-four_item li {
  width: 25%;
}

/* ---Countdown--- */
.countdown-wrap .countdown.item-4 .countdown__item {
  display: inline-block;
  position: relative;
  text-align: center;
  margin-left: 40px;
  position: relative;
}

@media (max-width: 575px) {
  .countdown-wrap .countdown.item-4 .countdown__item {
    margin-left: 20px;
  }
}

.countdown-wrap .countdown.item-4 .countdown__item:first-child {
  margin-left: 0;
}

.countdown-wrap .countdown.item-4 .countdown__item span {
  text-transform: capitalize;
  line-height: 1;
  color: #ffffff;
}

.countdown-wrap .countdown.item-4 .countdown__item span.countdown__time {
  font-size: 49px;
}

@media (max-width: 575px) {
  .countdown-wrap .countdown.item-4 .countdown__item span.countdown__time {
    font-size: 25px;
  }
}

.countdown-wrap .countdown.item-4 .countdown__item span.countdown__text {
  display: block;
  font-size: 18px;
  padding-top: 15px;
}

@media (max-width: 575px) {
  .countdown-wrap .countdown.item-4 .countdown__item span.countdown__text {
    font-size: 14px;
  }
}

.countdown-wrap.white-text_color span {
  color: #ffffff;
}

/* ---Google Map Related Stuff--- */
#google-map {
  height: 400px;
}

/* ---Post Blockquote--- */
.post-blockquote {
  padding: 25px 0 0 60px;
}

@media (max-width: 991px) {
  .post-blockquote {
    padding: 20px 0 0;
  }
}

.post-blockquote blockquote {
  position: relative;
  padding-left: 30px;
  margin: 0 0 20px;
  font-style: italic;
}

.post-blockquote blockquote:before {
  content: "";
  background: #8cc63e;
  position: absolute;
  width: 5px;
  height: 100%;
  top: 0;
  left: 0;
}

.post-blockquote blockquote p {
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #999999;
}

/* ---Feedback Form--- */
.feedback-form .feedback-post_box {
  padding-top: 20px;
}

.feedback-form .feedback-post_box .group-input {
  display: flex;
}

@media (max-width: 479px) {
  .feedback-form .feedback-post_box .group-input {
    flex-direction: column;
  }
}

.feedback-form .feedback-post_box .group-input input.input-field {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 14px;
  width: 100%;
  height: 50px;
}

.feedback-form .feedback-post_box .group-input input.input-field::placeholder {
  color: #999999;
}

.feedback-form .feedback-post_box .group-input input.input-field:hover {
  border-color: #666666;
}

.feedback-form .feedback-post_box .group-input input.input-field:focus {
  border-color: #666666;
}

.feedback-form .feedback-post_box textarea.textarea-field {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  height: 235px;
}

.feedback-form .feedback-post_box textarea.textarea-field::placeholder {
  color: #999999;
}

.feedback-form .feedback-post_box textarea.textarea-field:hover {
  border-color: #666666;
}

.feedback-form .feedback-post_box textarea.textarea-field:focus {
  border-color: #666666;
}

/* ---Image Hover Effect--- */
.img-hover_effect .single-img {
  position: relative;
  overflow: hidden;
}

.img-hover_effect .single-img:before {
  background-color: #1b1b1b;
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  z-index: 1;
}

.img-hover_effect:hover .single-img:before {
  opacity: 0.3;
}

.img-hover_effect:hover .single-img img {
  transform: scale(1.05);
}

.img-hover_effect-2 .single-img {
  position: relative;
  overflow: hidden;
}

.img-hover_effect-2 .single-img img.primary-img {
  position: relative;
  top: 0;
  left: 0;
}

.img-hover_effect-2 .single-img img.secondary-img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.img-hover_effect-2:hover .single-img img.primary-img {
  top: 100%;
}

.img-hover_effect-2:hover .single-img img.secondary-img {
  transform: none;
}

.img-hover_effect-3 {
  position: relative;
  cursor: pointer;
  height: 100%;
}

.img-hover_effect-3:before {
  background-color: #ffffff;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
}

.img-hover_effect-3 .single-img {
  position: relative;
}

.img-hover_effect-3 .single-img:before {
  background-color: #666600;
  content: "";
  width: 0;
  height: 4px;
  top: 0;
  left: auto;
  right: 0;
  position: absolute;
  z-index: 1;
  transition: 0.3s;
}

.img-hover_effect-3 .single-img:after {
  background-color: #666600;
  content: "";
  width: 4px;
  height: 0;
  top: auto;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  transition: 0.3s 0.25s;
}

.img-hover_effect-3 .single-img .inner-img:before {
  background-color: #666600;
  content: "";
  width: 0;
  height: 4px;
  bottom: 0;
  left: 0;
  right: auto;
  position: absolute;
  z-index: 1;
  transition: 0.3s 0.50s;
}

.img-hover_effect-3 .single-img .inner-img:after {
  background-color: #666600;
  content: "";
  width: 4px;
  height: 0;
  top: 0;
  bottom: auto;
  left: 0;
  position: absolute;
  z-index: 1;
  transition: 0.3s 0.75s;
}

.img-hover_effect-3 .single-img .inner-img .inner-content {
  position: absolute;
  left: 0;
  bottom: -30px;
  padding: 25px 50px;
  z-index: 2;
  opacity: 0;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .img-hover_effect-3 .single-img .inner-img .inner-content {
    padding: 25px;
  }
}

.img-hover_effect-3 .single-img .inner-img .inner-content span.project-category {
  padding-bottom: 15px;
  color: #999999;
  display: block;
}

.img-hover_effect-3 .single-img .inner-img .inner-content h3.heading {
  font-size: 24px;
  line-height: 29.6px;
}

.img-hover_effect-3:hover:before {
  opacity: 1;
}

.img-hover_effect-3:hover .single-img:before {
  width: 100%;
  right: auto;
  left: 0;
}

.img-hover_effect-3:hover .single-img:after {
  height: 100%;
  bottom: auto;
  top: 0;
}

.img-hover_effect-3:hover .single-img .inner-img:before {
  width: 100%;
  right: 0;
  left: auto;
}

.img-hover_effect-3:hover .single-img .inner-img:after {
  height: 100%;
  top: auto;
  bottom: 0;
}

.img-hover_effect-3:hover .single-img .inner-img .inner-content {
  bottom: 0;
  opacity: 1;
  overflow: visible;
}

.img-hover_effect-4 {
  position: relative;
}

.img-hover_effect-4:before {
  background-color: #666600;
  content: "";
  width: 0;
  height: 4px;
  top: 0;
  left: auto;
  right: 0;
  position: absolute;
  z-index: 1;
  transition: 0.3s;
}

.img-hover_effect-4:after {
  background-color: #666600;
  content: "";
  width: 4px;
  height: 0;
  top: auto;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  transition: 0.3s 0.25s;
}

.img-hover_effect-4 .hover-border:before {
  background-color: #666600;
  content: "";
  width: 0;
  height: 4px;
  bottom: 0;
  left: 0;
  right: auto;
  position: absolute;
  z-index: 1;
  transition: 0.3s 0.50s;
}

.img-hover_effect-4 .hover-border:after {
  background-color: #666600;
  content: "";
  width: 4px;
  height: 0;
  top: 0;
  bottom: auto;
  left: 0;
  position: absolute;
  z-index: 1;
  transition: 0.3s 0.75s;
}

.img-hover_effect-4:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

.img-hover_effect-4:hover:after {
  height: 100%;
  bottom: auto;
  top: 0;
}

.img-hover_effect-4:hover .hover-border:before {
  width: 100%;
  right: 0;
  left: auto;
}

.img-hover_effect-4:hover .hover-border:after {
  height: 100%;
  top: auto;
  bottom: 0;
}

/* ---Global Overlay--- */
.global-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  visibility: hidden;
}

.global-overlay.overlay-open {
  visibility: visible;
  cursor: pointer;
}

/* ---Swiper Pagination--- */
.swiper-pagination span.swiper-pagination-bullet {
  background-color: #666600;
}

.swiper-pagination.position-middle_right {
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
  width: auto;
  left: auto;
  bottom: auto;
}

@media (max-width: 1199px) {
  .swiper-pagination.position-middle_right {
    right: 40px;
  }
}

@media (max-width: 767px) {
  .swiper-pagination.position-middle_right {
    right: 20px;
  }
}

.swiper-pagination.position-middle_right span.swiper-pagination-bullet {
  background-color: #ffffff;
  position: relative;
  width: 12px;
  height: 12px;
  display: block;
  cursor: pointer;
  opacity: 1;
}

.swiper-pagination.position-middle_right span.swiper-pagination-bullet:not(:last-child) {
  margin-bottom: 30px;
}

.swiper-pagination.position-middle_right span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ee4300;
}

.swiper-pagination.position-middle_right span.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background-color: rgba(238, 67, 0, 0.25);
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-pagination.position-middle_right span.swiper-pagination-bullet:hover {
  background-color: #ee4300;
}

.swiper-pagination.position-middle_right span.swiper-pagination-bullet:hover:before {
  background-color: rgba(238, 67, 0, 0.25);
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ---baroque's Newsletters Popup--- */
.popup_wrapper {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
}

@media (max-width: 991px) {
  .popup_wrapper {
    display: none;
  }
}

.popup_wrapper .test {
  background: #ffffff;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
  height: 425px;
  left: 0;
  margin: auto;
  max-width: 870px;
  padding: 50px 50px 50px;
  position: absolute;
  right: 0;
  top: 0;
}

.popup_wrapper .test .popup_off {
  background: #666666;
  color: #ffffff;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 43px;
  display: block;
  cursor: pointer;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
}

.popup_wrapper .test .popup_off:hover {
  background-color: #666600;
}

.popup_wrapper .subscribe-area {
  display: flex;
  align-items: center;
  height: 100%;
}

.popup_wrapper .subscribe-area .subscribe-img {
  background-color: #f0daca;
  width: 40%;
}

.popup_wrapper .subscribe-area .subscribe-img .subscribe-inner_img {
  margin: -20px -20px 20px 20px;
}

.popup_wrapper .subscribe-area .subscribe-content {
  padding-left: 80px;
  width: 60%;
  text-align: center;
}

.popup_wrapper .subscribe-area .subscribe-content > h2 {
  color: #000000;
  font-weight: 600;
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.popup_wrapper .subscribe-area .subscribe-content > p {
  margin: 0;
  max-width: 580px;
  padding-bottom: 25px;
  color: #000000;
  font-size: 16px;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group {
  display: flex;
  justify-content: center;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form {
  position: relative;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form input {
  background: #ffffff;
  border: 1px solid #e5e5e5 !important;
  color: #000000;
  border: 0 none;
  height: 50px;
  padding: 0 150px 0 20px;
  width: 100%;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form input::placeholder {
  color: #000000;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form button {
  background: #666600;
  color: #ffffff;
  top: 0;
  right: 0;
  position: absolute;
  width: 130px;
  height: 50px;
  line-height: 50px;
  border: 0;
  display: block;
  text-transform: uppercase;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-form-group form.subscribe-form button:hover {
  background-color: #666600;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-bottom input {
  margin-right: 5px;
}

.popup_wrapper .subscribe-area .subscribe-content .subscribe-bottom label {
  margin-bottom: 0;
  color: #000000;
}

/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-charcoal_color {
  background-color: #454545;
}

.bg-denim_color {
  background-color: #666600;
}

.bg-smoke_color {
  background-color: #f2f2f2;
}

.bg-white_color {
  background-color: #ffffff;
}

.bg-snow_color {
  background-color: #fcfcfc;
}

.bg-dark_color {
  background-color: #000000;
}

.bg-nero_color {
  background-color: #1b1b1b;
}

/* ---Text Color Variation--- */
.text-white * {
  color: #ffffff;
}

.text-secondary_color * {
  color: #000000;
}

/* ---Template Color Variation--- */
.template-color-1 {
  /* ---Button--- */
  /* ---Dropdown--- */
  /* ---Megamenu--- */
  /* ---Minicart--- */
  /* ---Offcanvas Language--- */
  /* ---Project Tab--- */
  /* ---Paginatoin Area--- */
  /* ---Footer--- */
}

.template-color-1 a:hover {
  color: #666600;
}

.template-color-1 [class*="baroque-btn"]:hover {
  color: #ffffff;
}

.template-color-1 .btn-wrap .readmore-btn:hover {
  color: #666600;
}

.template-color-1 .baroque-dropdown li a {
  color: #999999;
}

.template-color-1 .baroque-dropdown li a:hover {
  color: #666600;
}

.template-color-1 .baroque-dropdown li.submenu-holder:hover a {
  color: #666600;
}

.template-color-1 .ba-megamenu > li a {
  color: #999999;
}

.template-color-1 .ba-megamenu > li a:hover {
  color: #666600;
}

.template-color-1 .minicart-wrap span.cart-counter {
  background-color: #666600;
}

.template-color-1 .offcanvas-language ul li.active a {
  color: #666600;
}

.template-color-1 .project-tab ul li a.active {
  color: #666600;
}

.template-color-1 .paginatoin-area-2 .pagination-box li.active > a {
  background-color: #666600;
  border-color: #666600;
  color: #ffffff;
}

.template-color-1 .paginatoin-area-2 .pagination-box li:hover > a {
  background-color: #666600;
  border-color: #666600;
  color: #ffffff;
}

.template-color-1 .footer-widgets_area .footer-widget li a:hover {
  color: #666600;
}

.template-color-1 .footer-widgets_area .footer-widget li.language-menu:hover > a {
  color: #666600;
}

.template-color-2 {
  /* ---Button--- */
  /* ---Dropdown--- */
  /* ---Megamenu--- */
  /* ---Minicart--- */
  /* ---Offcanvas Language--- */
  /* ---Project Tab--- */
  /* ---Paginatoin Area--- */
  /* ---Footer--- */
}

.template-color-2 a:hover {
  color: #ee4300;
}

.template-color-2 [class*="baroque-btn"]:hover {
  color: #ffffff;
}

.template-color-2 .btn-wrap .readmore-btn:hover {
  color: #ee4300;
}

.template-color-2 .baroque-dropdown li a {
  color: #999999;
}

.template-color-2 .baroque-dropdown li a:hover {
  color: #ee4300;
}

.template-color-2 .baroque-dropdown li.submenu-holder:hover a {
  color: #ee4300;
}

.template-color-2 .ba-megamenu > li a {
  color: #999999;
}

.template-color-2 .ba-megamenu > li a:hover {
  color: #ee4300;
}

.template-color-2 .minicart-wrap span.cart-counter {
  background-color: #ee4300;
}

.template-color-2 .offcanvas-language ul li.active a {
  color: #ee4300;
}

.template-color-2 .project-tab ul li a.active {
  color: #ee4300;
}

.template-color-2 .paginatoin-area-2 .pagination-box li.active > a {
  background-color: #ee4300;
  border-color: #ee4300;
  color: #ffffff;
}

.template-color-2 .paginatoin-area-2 .pagination-box li:hover > a {
  background-color: #ee4300;
  border-color: #ee4300;
  color: #ffffff;
}

.template-color-2 .footer-widgets_area .footer-widget li a:hover {
  color: #ee4300;
}

.template-color-2 .footer-widgets_area .footer-widget li.language-menu:hover > a {
  color: #ee4300;
}

/*----------------------------------------*/
/*  10 - Components - Navigation
/*----------------------------------------*/
/* ---Navigation and pagination wrap--- */
.nav-pagination_wrap .swiper-pagination {
  display: none;
}

@media (max-width: 991px) {
  .nav-pagination_wrap .swiper-pagination {
    display: block;
  }
}

.nav-pagination_wrap .swiper-button-next,
.nav-pagination_wrap .swiper-button-prev {
  display: flex;
}

@media (max-width: 991px) {
  .nav-pagination_wrap .swiper-button-next,
  .nav-pagination_wrap .swiper-button-prev {
    display: none;
  }
}

.nav-pagination_wrap .swiper-button-next {
  right: 60px;
}

@media (max-width: 1199px) {
  .nav-pagination_wrap .swiper-button-next {
    right: 20px;
  }
}

.nav-pagination_wrap .swiper-button-prev {
  left: 60px;
}

@media (max-width: 1199px) {
  .nav-pagination_wrap .swiper-button-prev {
    left: 20px;
  }
}

/* ---Swiper Arrow--- */
.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 14px;
  width: 70px;
  height: 70px;
  line-height: 70px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #000000;
}

/* ---Verticle Arrows--- */
.verticle-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.verticle-arrow .swiper-button-next {
  display: block;
}

/* ---Swiper Arrow Wrap--- */
.swiper-arrow_wrap.bottom-left {
  position: absolute;
  bottom: 20px;
  left: 0;
}

.swiper-arrow_wrap.bottom-left .swiper-button-next {
  left: 70px;
}

.swiper-arrow_wrap.bottom-left .swiper-button-prev {
  left: 0;
}

.swiper-arrow_wrap.without-background .swiper-button-next,
.swiper-arrow_wrap.without-background .swiper-button-prev {
  background-color: transparent;
  color: #ccc;
  width: auto;
  height: auto;
}

.swiper-arrow_wrap.without-background .swiper-button-next:after,
.swiper-arrow_wrap.without-background .swiper-button-prev:after {
  font-size: 40px;
}

.swiper-arrow_wrap.without-background .swiper-button-next:hover,
.swiper-arrow_wrap.without-background .swiper-button-prev:hover {
  color: #000000;
}

/* ---Offcanvas--- */
.mobile-menu_wrapper .offcanvas-body,
.offcanvas-minicart_wrapper .offcanvas-body {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: auto;
  right: -660px;
  width: 660px;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  overflow: visible;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 100%;
  overflow-y: auto;
  margin: 0 -20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .mobile-menu_wrapper .offcanvas-body,
  .offcanvas-minicart_wrapper .offcanvas-body {
    width: 100%;
  }
}

.mobile-menu_wrapper .offcanvas-body .inner-body,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body {
  padding: 82.5px 100px 0;
}

@media (max-width: 767px) {
  .mobile-menu_wrapper .offcanvas-body .inner-body,
  .offcanvas-minicart_wrapper .offcanvas-body .inner-body {
    width: 100%;
    padding: 82.5px 65px 0 45px;
  }
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-top,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-top .offcanvas-language ul,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-top .offcanvas-language ul {
  display: flex;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-top .offcanvas-language ul li:not(:last-child),
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-top .offcanvas-language ul li:not(:last-child) {
  padding-right: 20px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-top .offcanvas-language ul li a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-top .offcanvas-language ul li a {
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 500;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-top .btn-close,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-top .btn-close {
  font-size: 24px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation {
  overflow-y: auto;
  height: 263px;
  /* ---Light Offcanvas--- */
  /* ---Dark Offcanvas--- */
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li {
  height: 100%;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu > li > a span {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li {
  position: relative;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li:not(:last-child),
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li:not(:last-child) {
  padding-bottom: 20px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li > .menu-expand {
  position: absolute;
  right: 0;
  top: 2px;
  width: 50px;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  margin-right: 4px;
  font-size: 20px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li a {
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  display: block;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu {
  padding: 20px 0 0;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a {
  font-size: 18px;
  line-height: 18px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a span,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li .sub-menu li a span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-item-has-children.menu-open ul.inner-sub li,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-item-has-children.menu-open ul.inner-sub li {
  padding-left: 15px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-item-has-children.menu-open ul.inner-sub li a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation .mobile-menu li.menu-item-has-children.menu-open ul.inner-sub li a {
  font-size: 16px;
  line-height: 16px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu > li > a span:hover,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu > li > a span:hover {
  color: #000000;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu > li.active > a span,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu > li.active > a span {
  color: #000000;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu li .sub-menu li:hover > a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu li .sub-menu li:hover > a {
  color: #000000;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu li.menu-open > a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.light-offcanvas .mobile-menu li.menu-open > a {
  color: #000000;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu > li > a span:hover,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu > li > a span:hover {
  color: #ffffff;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu > li.active > a span,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu > li.active > a span {
  color: #ffffff;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu li .sub-menu li:hover > a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu li .sub-menu li:hover > a {
  color: #ffffff;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu li.menu-open > a,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-menu_area .offcanvas-navigation.dark-offcanvas .mobile-menu li.menu-open > a {
  color: #ffffff;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-bottom,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-bottom {
  padding-top: 45px;
}

.mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-bottom .copyright,
.offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-bottom .copyright {
  padding-top: 15px;
  max-width: 50%;
  padding-bottom: 87.5px;
}

@media (max-width: 479px) {
  .mobile-menu_wrapper .offcanvas-body .inner-body .offcanvas-bottom .copyright,
  .offcanvas-minicart_wrapper .offcanvas-body .inner-body .offcanvas-bottom .copyright {
    max-width: 100%;
  }
}

.mobile-menu_wrapper.open .offcanvas-body,
.offcanvas-minicart_wrapper.open .offcanvas-body {
  visibility: visible;
  left: auto;
  right: 0;
  opacity: 1;
  z-index: 999;
}

/* ---Offcanvas Minicart Area--- */
.offcanvas-minicart_wrapper .offcanvas-body {
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  padding: 60px 60px 0;
  width: 450px;
}

@media (max-width: 575px) {
  .offcanvas-minicart_wrapper .offcanvas-body {
    width: 100%;
    padding: 60px 55px 0 30px;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-heading h4 {
  font-size: 18px;
  line-height: 21.6px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list {
  max-height: 345px;
  position: relative;
  overflow: auto;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product {
  display: flex;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product {
    flex-direction: column;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product a.product-item_remove i {
  position: absolute;
  font-size: 12px;
  top: auto;
  right: 15px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_img {
  border: 1px solid #e5e5e5;
  flex-basis: 70px;
  max-width: 70px;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content {
  flex-basis: calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 20px;
  padding-right: 10px;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content a.product-item_title {
  line-height: 1.4;
  font-size: 16px;
  width: 90%;
  display: block;
}

@media (max-width: 479px) {
  .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content a.product-item_title {
    width: 100%;
  }
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content .product-item_quantity {
  display: block;
  padding-top: 10px;
  font-size: 13px;
  line-height: 24.7px;
  color: #454545;
}

.offcanvas-minicart_wrapper .offcanvas-body .minicart-item_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0 30px;
}

.offcanvas-minicart_wrapper .offcanvas-body .group-btn_wrap {
  padding-bottom: 60px;
}

/* ---Demo Switcher Area--- */
.demo-switcher_area {
  position: fixed;
  top: 170px;
  right: 0;
  z-index: 2;
}

.demo-switcher_area ul li a.demo-switcher_btn {
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  width: 65px;
  line-height: 1;
  padding: 17px 0;
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 15px;
}

.demo-switcher_area ul li a.demo-switcher_btn img {
  width: 100%;
  object-fit: cover;
}

.demo-switcher_area ul li a.demo-switcher_btn:hover {
  background-color: #000000;
}

.demo-switcher_wrapper {
  display: flex;
  justify-content: center;
}

.demo-switcher_wrapper .demo-switcher_body {
  background-color: #ffffff;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 90px;
  width: calc(100% - 180px);
  height: 100%;
  overflow-y: auto;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (max-width: 767px) {
  .demo-switcher_wrapper .demo-switcher_body {
    width: calc(100% - 60px);
    left: 30px;
  }
}

.demo-switcher_wrapper .demo-switcher_body ul {
  display: flex;
  flex-wrap: wrap;
}

.demo-switcher_wrapper .demo-switcher_body ul li {
  flex: 0 0 16.666%;
  max-width: 16.666%;
  padding: 10px;
}

@media (max-width: 1199px) {
  .demo-switcher_wrapper .demo-switcher_body ul li {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 991px) {
  .demo-switcher_wrapper .demo-switcher_body ul li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (max-width: 767px) {
  .demo-switcher_wrapper .demo-switcher_body ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 479px) {
  .demo-switcher_wrapper .demo-switcher_body ul li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.demo-switcher_wrapper .demo-switcher_body ul li a img {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  object-fit: cover;
}

.demo-switcher_wrapper .demo-switcher_body ul li a span {
  display: block;
  text-align: center;
  padding-top: 25px;
}

.demo-switcher_wrapper .demo-switcher_body ul li:hover a img {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.demo-switcher_wrapper.open .demo-switcher_body {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------*/
/*  11 - Components - Animation
/*----------------------------------------*/
/* ---Slider Animation--- */
.animation-style-01.swiper-slide-active .slide-content h1.heading {
  animation-name: fadeInDown;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content h1.sub-heading {
  animation-name: fadeInDown;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content p {
  animation-name: fadeInDown;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slide-content .button-wrap {
  animation-name: fadeInDown;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content span.date {
  animation-name: baSlideLeft;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content span.category {
  animation-name: baSlideUp;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content h1.heading {
  animation-name: baSlideLeft;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content .btn-wrap {
  animation-name: baSlideUp;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content .slide-nav_count span {
  animation-name: baSlideLeft;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-02.swiper-slide-active .slide-content .vertical-social_link {
  animation-name: baSlideLeft;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

/* ---Baroque's Slide Left--- */
@keyframes baSlideLeft {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes baSlideUp {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/*Baroque's Slide Up Animation*/
@keyframes barSlideInUp {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/*Top Down Keyframes*/
@-webkit-keyframes barSlideInDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

@keyframes barSlideInDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

.barSlideInDown {
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.kenburns-top {
  animation: kenburns-top 5s linear 1 normal both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-13 17:17:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@keyframes kenburns-top {
  0% {
    transform: scale(1) translateY(0);
    transform-origin: 50% 16%;
  }
  100% {
    transform: scale(1.05) translateY(-15px);
    transform-origin: top;
  }
}

/*----------------------------------------*/
/*  12 - Components - Button
/*----------------------------------------*/
/* ---baroque's Button Position--- */
.button-wrap {
  display: flex;
}

.button-wrap.position-center {
  justify-content: center;
}

/* ---baroque's Button--- */
.baroque-btn {
  background-color: #666600;
  color: #ffffff;
  width: 200px;
  height: 55px;
  line-height: 55px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Work Sans';
}

@media (max-width: 767px) {
  .baroque-btn {
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

.baroque-btn:hover {
  color: #ffffff;
}

/* ---Button Size--- */
.fullwidth-btn {
  width: 100%;
}

.btn-md_size {
  width: 150px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.btn-lg_size {
  width: 210px;
}

.btn-xl_size {
  width: auto;
  padding: 0 30px;
}

/* ---Additional Button--- */
/* ---Group Button--- */
.group-btn_wrap {
  display: flex;
}

.group-btn_wrap [class*="baroque-btn"]:not(:last-child) {
  margin-right: 25px;
}

/* --Read More Button-- */
.btn-wrap.inner-img_style {
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 15px;
  z-index: 1;
}

.btn-wrap .readmore-btn {
  font-family: 'Work Sans';
  color: #000000;
}

.btn-wrap .readmore-btn i {
  color: #000000;
  font-size: 12px;
}

.btn-wrap .readmore-btn.with-border {
  border-bottom: 1px solid #666600;
  font-size: 18px;
  line-height: 28.8px;
  font-weight: 500;
  text-transform: uppercase;
  color: #666600;
}

/* ---Fixed Button--- */
.fixed-btn_wrap {
  display: none;
}

@media (min-width: 1367px) {
  .fixed-btn_wrap {
    display: block;
    position: fixed;
    top: 50%;
    left: 10px;
    transform: translateY(-50%) rotate(-90deg);
  }
  .fixed-btn_wrap a {
    text-transform: uppercase;
    color: #000000;
  }
}

/*----------------------------------------*/
/*  13 - Components - Slider
/*----------------------------------------*/
/* ---Home Slider--- */
.home-slider .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-slider .swiper-slide.bg-1, .home-slider .swiper-slide.bg-2, .home-slider .swiper-slide.bg-3 {
  background-image: url("../images/slider/bg/default/1.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  height: 620px;
}

@media (max-width: 767px) {
  .home-slider .swiper-slide.bg-1, .home-slider .swiper-slide.bg-2, .home-slider .swiper-slide.bg-3 {
    height: 410px;
  }
}

.home-slider .swiper-slide.bg-1.bg-2, .home-slider .swiper-slide.bg-2.bg-2, .home-slider .swiper-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/default/2.jpg");
}

.home-slider .swiper-slide.bg-1.bg-3, .home-slider .swiper-slide.bg-2.bg-3, .home-slider .swiper-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/default/3.jpg");
}

.home-slider .swiper-slide .slide-content {
  text-align: center;
}

.home-slider .swiper-slide .slide-content h1 {
  font-family: 'Work Sans';
}

.home-slider .swiper-slide .slide-content h1.heading {
  font-size: 48px;
  line-height: 48px;
  padding-bottom: 5px;
}

@media (max-width: 767px) {
  .home-slider .swiper-slide .slide-content h1.heading {
    font-size: 25px;
    padding-bottom: 15px;
  }
}

.home-slider .swiper-slide .slide-content h1.sub-heading {
  padding-bottom: 35px;
}

@media (max-width: 767px) {
  .home-slider .swiper-slide .slide-content h1.sub-heading {
    font-size: 25px;
  }
}

.home-slider .swiper-slide .slide-content p.short-desc {
  font-size: 24px;
  line-height: 24px;
  color: #666666;
  padding-bottom: 75px;
}

@media (max-width: 767px) {
  .home-slider .swiper-slide .slide-content p.short-desc {
    font-size: 20px;
  }
}

/* ---Baroque Single Item--- */
.ba-single_item .slide-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@media (max-width: 767px) {
  .ba-single_item .slide-content {
    flex-direction: column;
  }
}

.ba-single_item .slide-content .slide-meta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .ba-single_item .slide-content .slide-meta {
    padding-bottom: 10px;
  }
}

.ba-single_item .slide-content .slide-meta ul.vertical-social_link {
  position: absolute;
  bottom: 45px;
  left: 80px;
}

@media (max-width: 1199px) {
  .ba-single_item .slide-content .slide-meta ul.vertical-social_link {
    left: 40px;
  }
}

@media (max-width: 767px) {
  .ba-single_item .slide-content .slide-meta ul.vertical-social_link {
    bottom: 45px;
    left: auto;
    right: 20px;
  }
  .ba-single_item .slide-content .slide-meta ul.vertical-social_link li {
    display: inline-block;
  }
  .ba-single_item .slide-content .slide-meta ul.vertical-social_link li:not(:last-child) {
    padding-right: 25px;
  }
}

.ba-single_item .slide-content .slide-text-with_nav {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 85%;
}

@media (max-width: 575px) {
  .ba-single_item .slide-content .slide-text-with_nav {
    width: 100%;
  }
}

.ba-single_item .slide-content .slide-text-with_nav span.category {
  font-size: 13px;
  line-height: 13px;
  padding-bottom: 30px;
  display: block;
}

.ba-single_item .slide-content .slide-text-with_nav .inner-text h1.heading {
  max-width: 65%;
  font-size: 63px;
  line-height: 63px;
  letter-spacing: -2px;
  padding-bottom: 65px;
}

@media (max-width: 1199px) {
  .ba-single_item .slide-content .slide-text-with_nav .inner-text h1.heading {
    font-size: 40px;
    line-height: 1.2;
  }
}

@media (max-width: 575px) {
  .ba-single_item .slide-content .slide-text-with_nav .inner-text h1.heading {
    max-width: 95%;
  }
}

.ba-single_item .slide-content .slide-text-with_nav .inner-text .btn-wrap {
  display: inline-block;
  vertical-align: text-bottom;
  padding-right: 15px;
}

.ba-single_item .slide-content .slide-text-with_nav .inner-text .btn-wrap a {
  font-size: 14px;
  line-height: 14px;
}

.ba-single_item .slide-content .slide-text-with_nav .inner-text .btn-wrap a:hover {
  color: #ffffff;
}

.ba-single_item .slide-content .slide-text-with_nav .inner-text .btn-wrap a i {
  vertical-align: middle;
  font-size: 10px;
}

.ba-single_item .slide-content .slide-text-with_nav .page-nav {
  position: absolute;
  transform: translateY(120px);
  display: inline-block;
}

@media (max-width: 767px) {
  .ba-single_item .slide-content .slide-text-with_nav .page-nav {
    position: relative;
    transform: none;
  }
}

.ba-single_item .slide-content .slide-text-with_nav .page-nav a i {
  font-size: 30px;
}

@media (max-width: 767px) {
  .ba-single_item .slide-content .slide-text-with_nav .page-nav a i {
    display: block;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .ba-single_item .slide-content .slide-text-with_nav .slide-nav_count {
    transform: none;
    right: 20px;
    top: 20px;
  }
}

/* ---Minimal Home Slider--- */
.minimal-home_slider .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.minimal-home_slider .swiper-slide.bg-1, .minimal-home_slider .swiper-slide.bg-2, .minimal-home_slider .swiper-slide.bg-3 {
  background-image: url("../images/slider/bg/minimal/1.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 710px;
}

@media (max-width: 767px) {
  .minimal-home_slider .swiper-slide.bg-1, .minimal-home_slider .swiper-slide.bg-2, .minimal-home_slider .swiper-slide.bg-3 {
    height: 410px;
  }
}

.minimal-home_slider .swiper-slide.bg-1.bg-2, .minimal-home_slider .swiper-slide.bg-2.bg-2, .minimal-home_slider .swiper-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/minimal/2.jpg");
}

.minimal-home_slider .swiper-slide.bg-1.bg-3, .minimal-home_slider .swiper-slide.bg-2.bg-3, .minimal-home_slider .swiper-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/minimal/3.jpg");
}

.mh-additional_content {
  background-color: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 40px;
  cursor: pointer;
}

@media (min-width: 1501px) {
  .mh-additional_content {
    width: 945px;
  }
}

@media (min-width: 1200px) {
  .mh-additional_content {
    width: 865px;
  }
}

@media (max-width: 1199px) {
  .mh-additional_content {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .mh-additional_content {
    position: relative;
    width: 100%;
    padding: 40px 20px 0;
    margin-bottom: -10px;
  }
}

.mh-additional_content .swiper-slide a span {
  display: block;
}

.mh-additional_content .swiper-slide a span.heading {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.2px;
  max-width: 71%;
}

@media (max-width: 1199px) {
  .mh-additional_content .swiper-slide a span.heading {
    max-width: 90%;
    font-size: 21px;
  }
}

.mh-additional_content .swiper-slide a:hover span {
  color: #000000;
}

.mh-additional_content .swiper-slide.swiper-slide-thumb-active a span {
  color: #000000;
}

/* ---Home Verticle Slider--- */
@media (max-width: 1366px) {
  .slider-area-3 .container {
    width: 100%;
    padding-right: 100px;
    padding-left: 80px;
    max-width: 100% !important;
  }
}

@media (max-width: 1199px) {
  .slider-area-3 .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .slider-area-3 .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.home-verticle_slider {
  width: 100%;
  height: 100vh;
}

.home-verticle_slider .swiper-wrapper {
  height: 100% !important;
}

.home-verticle_slider .swiper-wrapper .swiper-slide {
  height: 100% !important;
  display: flex;
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  position: relative;
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-content h2.heading {
  font-size: 72px;
  line-height: 72px;
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 15px;
}

@media (max-width: 767px) {
  .home-verticle_slider .swiper-wrapper .swiper-slide .slide-content h2.heading {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.5px;
    padding: 10px;
  }
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-content span.category {
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  padding-bottom: 65px;
  display: block;
}

@media (max-width: 767px) {
  .home-verticle_slider .swiper-wrapper .swiper-slide .slide-content span.category {
    padding-bottom: 15px;
    font-size: 14px;
    line-height: 22.4px;
  }
}

@media (max-width: 767px) {
  .home-verticle_slider .swiper-wrapper .swiper-slide .slide-content p {
    display: none;
  }
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-content p.short-desc {
  padding-bottom: 85px;
  max-width: 53%;
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-content p.short-desc.different-width {
  max-width: 42%;
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-content .btn-wrap a {
  color: #000000;
  font-weight: 500;
  text-transform: uppercase;
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-content .btn-wrap a:hover {
  color: #666600;
}

.home-verticle_slider .swiper-wrapper .swiper-slide .slide-img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 1199px) {
  .home-verticle_slider .swiper-wrapper .swiper-slide .slide-img {
    width: 74%;
  }
}

@media (max-width: 991px) {
  .home-verticle_slider .swiper-wrapper .swiper-slide .slide-img {
    width: 85%;
  }
}

@media (max-width: 767px) {
  .home-verticle_slider .swiper-wrapper .swiper-slide .slide-img {
    width: 80%;
  }
}

/* ---Home Parallax Slider--- */
.parallax-home_slider .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parallax-home_slider .swiper-slide.bg-1, .parallax-home_slider .swiper-slide.bg-2, .parallax-home_slider .swiper-slide.bg-3, .parallax-home_slider .swiper-slide.bg-4, .parallax-home_slider .swiper-slide.bg-5 {
  background-image: url("../images/slider/bg/parallax/1.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 100vh;
}

.parallax-home_slider .swiper-slide.bg-1.bg-2, .parallax-home_slider .swiper-slide.bg-2.bg-2, .parallax-home_slider .swiper-slide.bg-3.bg-2, .parallax-home_slider .swiper-slide.bg-4.bg-2, .parallax-home_slider .swiper-slide.bg-5.bg-2 {
  background-image: url("../images/slider/bg/parallax/2.jpg");
}

.parallax-home_slider .swiper-slide.bg-1.bg-3, .parallax-home_slider .swiper-slide.bg-2.bg-3, .parallax-home_slider .swiper-slide.bg-3.bg-3, .parallax-home_slider .swiper-slide.bg-4.bg-3, .parallax-home_slider .swiper-slide.bg-5.bg-3 {
  background-image: url("../images/slider/bg/parallax/3.jpg");
}

.parallax-home_slider .swiper-slide.bg-1.bg-4, .parallax-home_slider .swiper-slide.bg-2.bg-4, .parallax-home_slider .swiper-slide.bg-3.bg-4, .parallax-home_slider .swiper-slide.bg-4.bg-4, .parallax-home_slider .swiper-slide.bg-5.bg-4 {
  background-image: url("../images/slider/bg/parallax/4.jpg");
}

.parallax-home_slider .swiper-slide.bg-1.bg-5, .parallax-home_slider .swiper-slide.bg-2.bg-5, .parallax-home_slider .swiper-slide.bg-3.bg-5, .parallax-home_slider .swiper-slide.bg-4.bg-5, .parallax-home_slider .swiper-slide.bg-5.bg-5 {
  background-image: url("../images/slider/bg/parallax/5.jpg");
}

@media (max-width: 1199px) {
  .parallax-home_slider .swiper-slide .slide-content .slide-nav_count {
    right: 40px;
  }
}

@media (max-width: 767px) {
  .parallax-home_slider .swiper-slide .slide-content .slide-nav_count {
    transform: none;
    right: 20px;
    top: 60%;
  }
}

@media (max-width: 767px) {
  .parallax-home_slider .swiper-slide .slide-content .inner-text h1.heading {
    padding-bottom: 30px;
  }
}

.parallax-home_slider .swiper-slide .slide-content .inner-text .btn-wrap a:hover {
  color: inherit;
}

@media (max-width: 1199px) {
  .parallax-home_slider .swiper-pagination {
    display: none;
  }
}

/* ---Home Onepage Slider--- */
.slider-area-5 {
  display: flex;
}

.slider-area-5 .home-onepage_slider {
  flex-grow: 1;
  width: calc(100% - 140px);
}

@media (max-width: 767px) {
  .slider-area-5 .home-onepage_slider {
    width: calc(100% - 70px);
  }
}

.slider-area-5 .additional-content {
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .slider-area-5 .additional-content {
    width: 70px;
  }
}

.slider-area-5 .additional-content p.short-desc {
  writing-mode: tb-rl;
}

.home-onepage_slider .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.home-onepage_slider .swiper-slide.bg-1, .home-onepage_slider .swiper-slide.bg-2, .home-onepage_slider .swiper-slide.bg-3 {
  background-image: url("../images/slider/bg/onepage/1.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 580px;
  position: relative;
}

@media (max-width: 767px) {
  .home-onepage_slider .swiper-slide.bg-1, .home-onepage_slider .swiper-slide.bg-2, .home-onepage_slider .swiper-slide.bg-3 {
    height: 410px;
  }
}

.home-onepage_slider .swiper-slide.bg-1:before, .home-onepage_slider .swiper-slide.bg-2:before, .home-onepage_slider .swiper-slide.bg-3:before {
  background-color: #666666;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
  opacity: 0.2;
}

.home-onepage_slider .swiper-slide.bg-1.bg-2, .home-onepage_slider .swiper-slide.bg-2.bg-2, .home-onepage_slider .swiper-slide.bg-3.bg-2 {
  background-image: url("../images/slider/bg/onepage/2.jpg");
}

.home-onepage_slider .swiper-slide.bg-1.bg-3, .home-onepage_slider .swiper-slide.bg-2.bg-3, .home-onepage_slider .swiper-slide.bg-3.bg-3 {
  background-image: url("../images/slider/bg/onepage/3.jpg");
}

@media (max-width: 767px) {
  .home-onepage_slider .swiper-slide .slide-content .inner-text h1.heading {
    font-size: 28px;
  }
}

.home-onepage_slider .swiper-slide .slide-content .slide-nav_count {
  right: 80px;
}

@media (max-width: 1199px) {
  .home-onepage_slider .swiper-slide .slide-content .slide-nav_count {
    right: 40px;
  }
}

/* ---Home Zoom Slider--- */
.home-zoom_slider {
  width: 100%;
  height: 100vh;
}

.home-zoom_slider .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.home-zoom_slider .swiper-slide.bg-1, .home-zoom_slider .swiper-slide.bg-2, .home-zoom_slider .swiper-slide.bg-3 {
  height: 100vh;
}

.home-zoom_slider .swiper-slide.bg-1:before, .home-zoom_slider .swiper-slide.bg-2:before, .home-zoom_slider .swiper-slide.bg-3:before {
  background-image: url("../images/slider/bg/zoom/1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-zoom_slider .swiper-slide.bg-1.bg-2:before, .home-zoom_slider .swiper-slide.bg-2.bg-2:before, .home-zoom_slider .swiper-slide.bg-3.bg-2:before {
  background-image: url("../images/slider/bg/zoom/2.jpg");
}

.home-zoom_slider .swiper-slide.bg-1.bg-3:before, .home-zoom_slider .swiper-slide.bg-2.bg-3:before, .home-zoom_slider .swiper-slide.bg-3.bg-3:before {
  background-image: url("../images/slider/bg/zoom/3.jpg");
}

.home-zoom_slider .swiper-slide.swiper-slide-next.bg-1:before, .home-zoom_slider .swiper-slide.swiper-slide-next.bg-2:before, .home-zoom_slider .swiper-slide.swiper-slide-next.bg-3:before {
  transition: 2s ease-in-out;
  transform: scale(0.5);
}

.home-zoom_slider .swiper-slide.swiper-slide-prev.bg-1:before, .home-zoom_slider .swiper-slide.swiper-slide-prev.bg-2:before, .home-zoom_slider .swiper-slide.swiper-slide-prev.bg-3:before {
  transition: 2s ease-in-out;
  transform: scale(0.5);
}

.home-zoom_slider .swiper-slide.swiper-slide-active.bg-1:before, .home-zoom_slider .swiper-slide.swiper-slide-active.bg-2:before, .home-zoom_slider .swiper-slide.swiper-slide-active.bg-3:before {
  transform: scale(1);
  transition: 2s ease-in-out;
}

.home-zoom_slider .swiper-slide .slide-content .inner-content {
  max-width: 66%;
}

@media (min-width: 1501px) {
  .home-zoom_slider .swiper-slide .slide-content .inner-content {
    max-width: 39%;
  }
}

@media (max-width: 479px) {
  .home-zoom_slider .swiper-slide .slide-content .inner-content {
    max-width: 100%;
  }
}

.home-zoom_slider .swiper-slide .slide-content .inner-content h1.heading {
  font-size: 90px;
  line-height: 90px;
  padding-bottom: 35px;
}

@media (max-width: 1199px) {
  .home-zoom_slider .swiper-slide .slide-content .inner-content h1.heading {
    font-size: 30px;
    line-height: 1.2;
    padding-bottom: 10px;
  }
}

.home-zoom_slider .swiper-slide .slide-content .inner-content span {
  font-size: 24px;
  line-height: 38.4px;
}

@media (max-width: 1199px) {
  .home-zoom_slider .swiper-slide .slide-content .inner-content span {
    font-size: 18px;
    line-height: 32.4px;
  }
}

.home-zoom_slider .swiper-arrow_wrap.bottom-left {
  bottom: 120px;
  left: 80px;
}

@media (max-width: 1199px) {
  .home-zoom_slider .swiper-arrow_wrap.bottom-left {
    bottom: 135px;
    left: 40px;
  }
}

@media (max-width: 767px) {
  .home-zoom_slider .swiper-arrow_wrap.bottom-left {
    left: 20px;
  }
}

@media (max-width: 479px) {
  .home-zoom_slider .swiper-arrow_wrap.bottom-left {
    bottom: 185px;
  }
}

.home-zoom_slider .swiper-arrow_wrap.bottom-left .swiper-button-next {
  left: 50px;
}

.home-zoom_slider .swiper-arrow_wrap.bottom-left .swiper-button-next:after,
.home-zoom_slider .swiper-arrow_wrap.bottom-left .swiper-button-prev:after {
  color: #ccc;
  font-size: 30px;
}

/*----------------------------------------*/
/*  14 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumbs ul {
  display: flex;
}

@media (max-width: 575px) {
  .breadcrumbs ul {
    flex-wrap: wrap;
  }
}

.breadcrumbs ul li:not(:last-child) {
  padding-right: 5px;
}

@media (max-width: 767px) {
  .breadcrumbs ul li a,
  .breadcrumbs ul li span {
    font-size: 14px;
  }
}

/*----------------------------------------*/
/*  15 - Components - Form
/*----------------------------------------*/
.form-area h3.heading {
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 15px;
}

form.feedback-form .form-field {
  margin-bottom: 0;
  padding-bottom: 20px;
}

form.feedback-form .form-field textarea.textarea-field {
  height: 220px;
  width: 100%;
  padding: 25px;
}

form.feedback-form .form-field input.input-field {
  width: 100%;
  height: 50px;
  padding: 0 25px;
}

form.feedback-form .form-field.group-input {
  display: flex;
}

@media (max-width: 767px) {
  form.feedback-form .form-field.group-input {
    flex-direction: column;
  }
}

form.feedback-form .form-btn_wrap {
  padding-top: 25px;
}

form.feedback-form.with-border-bottom .form-field textarea.textarea-field {
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

form.feedback-form.with-border-bottom .form-field textarea.textarea-field:focus {
  border: 0;
  border-bottom: 1px solid #000000;
  color: #000000;
}

form.feedback-form.with-border-bottom .form-field input.input-field {
  border: 0;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

form.feedback-form.with-border-bottom .form-field input.input-field:focus {
  border: 0;
  border-bottom: 1px solid #000000;
  color: #000000;
}

/*----------------------------------------*/
/*  16 - Components - Newsletter
/*----------------------------------------*/
/* ---Newsletter Header--- */
@media (max-width: 767px) {
  .news-header {
    padding-bottom: 25px;
    text-align: center;
  }
}

.news-header h2.heading {
  font-size: 24px;
  line-height: 28.8px;
  padding-bottom: 5px;
}

/* ---Newsletter Form--- */
.newsletter-form_wrap .subscribe-form {
  position: relative;
}

.newsletter-form_wrap .subscribe-form .newsletter-input {
  border: 1px solid #e5e5e5;
  height: 50px;
  width: 100%;
  padding: 0 15px;
}

.newsletter-form_wrap .subscribe-form .newsletter-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.newsletter-form_wrap .subscribe-form .newsletter-btn {
  background-color: #666600;
  color: #ffffff;
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 479px) {
  .newsletter-form_wrap .subscribe-form .newsletter-btn {
    margin-top: 20px;
    position: unset;
    width: 100%;
  }
}

.newsletter-form_wrap .subscribe-form .newsletter-btn:hover {
  background-color: #000000;
}

/*----------------------------------------*/
/*  17 - Components - Social Link
/*----------------------------------------*/
.social-link li {
  display: inline-block;
  padding-right: 25px;
}

.social-link li:last-child {
  padding-right: 0;
}

.social-link li a {
  font-size: 16px;
  display: block;
}

.social-link.with-color li {
  padding-right: 10px;
}

.social-link.with-color li:last-child {
  padding-right: 0;
}

.social-link.with-color li a {
  color: #ffffff;
  font-size: 14px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.social-link.with-color li.facebook a {
  background-color: #3b579d;
}

.social-link.with-color li.twitter a {
  background-color: #3acaff;
}

.social-link.with-color li.google-plus a {
  background-color: #ca0813;
}

.social-link.with-color li.skype a {
  background-color: #00aff0;
}

.social-link.with-border-radius li a {
  border-radius: 3px;
}

.social-link.with-border-radius.sm-size li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.social-link.vertical-social_link {
  display: inline-block;
}

.social-link.vertical-social_link li {
  display: block;
  padding-right: 0;
  padding-bottom: 10px;
}

/*----------------------------------------*/
/*  18 - Components - Introduction
/*----------------------------------------*/
@media (max-width: 767px) {
  .intro-content {
    padding-bottom: 25px;
  }
}

.intro-content h2.heading {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: -1px;
  padding-bottom: 55px;
}

@media (max-width: 1199px) {
  .intro-content h2.heading {
    font-size: 30px;
  }
}

.intro-content p.short-desc {
  padding-bottom: 75px;
}

.intro-content .project-info span {
  text-transform: uppercase;
  display: block;
}

.intro-content .intro-btn {
  color: #000000;
  text-transform: uppercase;
}

.intro-content .intro-btn i {
  font-size: 14px;
  vertical-align: middle;
}

.intro-img {
  display: flex;
  justify-content: flex-end;
}

.introduction-area-3.intro-bg {
  background-image: url("../images/introduction/onepage/1.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  height: 935px;
}

@media (max-width: 991px) {
  .introduction-area-3.intro-bg {
    height: auto;
  }
}

.introduction-area-3 .intro-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}

@media (max-width: 991px) {
  .introduction-area-3 .intro-item {
    display: block;
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

.introduction-area-3 .intro-item .left-side {
  width: 41.666667%;
}

@media (max-width: 991px) {
  .introduction-area-3 .intro-item .left-side {
    width: 100%;
  }
}

.introduction-area-3 .intro-item .left-side .inner-img {
  padding-bottom: 95px;
}

@media (max-width: 991px) {
  .introduction-area-3 .intro-item .left-side .inner-img {
    padding-bottom: 45px;
  }
}

.introduction-area-3 .intro-item .left-side h3.ba-regular {
  color: #ffffff;
  font-size: 48px;
  line-height: 48px;
  font-weight: 300;
  font-family: 'Work Sans', sans-serif;
  max-width: 85%;
}

@media (max-width: 991px) {
  .introduction-area-3 .intro-item .left-side h3.ba-regular {
    padding-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .introduction-area-3 .intro-item .left-side h3.ba-regular {
    font-size: 28px;
    line-height: 1.2;
  }
}

.introduction-area-3 .intro-item .left-side h3.ba-regular span {
  font-weight: 600;
  display: block;
}

.introduction-area-3 .intro-item .right-side {
  width: 58.333333%;
}

@media (max-width: 991px) {
  .introduction-area-3 .intro-item .right-side {
    width: 100%;
  }
}

.introduction-area-3 .intro-item .right-side h2.heading {
  font-size: 60px;
  line-height: 60px;
  padding-bottom: 45px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .introduction-area-3 .intro-item .right-side h2.heading {
    font-size: 28px;
    line-height: 1.2;
  }
}

.introduction-area-3 .intro-item .right-side p.short-desc {
  color: #ffffff;
}

@media (max-width: 575px) {
  .introduction-area-3 .intro-item .right-side .project-info .counter-item {
    flex-wrap: wrap;
  }
  .introduction-area-3 .intro-item .right-side .project-info .counter-item li {
    width: 50%;
  }
  .introduction-area-3 .intro-item .right-side .project-info .counter-item li:not(:nth-child(n+3)) {
    padding-bottom: 15px;
  }
}

.introduction-area-4 {
  position: relative;
}

.introduction-area-4.intro-bg-2 {
  background-image: url("../images/introduction/onepage/2.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  height: 580px;
}

@media (max-width: 767px) {
  .introduction-area-4.intro-bg-2 {
    height: auto;
  }
}

.introduction-area-4 .intro-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@media (max-width: 767px) {
  .introduction-area-4 .intro-item {
    padding-top: 45px;
    padding-bottom: 45px;
    height: auto;
  }
}

.introduction-area-4 .intro-item > span {
  padding-bottom: 30px;
  display: block;
}

.introduction-area-4 .intro-item h2.contact-number {
  padding-bottom: 60px;
}

.introduction-area-4 .intro-item h2.contact-number a {
  font-size: 60px;
  line-height: 72px;
}

@media (max-width: 575px) {
  .introduction-area-4 .intro-item h2.contact-number a {
    font-size: 25px;
    line-height: 37px;
  }
}

.introduction-area-4 .intro-item h3.title {
  font-size: 18px;
  line-height: 21.6px;
  padding-bottom: 30px;
}

.introduction-area-4 .intro-item p.short-desc {
  max-width: 40%;
}

@media (max-width: 575px) {
  .introduction-area-4 .intro-item p.short-desc {
    max-width: 100%;
  }
}

.introduction-area-4 .additional-content {
  background-color: #ffffff;
  width: 140px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

@media (max-width: 767px) {
  .introduction-area-4 .additional-content {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 25px;
  }
}

.introduction-area-4 .additional-content .inner-content {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .introduction-area-4 .additional-content .inner-content ul.vertical-social_link li {
    display: inline-block;
  }
  .introduction-area-4 .additional-content .inner-content ul.vertical-social_link li:not(:last-child) {
    padding-right: 25px;
    padding-bottom: 0;
  }
}

/*----------------------------------------*/
/*  19 - Components - Service
/*----------------------------------------*/
@media (max-width: 991px) {
  .service-area .service-title {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .service-area .service-title h2.heading {
    padding-bottom: 20px;
  }
}

.service-item {
  position: relative;
}

.service-item .service-content .inner-content {
  background-color: #ffffff;
  width: calc(100% - 100px);
  padding: 30px 30px 0;
  margin-top: -100px;
  position: relative;
}

@media (max-width: 991px) {
  .service-item .service-content .inner-content {
    width: 100%;
    padding: 30px 0 0;
    margin-top: 0;
  }
}

.service-item .service-content .inner-content span {
  font-size: 18px;
  line-height: 28.8px;
  padding-bottom: 15px;
  display: block;
}

.service-item .service-content .inner-content h3.heading {
  padding-bottom: 35px;
}

.service-item .service-content .inner-content h3.heading a {
  font-size: 24px;
  line-height: 33.6px;
  text-transform: uppercase;
  font-family: 'Work Sans';
  color: #000000;
}

@media (max-width: 1199px) {
  .service-item .service-content .inner-content h3.heading a {
    font-size: 19px;
    line-height: 29.6px;
  }
}

.service-item .service-content .inner-content.full-width {
  width: 100%;
}

@media (max-width: 991px) {
  .service-item.style-two {
    padding-top: 90px;
  }
}

.service-item.style-two .service-img {
  position: relative;
}

.service-item.style-two .service-content .inner-content {
  margin-top: 0;
  padding: 30px 0 0;
}

.migrate-item_area .service-title span.category {
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  padding-bottom: 40px;
  display: block;
}

.migrate-item_area .service-title h2.heading {
  font-size: 48px;
  line-height: 57px;
  letter-spacing: -1.5px;
  padding-bottom: 65px;
  max-width: 95%;
}

@media (max-width: 991px) {
  .migrate-item_area .service-title h2.heading {
    font-size: 30px;
    line-height: 39px;
  }
}

.migrate-item_area .service-title .btn-wrap a {
  font-size: 25px;
}

.migrate-item_area .service-item .service-content .inner-content span {
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ee4300;
}

.migrate-item_area .service-item .service-content .inner-content span em.meta {
  color: #666666;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
}

.migrate-item_area .service-item .service-content .inner-content h3.heading {
  max-width: 84%;
  padding-bottom: 0;
}

.migrate-item_area .service-item .service-content .inner-content h3.heading a {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  text-transform: none;
}

@media (max-width: 991px) {
  .migrate-item_area .service-item .service-content .inner-content h3.heading a {
    font-size: 25px;
    line-height: 31px;
  }
}

@media (min-width: 992px) {
  .migrate-item_area .service-item .service-content .inner-content.md-size {
    width: calc(100% - 120px);
  }
}

.migrate-item_area .service-item .service-content .inner-content.md-size span {
  font-size: 14px;
  line-height: 22.8px;
}

.migrate-item_area .service-item .service-content .inner-content.md-size h3.heading {
  max-width: 100%;
}

@media (min-width: 992px) {
  .migrate-item_area .service-item .service-content .inner-content.sm-size {
    width: 280px;
  }
}

.migrate-item_area .service-item .service-content .inner-content.sm-size span {
  font-size: 14px;
  line-height: 22.8px;
}

.migrate-item_area .service-item .service-content .inner-content.sm-size h3.heading {
  max-width: 100%;
}

@media (max-width: 767px) {
  .migrate-item_area .specific-newsletter {
    padding-bottom: 100px;
  }
}

.migrate-item_area .specific-newsletter h2.heading {
  font-size: 48px;
  line-height: 57.6px;
  padding-bottom: 20px;
}

@media (max-width: 991px) {
  .migrate-item_area .specific-newsletter h2.heading {
    font-size: 30px;
    line-height: 39.6px;
  }
}

.migrate-item_area .specific-newsletter p.short-desc {
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: -0.2px;
  padding-bottom: 55px;
  max-width: 76%;
}

@media (max-width: 767px) {
  .migrate-item_area .specific-newsletter p.short-desc {
    padding-bottom: 0;
  }
}

.migrate-item_area .specific-newsletter p.short-desc span {
  color: #000000;
  font-weight: 600;
}

.migrate-item_area .specific-newsletter form.newsletters-form .subscribe-form {
  position: relative;
}

.migrate-item_area .specific-newsletter form.newsletters-form .subscribe-form input.newsletter-input {
  border: 0;
  border-bottom: 1px solid #000000;
  width: 100%;
  height: 50px;
  padding-right: 30px;
}

.migrate-item_area .specific-newsletter form.newsletters-form .subscribe-form .newsletter-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.migrate-item_area .specific-newsletter form.newsletters-form .subscribe-form .newsletter-btn i {
  color: #ee4300;
}

/*----------------------------------------*/
/*  20 - Components - Project
/*----------------------------------------*/
/* ---Latest Project Area--- */
.latest-project_area .project-title_wrap {
  align-items: flex-end;
  padding-bottom: 40px;
}

.latest-project_area .project-title_wrap .project-title h2.heading {
  max-width: 35%;
}

@media (max-width: 991px) {
  .latest-project_area .project-title_wrap .project-title h2.heading {
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .latest-project_area .project-title_wrap .project-title h2.heading {
    padding-bottom: 10px;
  }
}

.latest-project_area .project-title_wrap .btn-wrap .project-btn {
  text-transform: uppercase;
  color: #000000;
}

@media (max-width: 991px) {
  .latest-project_area .latest-project_slider .swiper-pagination {
    position: relative;
  }
}

/* ---List Project Item--- */
@media (max-width: 991px) {
  .list-project_item {
    padding-bottom: 20px;
  }
}

.list-project_item .single-content .inner-content {
  background-color: #ffffff;
  margin-top: -150px;
  padding: 40px 50px 0;
}

@media (max-width: 991px) {
  .list-project_item .single-content .inner-content {
    padding: 20px 0 0;
    margin-top: 0;
  }
}

.list-project_item .single-content .inner-content span {
  font-size: 18px;
  line-height: 28.8px;
  padding-bottom: 15px;
  display: block;
}

.list-project_item .single-content .inner-content h2.heading {
  font-size: 48px;
  line-height: 48px;
  padding-bottom: 15px;
  letter-spacing: -1px;
}

@media (max-width: 1199px) {
  .list-project_item .single-content .inner-content h2.heading {
    font-size: 25px;
    line-height: 33px;
  }
}

@media (max-width: 767px) {
  .list-project_item .single-content .inner-content h2.heading {
    font-size: 20px;
    line-height: 28px;
  }
}

.list-project_item .btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 991px) {
  .list-project_item .btn-wrap {
    justify-content: flex-start;
    padding-top: 15px;
  }
}

.list-project_item .btn-wrap .project-btn {
  font-size: 20px;
  line-height: 32px;
  font-family: 'Work Sans';
  color: #000000;
}

.list-project_item .btn-wrap .project-btn i {
  color: #000000;
  font-size: 15px;
}

/* ---Project Item--- */
.project-item .single-content span.project-category {
  font-size: 14px;
  line-height: 22.4px;
  text-transform: uppercase;
  color: #999999;
  padding-top: 15px;
  display: block;
}

.project-item .single-content h3.heading {
  padding-top: 5px;
}

.project-item .single-content h3.heading a {
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
  letter-spacing: -0.5px;
}

@media (max-width: 767px) {
  .project-item .single-content h3.heading a {
    font-size: 18px;
  }
}

/* ---Project Carousel Slider--- */
.project-carousel_slider .swiper-slide {
  width: 60%;
}

@media (max-width: 991px) {
  .project-carousel_slider .swiper-slide {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .project-carousel_slider .swiper-slide:nth-child(2n) {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .project-carousel_slider .swiper-slide:nth-child(3n) {
    width: 40%;
  }
}

.project-carousel_slider .swiper-slide .project-item {
  width: 100%;
  height: 100vh;
}

.project-carousel_slider .swiper-slide .project-item .single-img {
  width: 100%;
  height: 100vh;
}

.project-carousel_slider .swiper-slide .project-item .single-img .inner-img {
  width: 100%;
  height: 100vh;
}

.project-carousel_slider .swiper-scrollbar {
  border-radius: 0;
  height: 8px;
  position: relative;
  left: 0;
  bottom: 0;
  width: 85%;
  margin: 50px auto 0;
  cursor: pointer;
}

.project-carousel_slider .swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #666600;
  border-radius: 0;
}

/* ---Project Parallax Slider--- */
.project-parallax_slider {
  width: 100%;
  height: 100vh;
}

.project-parallax_slider .swiper-wrapper {
  height: 100%;
}

.project-parallax_slider .swiper-wrapper .swiper-slide {
  height: 100%;
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-1, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-2, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-3, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-4 {
  background-image: url("../images/project/bg/1-1880x1080.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  position: relative;
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-1.project-bg-2, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-2.project-bg-2, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-3.project-bg-2, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-4.project-bg-2 {
  background-image: url("../images/project/bg/2-1880x1080.jpg");
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-1.project-bg-3, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-2.project-bg-3, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-3.project-bg-3, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-4.project-bg-3 {
  background-image: url("../images/project/bg/3-1880x1080.jpg");
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-1.project-bg-4, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-2.project-bg-4, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-3.project-bg-4, .project-parallax_slider .swiper-wrapper .swiper-slide .project-item.project-bg-4.project-bg-4 {
  background-image: url("../images/project/bg/4-1880x1080.jpg");
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

@media (max-width: 575px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content {
  background-color: #ffffff;
  padding: 40px 50px;
  width: 680px;
}

@media (max-width: 991px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content {
    width: 460px;
  }
}

@media (max-width: 767px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content {
    width: 320px;
    padding: 20px 25px;
  }
}

@media (max-width: 575px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content {
    width: 100%;
  }
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content span {
  padding-bottom: 15px;
  display: block;
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content h1.heading {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -1px;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content h1.heading {
    font-size: 25px;
    line-height: 1.4;
  }
}

@media (max-width: 767px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content h1.heading {
    font-size: 18px;
    text-transform: capitalize;
  }
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .btn-wrap {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 575px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .btn-wrap {
    background-color: #ffffff;
    width: 100%;
  }
}

.project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .btn-wrap .project-btn {
  background-color: #ffffff;
  color: #000000;
  padding: 0 30px;
  height: 64px;
  font-size: 18px;
  line-height: 64px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .btn-wrap .project-btn {
    font-size: 16px;
    text-transform: capitalize;
  }
  .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .btn-wrap .project-btn i {
    font-size: 14px;
  }
}

/* ---Project Details Area--- */
.project-details_area .pd-content {
  background-color: #ffffff;
  padding: 40px 50px 0;
  margin-top: -160px;
  width: 680px;
}

@media (max-width: 991px) {
  .project-details_area .pd-content {
    padding: 40px 0 0;
  }
}

@media (max-width: 767px) {
  .project-details_area .pd-content {
    padding: 20px 0 0;
    margin-top: 0;
    width: 100%;
  }
}

.project-details_area .pd-content span {
  padding-bottom: 15px;
  display: block;
}

.project-details_area .pd-content h1.heading {
  padding-bottom: 40px;
}

.project-details_area .pd-content p.short-desc {
  padding-bottom: 45px;
}

.project-details_area .pd-content h3.sub-heading {
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .project-details_area .sidebar-area {
    padding-top: 90px;
  }
}

@media (max-width: 991px) {
  .project-details_area .sidebar-area .project-meta .single-item {
    text-align: left;
  }
}

.project-details_area .single-project_slider {
  padding-top: 95px;
}

@media (max-width: 991px) {
  .project-details_area .single-project_slider {
    padding-top: 100px;
  }
}

.project-details_area .single-project_slider .single-project.bg-1, .project-details_area .single-project_slider .single-project.bg-2, .project-details_area .single-project_slider .single-project.bg-3 {
  background-image: url("../images/project/bg/1-1170x570.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 570px;
}

.project-details_area .single-project_slider .single-project.bg-1.bg-2, .project-details_area .single-project_slider .single-project.bg-2.bg-2, .project-details_area .single-project_slider .single-project.bg-3.bg-2 {
  background-image: url("../images/project/bg/2-1170x570.jpg");
}

.project-details_area .single-project_slider .single-project.bg-1.bg-3, .project-details_area .single-project_slider .single-project.bg-2.bg-3, .project-details_area .single-project_slider .single-project.bg-3.bg-3 {
  background-image: url("../images/project/bg/3-1170x570.jpg");
}

/* ---Product Details Style Two--- */
.pd-style_two {
  padding-bottom: 20px;
}

/* ---Product Details Style Three--- */
.pd-style_three {
  padding-right: 460px;
  padding-top: 85px;
}

@media (max-width: 991px) {
  .pd-style_three {
    padding-top: 0;
    padding-right: 0;
  }
}

.pd-style_three .project-gallery .single-img {
  cursor: pointer;
}

.pd-style_three .project-gallery .single-img[class*="single-img"]:not(:last-child) {
  padding-bottom: 20px;
}

.pd-style_three .fixed-sidebar {
  background-color: #f2f2f2;
  position: fixed;
  top: 85px;
  right: 20px;
  width: 440px;
  height: 100%;
}

@media (max-width: 991px) {
  .pd-style_three .fixed-sidebar {
    margin-top: 20px;
    position: unset;
    width: 100%;
  }
}

.pd-style_three .fixed-sidebar .inner-content {
  padding: 55px 45px 0;
  overflow-y: auto;
  height: 83%;
}

@media (max-width: 991px) {
  .pd-style_three .fixed-sidebar .inner-content {
    height: 100%;
  }
}

@media (max-width: 479px) {
  .pd-style_three .fixed-sidebar .inner-content {
    padding: 25px 30px 0;
  }
}

.pd-style_three .fixed-sidebar .pagination-area {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 25px;
  margin-bottom: 20px;
}

.pd-style_three .fixed-sidebar .pagination-area ul li a {
  font-size: 16px;
  line-height: 25.6px;
}

.pd-style_three .fixed-sidebar h1.heading {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1px;
  padding-bottom: 15px;
}

@media (max-width: 479px) {
  .pd-style_three .fixed-sidebar h1.heading {
    font-size: 25px;
    line-height: 31px;
  }
}

.pd-style_three .fixed-sidebar p.short-desc {
  font-size: 14px;
  line-height: 22.4px;
  color: #999999;
}

.pd-style_three .fixed-sidebar .project-meta {
  padding-top: 50px;
}

.pd-style_three .fixed-sidebar .project-meta > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pd-style_three .fixed-sidebar .project-meta > ul > li:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

@media (max-width: 479px) {
  .pd-style_three .fixed-sidebar .project-meta > ul > li.social-link_wrap {
    flex-direction: column;
  }
}

.pd-style_three .fixed-sidebar .project-meta > ul > li h3.meta-heading {
  font-size: 14px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.pd-style_three .fixed-sidebar .project-meta > ul > li span.meta-content {
  font-size: 16px;
  line-height: 25.6px;
}

.pd-style_three .fixed-sidebar .project-meta > ul > li ul {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}

.pd-style_three .fixed-sidebar .copyright {
  padding-bottom: 55px;
}

@media (max-width: 479px) {
  .pd-style_three .fixed-sidebar .copyright {
    padding-bottom: 25px;
  }
}

.empty-space {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 1;
}

/* ---Product Details Style Four--- */
.pd-style_four .project-meta ul {
  display: flex;
  flex-wrap: wrap;
}

.pd-style_four .project-meta ul li {
  width: 25%;
}

@media (max-width: 767px) {
  .pd-style_four .project-meta ul li {
    width: 33.333333%;
  }
  .pd-style_four .project-meta ul li:not(:last-child) {
    padding-bottom: 25px;
  }
}

@media (max-width: 479px) {
  .pd-style_four .project-meta ul li {
    width: 50%;
  }
  .pd-style_four .project-meta ul li:nth-child(3) {
    padding-bottom: 0;
  }
}

.pd-style_four .project-meta ul li span.meta-content {
  font-size: 14px;
  line-height: 16.8px;
  text-transform: uppercase;
  color: #999999;
  padding-bottom: 5px;
  display: block;
}

.pd-style_four .project-meta ul li h3.meta-heading {
  font-size: 18px;
  line-height: 28.8px;
  font-weight: 400;
}

/* ---Onepage Project Slider--- */
.onepage-project_slider {
  width: 100%;
  height: 100vh;
}

.onepage-project_slider .swiper-slide {
  display: flex;
  height: 100%;
}

@media (max-width: 767px) {
  .onepage-project_slider .swiper-slide {
    flex-direction: column-reverse;
  }
}

.onepage-project_slider .swiper-slide .slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.onepage-project_slider .swiper-slide .slide-content h2.heading {
  font-size: 72px;
  line-height: 72px;
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 15px;
}

@media (max-width: 767px) {
  .onepage-project_slider .swiper-slide .slide-content h2.heading {
    font-size: 25px;
    line-height: 1.2;
    padding-left: 0;
    margin-bottom: 0;
  }
}

.onepage-project_slider .swiper-slide .slide-content span.area {
  color: #000000;
  padding-bottom: 35px;
  display: block;
}

.onepage-project_slider .swiper-slide .slide-content p.location, .onepage-project_slider .swiper-slide .slide-content p.year {
  color: #000000;
}

.onepage-project_slider .swiper-slide .slide-content p.location span, .onepage-project_slider .swiper-slide .slide-content p.year span {
  color: #666666;
}

.onepage-project_slider .swiper-slide .slide-content .btn-wrap {
  padding-top: 90px;
}

.onepage-project_slider .swiper-slide .slide-content .btn-wrap a {
  color: #000000;
}

.onepage-project_slider .swiper-slide .slide-content .btn-wrap a i {
  font-size: 12px;
}

.onepage-project_slider .swiper-slide .slide-img {
  position: relative;
  height: 100%;
}

@media (min-width: 768px) {
  .onepage-project_slider .swiper-slide .slide-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
  }
}

.onepage-project_slider .swiper-slide .slide-img .slide-nav_count {
  top: auto;
  right: auto;
  transform: none;
  bottom: 30px;
  left: 30px;
}

@media (max-width: 767px) {
  .onepage-project_slider .swiper-slide .slide-img .slide-nav_count {
    left: auto;
    right: 30px;
  }
}

.onepage-project_slider .swiper-slide .slide-img .slide-nav_count span {
  color: #ffffff;
}

.onepage-project_slider .swiper-slide .slide-img .slide-nav_count span.data-count:before {
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;
}

.onepage-project_slider .swiper-slide .slide-img .slide-nav_count span sup {
  font-size: 16px;
  line-height: 16px;
  vertical-align: super;
  padding-left: 5px;
}

@media (max-width: 767px) {
  .onepage-project_slider .swiper-arrow_wrap {
    display: none;
  }
}

/*----------------------------------------*/
/*  21 - Components - Testimonials
/*----------------------------------------*/
.testimonials-item {
  border: 5px solid #666600;
  padding: 55px 60px;
  display: flex;
}

@media (max-width: 767px) {
  .testimonials-item {
    flex-direction: column;
    padding: 25px 30px;
  }
}

.testimonials-item.without-border {
  border: 0;
  padding: 0;
}

.testimonials-item .quote-header {
  display: flex;
  width: 34%;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .testimonials-item .quote-header {
    width: 100%;
  }
}

.testimonials-item .quote-header h3.heading {
  max-width: 75%;
  font-size: 20px;
  line-height: 24px;
}

@media (max-width: 1199px) {
  .testimonials-item .quote-header h3.heading {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .testimonials-item .quote-header h3.heading {
    padding-bottom: 25px;
  }
}

.testimonials-item .quote-header .quote-icon {
  background-color: #ced08d;
  border: 1px solid #ced08d;
  border-radius: 100%;
  position: relative;
  width: 97px;
  height: 97px;
  margin-left: 46px;
  margin-bottom: 23px;
}

.testimonials-item .quote-header .quote-icon i {
  color: #000000;
  font-size: 180px;
  position: absolute;
  bottom: -65px;
  left: -90px;
}

.testimonials-item .quote-content {
  width: 75%;
}

@media (max-width: 767px) {
  .testimonials-item .quote-content {
    padding-top: 15px;
    width: 100%;
  }
}

.testimonials-item .quote-content p.feedback {
  font-size: 28px;
  line-height: 44.8px;
  font-family: 'Libre Baskerville';
  color: #000000;
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .testimonials-item .quote-content p.feedback {
    font-size: 16px;
    line-height: 32.8px;
  }
}

.testimonials-item .quote-content .user-info span {
  font-size: 18px;
  line-height: 28.8px;
}

.testimonials-item .quote-content .user-info span.user-name {
  color: #000000;
}

/*----------------------------------------*/
/*  22 - Components - News
/*----------------------------------------*/
/* ---Single Item--- */
.single-item .single-content .news-meta {
  display: flex;
  padding-top: 20px;
}

.single-item .single-content .news-meta span {
  font-size: 14px;
  line-height: 22.4px;
  text-transform: uppercase;
  font-family: serif;
}

.single-item .single-content .news-meta span.meta-category {
  padding-left: 5px;
}

.single-item .single-content .news-meta span.meta-category a {
  font-size: 14px;
  line-height: 22.4px;
  text-transform: uppercase;
  color: #666600;
}

.single-item .single-content h3.heading {
  padding-top: 5px;
}

.single-item .single-content h3.heading a {
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
  letter-spacing: -0.5px;
}

@media (max-width: 1199px) {
  .single-item .single-content h3.heading a {
    font-size: 20px;
  }
}

.single-item .single-content p.short-desc {
  font-size: 14px;
  line-height: 22.8px;
  padding-top: 20px;
}

.single-item[class*="single-item"]:not(:last-child) {
  padding-bottom: 55px;
}

/* ---Latest New Slider--- */
.latest-news_slider .single-item[class*="single-item"]:not(:last-child) {
  padding-bottom: 0;
}

/* ---Single List Item--- */
.single-list_item {
  display: flex;
  cursor: pointer;
}

@media (max-width: 767px) {
  .single-list_item {
    flex-direction: column;
  }
}

.single-list_item .single-img {
  width: calc(100% - 400px);
}

@media (max-width: 767px) {
  .single-list_item .single-img {
    width: 100%;
  }
}

.single-list_item .single-content {
  background: #f2f2f2;
  padding: 50px 55px;
  width: 400px;
}

@media (max-width: 767px) {
  .single-list_item .single-content {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .single-list_item .single-content {
    padding: 20px 25px;
  }
}

.single-list_item .single-content .news-meta {
  padding-bottom: 10px;
}

.single-list_item .single-content .news-meta span.meta-category a {
  color: #666600;
}

.single-list_item .single-content h3.heading {
  padding-bottom: 30px;
}

.single-list_item .single-content h3.heading a {
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
  letter-spacing: -1px;
}

.single-list_item .single-content p.short-desc {
  font-size: 14px;
  line-height: 22.4px;
}

.single-list_item .single-content .btn-wrap {
  padding-top: 205px;
}

@media (max-width: 767px) {
  .single-list_item .single-content .btn-wrap {
    padding-top: 35px;
  }
}

.single-list_item:hover .single-content {
  background-color: #666600;
}

.single-list_item:hover .single-content h3.heading a {
  color: #ffffff;
}

.single-list_item:hover .single-content span,
.single-list_item:hover .single-content span.meta-category a,
.single-list_item:hover .single-content p {
  color: #ccc;
}

.single-list_item:hover .single-content .btn-wrap a,
.single-list_item:hover .single-content .btn-wrap a i {
  color: #ffffff;
}

.single-list_item[class*="single-list_item"]:not(:last-child) {
  padding-bottom: 50px;
}

/* ---Masonary Item--- */
.masonary-item {
  cursor: pointer;
}

.masonary-item .single-img.grid-img_height {
  height: 290px;
}

.masonary-item .single-img.grid-img_height img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.masonary-item .single-img.grid-img_height2 {
  height: 380px;
}

.masonary-item .single-img.grid-img_height2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.masonary-item .single-content {
  background: #f2f2f2;
  padding: 25px 30px;
}

.masonary-item .single-content .news-meta {
  padding-bottom: 5px;
  padding-top: 0;
}

.masonary-item .single-content .news-meta span.meta-category a {
  color: #666600;
}

.masonary-item .single-content h3.heading {
  padding-bottom: 15px;
}

.masonary-item .single-content h3.heading a {
  font-size: 24px;
  line-height: 28.8px;
  color: #000000;
  letter-spacing: -1px;
}

.masonary-item .single-content p.short-desc {
  font-size: 14px;
  line-height: 22.4px;
}

.masonary-item .single-content .btn-wrap {
  padding-top: 15px;
}

.masonary-item:hover .single-content {
  background-color: #666600;
}

.masonary-item:hover .single-content h3.heading a {
  color: #ffffff;
}

.masonary-item:hover .single-content span,
.masonary-item:hover .single-content span.meta-category a,
.masonary-item:hover .single-content p {
  color: #ccc;
}

.masonary-item:hover .single-content .btn-wrap a,
.masonary-item:hover .single-content .btn-wrap a i {
  color: #ffffff;
}

/* ---News Text Area--- */
.news-text_item {
  padding-bottom: 45px;
}

.news-text_item .single-content h3.heading {
  padding-bottom: 10px;
}

.news-text_item .single-content h3.heading a {
  font-size: 36px;
  line-height: 43.2px;
  color: #000000;
  letter-spacing: -1px;
}

@media (max-width: 767px) {
  .news-text_item .single-content h3.heading a {
    font-size: 25px;
    line-height: 1.2;
  }
}

.news-text_item .single-content h3.heading a:hover {
  color: #666600;
}

.news-text_item .single-content .news-meta span.meta-category a {
  text-transform: uppercase;
  color: #666600;
}

/* ---New Post Area--- */
.news-post_area .news-meta {
  padding-bottom: 30px;
}

.news-post_area .news-meta span {
  text-transform: uppercase;
}

.news-post_area .news-meta span.meta-category a {
  text-transform: uppercase;
  color: #666600;
}

.news-post_area h1.heading {
  font-size: 48px;
  line-height: 57.6px;
  padding-bottom: 80px;
  letter-spacing: -1px;
}

@media (max-width: 767px) {
  .news-post_area h1.heading {
    font-size: 30px;
    line-height: 39.6px;
  }
}

.news-post_area h2.sub-heading {
  font-size: 24px;
  line-height: 38.4px;
  font-weight: 400;
  letter-spacing: -0.2px;
  padding-bottom: 40px;
}

.news-post_area p.short-desc {
  padding-bottom: 45px;
}

.news-post_area .additional-content h3.heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  padding-top: 55px;
  padding-bottom: 45px;
}

@media (max-width: 479px) {
  .news-post_area .additional-content h3.heading {
    font-size: 18px;
  }
}

.news-post_area .additional-content ul.baroque-list {
  padding-left: 50px;
  list-style: disc;
  padding-bottom: 45px;
}

.news-post_area .additional-content ul.baroque-list li:not(:last-child) {
  padding-bottom: 10px;
}

.news-post_area .additional-content p.short-desc a {
  color: #00CCFF;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .news-post_area .form-area form.feedback-form .group-input [class*="input-field"]:not(:last-child) {
    margin-bottom: 20px;
  }
}

/*----------------------------------------*/
/*  23 - Components - Sidebar
/*----------------------------------------*/
@media (max-width: 991px) {
  .sidebar-area {
    padding-top: 95px;
  }
}

.sidebar-area .search-box {
  position: relative;
}

.sidebar-area .search-box input.search-box_input {
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 50px;
  padding: 0 45px 0 15px;
}

.sidebar-area .search-box button.search-box_btn {
  color: #454545;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.sidebar-area .search-box button.search-box_btn:hover {
  color: #666600;
}

.sidebar-area .widgets-area {
  padding-top: 55px;
}

.sidebar-area .widgets-area h2.heading {
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 25px;
}

.sidebar-area .widgets-area .widgets-item ul li:not(:last-child) a {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 11px;
}

.sidebar-area .widgets-area .widgets-item ul li a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  display: block;
}

.sidebar-area .widgets-area .widgets-item ul li a:hover {
  color: #666600;
}

.sidebar-area .widgets-area .widgets-item ul li a i {
  vertical-align: middle;
  padding-right: 10px;
}

.sidebar-area .widgets-area .widgets-item ul li span,
.sidebar-area .widgets-area .widgets-item ul li i {
  color: #999999;
}

.sidebar-area .widgets-area .widgets-item.without-border ul li:not(:last-child) a {
  border-bottom: 0;
  margin-bottom: 0;
}

.sidebar-area .widgets-area .widgets-item.tags-item ul li {
  display: inline-block;
}

.sidebar-area .widgets-area .widgets-item.tags-item ul li a {
  padding-bottom: 0;
  text-transform: none;
}

.sidebar-area .widgets-area .widgets-item.color-option ul li a {
  display: flex;
  justify-content: space-between;
}

.sidebar-area .widgets-area .widgets-item.color-option ul li a span {
  color: #000000;
}

.sidebar-area .widgets-area .widgets-item.color-option ul li:hover a span {
  color: #666600;
}

.sidebar-area .project-meta .single-item {
  text-align: right;
}

.sidebar-area .project-meta .single-item span {
  font-size: 14px;
  line-height: 16.8px;
  text-transform: uppercase;
  padding-bottom: 5px;
  color: #999999;
  display: block;
}

.sidebar-area .project-meta .single-item h3.heading {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.sidebar-area .project-meta .single-item[class*="single-item"]:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

/*----------------------------------------*/
/*  24 - Components - Tab
/*----------------------------------------*/
/* ---Project Tab--- */
.project-tab {
  padding-bottom: 45px;
}

.project-tab ul li:not(:last-child) {
  padding-right: 35px;
}

@media (max-width: 479px) {
  .project-tab ul li:not(:last-child) {
    padding-right: 15px;
  }
}

.project-tab ul li a {
  text-transform: uppercase;
  color: #999999;
}

/* ---Vertical Tab--- */
@media (max-width: 991px) {
  .vertical-tab {
    padding-bottom: 45px;
  }
}

.vertical-tab ul.tab-menu {
  flex-direction: column;
}

.vertical-tab ul.tab-menu li:not(:last-child) {
  padding-bottom: 15px;
}

.vertical-tab ul.tab-menu li a {
  text-transform: uppercase;
}

.vertical-tab ul.tab-menu li a.active {
  color: #666600;
}

/* ---Product Tab--- */
.product-tab ul.product-menu li:not(:last-child) {
  padding-right: 30px;
}

.product-tab ul.product-menu li a {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #999999;
}

.product-tab ul.product-menu li a.active {
  color: #000000;
}

.product-tab ul.product-menu li a:hover {
  color: #000000;
}

/*----------------------------------------*/
/*  25 - Components - Brand
/*----------------------------------------*/
.brand-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .brand-item {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .brand-item .single-img[class*="single-img"]:not(:last-child) {
    padding-right: 25px;
  }
}

@media (max-width: 767px) {
  .brand-item .single-img[class*="single-img"]:not(:last-child) {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .brand-item .single-img {
    width: 50%;
  }
}

.brand-item .single-img:hover img {
  opacity: 0.25;
}

.hover-tyle-2 .brand-item .single-img[class*="single-img"]:not(:last-child) {
  padding-right: 30px;
}

@media (max-width: 575px) {
  .hover-tyle-2 .brand-item .single-img[class*="single-img"]:not(:last-child) {
    padding-bottom: 30px;
  }
}

.hover-tyle-2 .brand-item .single-img img {
  opacity: 0.5;
}

.hover-tyle-2 .brand-item .single-img:hover img {
  opacity: 1;
}

/*----------------------------------------*/
/*  26 - Components - Team
/*----------------------------------------*/
.team-header {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 575px) {
  .team-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.team-header .heading-wrap {
  width: 50%;
}

@media (max-width: 575px) {
  .team-header .heading-wrap {
    padding-bottom: 10px;
    width: 100%;
  }
}

.team-header .heading-wrap h2.heading {
  font-size: 36px;
  line-height: 43.2px;
}

@media (max-width: 575px) {
  .team-header .heading-wrap h2.heading {
    font-size: 30px;
    line-height: 1.4;
  }
}

.team-header .btn-wrap .team-btn i {
  vertical-align: middle;
}

/* ---Member Wrap--- */
.member-wrap {
  margin-right: -10px;
  margin-left: -10px;
}

.member-wrap[class*="member-wrap"]:not(:last-child) {
  padding-bottom: 20px;
}

@media (max-width: 991px) {
  .member-wrap[class*="member-wrap"] [class*="col-"]:not(:nth-child(n+3)) .ba-member {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .member-wrap[class*="member-wrap"] [class*="col-"]:not(:last-child) .ba-member {
    margin-bottom: 20px;
  }
}

.member-wrap [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

/* ---Baroque Member--- */
.ba-member .single-content {
  text-align: right;
  padding: 15px 25px 25px;
}

.ba-member .single-content span.member-position {
  font-size: 14px;
  line-height: 22.4px;
  color: #000000;
  display: block;
  padding-bottom: 10px;
}

.ba-member .single-content h3.member-name {
  font-size: 24px;
  line-height: 28.8px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
}

.ba-member .single-content h3.member-name:before {
  background-color: #666600;
  width: 0;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
}

.ba-member .single-content ul.social-link li:not(:last-child) {
  padding-right: 10px;
}

.ba-member .single-content ul.social-link li a {
  color: #000000;
}

.ba-member .single-content ul.social-link li a:hover {
  color: #666600;
}

.ba-member:hover .single-content h3.member-name:before {
  width: 70px;
}

/* ---Apply Membership--- */
.apply-membership .membership-item h2.heading {
  font-size: 48px;
  line-height: 57.6px;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  .apply-membership .membership-item h2.heading {
    font-size: 30px;
    line-height: 39.6px;
    padding-bottom: 10px;
  }
}

.apply-membership .membership-item .btn-wrap {
  padding-top: 25px;
}

/*----------------------------------------*/
/*  28 - Components - Home Masonry
/*----------------------------------------*/
.home-masonry_content .section-area {
  text-align: center;
}

.home-masonry_content .section-area span {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 45px;
  display: block;
}

.home-masonry_content .section-area p.short-desc {
  color: #000000;
  max-width: 75%;
  font-size: 20px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .home-masonry_content .section-area p.short-desc {
    max-width: 85%;
  }
}

@media (max-width: 767px) {
  .home-masonry_content .section-area p.short-desc {
    max-width: 100%;
    font-size: 16px;
  }
}

.home-masonry_content .masonry-grid {
  display: flex;
  margin: 0;
}

.home-masonry_content .masonry-grid[class*="masonry-grid"]:not(:last-child) {
  padding-bottom: 80px;
}

@media (max-width: 575px) {
  .home-masonry_content .masonry-grid[class*="masonry-grid"]:not(:last-child) {
    padding-bottom: 20px;
  }
}

.home-masonry_content .masonry-grid .grid-item {
  padding-left: 0;
  padding-right: 20px;
  margin-bottom: 0;
  width: 33.333333%;
}

@media (max-width: 991px) {
  .home-masonry_content .masonry-grid .grid-item {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .home-masonry_content .masonry-grid .grid-item {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .home-masonry_content .masonry-grid .grid-item[class*="grid-item"]:last-child {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .home-masonry_content .masonry-grid .grid-item[class*="grid-item"]:nth-child(2) {
    padding-right: 0;
  }
  .home-masonry_content .masonry-grid .grid-item[class*="grid-item"]:first-child {
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .home-masonry_content .masonry-grid .grid-item[class*="grid-item"] {
    padding-right: 0;
  }
  .home-masonry_content .masonry-grid .grid-item[class*="grid-item"]:not(:last-child) {
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .home-masonry_content .masonry-grid .grid-item.grid-item--height2, .home-masonry_content .masonry-grid .grid-item.grid-item--height3 {
    height: auto;
  }
}

.home-masonry_content .masonry-grid .grid-item .img-hover_effect-3 .inner-content {
  padding: 25px 30px;
}

/*----------------------------------------*/
/*  29 - Components - Grid Background
/*----------------------------------------*/
.mouseover-effect ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.mouseover-effect ul li {
  display: inline-block;
  width: 25%;
  position: relative;
  height: 350px;
  cursor: pointer;
}

.mouseover-effect ul li:not(:nth-child(n+9)) {
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .mouseover-effect ul li {
    width: 33.333333%;
  }
  .mouseover-effect ul li:not(:nth-child(n+10)) {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .mouseover-effect ul li {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .mouseover-effect ul li {
    width: 100%;
  }
  .mouseover-effect ul li:not(:last-child) {
    margin-bottom: 30px;
  }
}

.mouseover-effect ul li .inner-list {
  border: 1px solid #e5e5e5;
  padding: 30px;
  height: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.mouseover-effect ul li .inner-list .single-img {
  height: 100%;
}

.mouseover-effect ul li .inner-list .single-img .grid-bg {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0.5;
  overflow: hidden;
}

.mouseover-effect ul li .inner-list .single-img .grid-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.mouseover-effect ul li .inner-list .single-img .grid-content span {
  font-size: 14px;
  line-height: 22.4px;
  padding-bottom: 10px;
  display: block;
}

.mouseover-effect ul li .inner-list .single-img .grid-content h2.title a {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #ffffff;
}

.mouseover-effect ul li.active .inner-list {
  background-color: #ee4300;
  border-color: #ee4300;
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg {
  background-image: url("../images/grid-background/1.jpg");
  opacity: 1;
  overflow: visible;
  animation: kenburns-top 5s linear 1 normal both;
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-2 {
  background-image: url("../images/grid-background/2.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-3 {
  background-image: url("../images/grid-background/3.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-4 {
  background-image: url("../images/grid-background/4.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-5 {
  background-image: url("../images/grid-background/5.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-6 {
  background-image: url("../images/grid-background/6.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-7 {
  background-image: url("../images/grid-background/7.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-8 {
  background-image: url("../images/grid-background/8.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-9 {
  background-image: url("../images/grid-background/9.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-10 {
  background-image: url("../images/grid-background/10.jpg");
}

.mouseover-effect ul li.active .inner-list .single-img .grid-bg.grid-bg-11 {
  background-image: url("../images/grid-background/11.jpg");
}

.mouseover-effect ul li:hover .inner-list {
  background-color: #ee4300;
  border-color: #ee4300;
}

/*----------------------------------------*/
/*  30 - Components - Approach
/*----------------------------------------*/
.approach-item {
  padding-bottom: 40px;
}

.approach-item span.number {
  font-size: 16px;
  line-height: 16px;
  color: #666600;
  padding-bottom: 30px;
  display: block;
}

.approach-item h3.title {
  padding-bottom: 30px;
}

/*----------------------------------------*/
/*  31 - Components - Text Interactive
/*----------------------------------------*/
.text-interactive-area ul.text-interactive li {
  display: flex;
}

.text-interactive-area ul.text-interactive li:not(:last-child) {
  padding-bottom: 45px;
}

.text-interactive-area ul.text-interactive li .single-content h2.heading {
  font-size: 48px;
  line-height: 48px;
  color: #bbbbbb;
  padding-bottom: 5px;
}

@media (max-width: 479px) {
  .text-interactive-area ul.text-interactive li .single-content h2.heading {
    font-size: 30px;
    line-height: 30px;
  }
}

.text-interactive-area ul.text-interactive li .fixed-img {
  background-image: url("../images/text-interactive/1.jpg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 2;
}

@media (max-width: 991px) {
  .text-interactive-area ul.text-interactive li .fixed-img {
    display: none;
  }
}

.text-interactive-area ul.text-interactive li.active .single-content h2.heading {
  color: #000000;
}

.text-interactive-area ul.text-interactive li.active .fixed-img {
  opacity: 1;
  visibility: visible;
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10 {
  background-image: url("../images/text-interactive/1.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-2, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-2 {
  background-image: url("../images/text-interactive/2.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-3, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-3 {
  background-image: url("../images/text-interactive/3.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-4, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-4 {
  background-image: url("../images/text-interactive/4.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-5, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-5 {
  background-image: url("../images/text-interactive/5.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-6, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-6 {
  background-image: url("../images/text-interactive/6.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-7, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-7 {
  background-image: url("../images/text-interactive/7.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-8, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-8 {
  background-image: url("../images/text-interactive/8.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-9, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-9 {
  background-image: url("../images/text-interactive/9.jpg");
}

.text-interactive-area ul.text-interactive li.active .fixed-img.bg-1.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-2.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-3.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-4.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-5.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-6.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-7.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-8.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-9.bg-10, .text-interactive-area ul.text-interactive li.active .fixed-img.bg-10.bg-10 {
  background-image: url("../images/text-interactive/10.jpg");
}

/*----------------------------------------*/
/*  32 - Components - Baroque Dark
/*----------------------------------------*/
.baroque-dark {
  background-color: #1b1b1b;
  color: #c7c7c7;
  /* ---Button--- */
  /* ---Offcanvas--- */
  /* ---Header Area--- */
  /* ---Slider Area--- */
  /* ---Service Item--- */
  /* ---Introduction--- */
  /* ---Latest Project--- */
  /* ---Project--- */
  /* ---Project Details--- */
  /* ---Empty Space--- */
  /* ---News Area--- */
  /* ---News Post Area--- */
  /* ---Blockquote--- */
  /* ---Tags List--- */
  /* ---Testimonials Item--- */
  /* ---Single Item--- */
  /* ---Footer Area--- */
  /* ---Image Hover Effect--- */
  /* ---Home Masonry--- */
  /* ---Home Vertical Slider Wrapper--- */
  /* ---Approach--- */
  /* ---Form--- */
  /* ---Baroque Dark Wrapper--- */
  /* ---Text Interactive Area--- */
  /* ---Main Page Heading--- */
  /* ---Sidebar--- */
  /* ---Pagination Area--- */
  /* ---Newsletter Form Wrap--- */
  /* ---Baroque Member--- */
  /* ---Error 404--- */
  /* ---Contact Area--- */
  /* ---Shop Area--- */
  /* ---Product Detail Area--- */
  /* ---Nice Select--- */
  /* ---Quantity--- */
  /* ---Product Detail Tab--- */
  /* ---Product Details Meta--- */
  /* ---Related Product--- */
  /* ---Checkout Area--- */
  /* ---Baroque Cart Area--- */
  /* ---My Account--- */
}

.baroque-dark .main-wrapper h2 {
  color: #ffffff;
}

.baroque-dark .main-wrapper p {
  color: #c7c7c7;
}

.baroque-dark .btn-wrap.inner-img_style {
  background-color: #1b1b1b;
}

.baroque-dark .btn-wrap.inner-img_style a,
.baroque-dark .btn-wrap.inner-img_style i {
  color: #ffffff;
}

.baroque-dark .btn-wrap .team-btn {
  color: #ffffff;
}

.baroque-dark .intro-btn {
  color: #c7c7c7;
}

.baroque-dark .mobile-menu_wrapper .offcanvas-body {
  background-image: url("../images/offcanvas/1.jpg");
  background-color: transparent;
}

.baroque-dark .mobile-menu_wrapper .offcanvas-body .inner-body {
  position: relative;
}

.baroque-dark .mobile-menu_wrapper .offcanvas-body .inner-body:before {
  background-color: rgba(0, 0, 0, 0.75);
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}

.baroque-dark .offcanvas-minicart_wrapper .offcanvas-body {
  background-color: #1b1b1b;
}

.baroque-dark .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-heading h4 {
  color: #ffffff;
}

.baroque-dark .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content a.product-item_title {
  color: #ffffff;
}

.baroque-dark .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li.minicart-product .product-item_content .product-item_quantity {
  color: #ffffff;
}

.baroque-dark .offcanvas-minicart_wrapper .offcanvas-body .minicart-content .minicart-list li:not(:last-child) {
  border-bottom: 1px solid #c7c7c7;
}

.baroque-dark .offcanvas-minicart_wrapper .offcanvas-body .minicart-item_total span {
  color: #ffffff;
}

.baroque-dark .main-header_area-2 .main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap,
.baroque-dark .main-header_area-4 .main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap,
.baroque-dark .main-header_area-6 .main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap {
  background-color: #1b1b1b;
}

.baroque-dark .main-header_area-2 .main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap ul.ba-megamenu span.title,
.baroque-dark .main-header_area-4 .main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap ul.ba-megamenu span.title,
.baroque-dark .main-header_area-6 .main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap ul.ba-megamenu span.title {
  color: #ffffff;
}

.baroque-dark .main-header_area-2 .main-header .main-nav > ul > li .baroque-dropdown,
.baroque-dark .main-header_area-4 .main-header .main-nav > ul > li .baroque-dropdown,
.baroque-dark .main-header_area-6 .main-header .main-nav > ul > li .baroque-dropdown {
  background-color: #1b1b1b;
}

.baroque-dark .main-header_area-2 .main-header.header-sticky,
.baroque-dark .main-header_area-4 .main-header.header-sticky,
.baroque-dark .main-header_area-6 .main-header.header-sticky {
  background-color: #1b1b1b;
}

.baroque-dark .main-header_area-7 .header-sticky {
  background-color: #1b1b1b;
}

.baroque-dark .slider-area-2 .mh-additional_content {
  background-color: #292828;
}

.baroque-dark .slider-area-2 .mh-additional_content .swiper-slide.swiper-slide-thumb-active a span {
  color: #ffffff;
}

.baroque-dark .slider-area-2 .mh-additional_content .swiper-slide a:hover span {
  color: #ffffff;
}

.baroque-dark .home-verticle_slider .swiper-wrapper .swiper-slide .slide-content h2.heading {
  background-color: transparent;
}

.baroque-dark .home-verticle_slider .swiper-wrapper .swiper-slide .slide-content span.category {
  color: #ffffff;
}

.baroque-dark .home-verticle_slider .swiper-wrapper .swiper-slide .slide-content .btn-wrap a {
  color: #ffffff;
}

.baroque-dark .service-item .service-content .inner-content {
  background-color: #1b1b1b;
}

.baroque-dark .service-item .service-content .inner-content h3.heading a {
  color: #ffffff;
}

.baroque-dark .service-item .service-content .inner-content a,
.baroque-dark .service-item .service-content .inner-content i {
  color: #ffffff;
}

.baroque-dark .migrate-item_area .service-title span.category {
  color: #ffffff;
}

.baroque-dark .migrate-item_area .specific-newsletter p.short-desc span {
  color: #ffffff;
}

.baroque-dark .migrate-item_area .specific-newsletter form.newsletters-form .subscribe-form input.newsletter-input {
  background-color: #1b1b1b;
  color: #c7c7c7;
  border-color: #c7c7c7;
}

.baroque-dark .introduction-area-4 .additional-content {
  background-color: #1b1b1b;
}

.baroque-dark .latest-project_area .btn-wrap a,
.baroque-dark .latest-project_area .btn-wrap i {
  color: #ffffff;
}

.baroque-dark .latest-project_area .list-project_item .single-content .inner-content {
  background-color: #1b1b1b;
}

.baroque-dark .project-item .single-content h3.heading a {
  color: #ffffff;
}

.baroque-dark .list-project_item .single-content .inner-content {
  background-color: #1b1b1b;
}

.baroque-dark .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content {
  background-color: #1b1b1b;
}

.baroque-dark .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .inner-content h1.heading {
  color: #ffffff;
}

.baroque-dark .project-parallax_slider .swiper-wrapper .swiper-slide .project-item .single-content .btn-wrap a.project-btn {
  background-color: #1b1b1b;
  color: #ffffff;
}

.baroque-dark .onepage-project_slider .swiper-slide .slide-content h2.heading {
  background-color: transparent;
}

.baroque-dark .onepage-project_slider .swiper-slide .slide-content span.area {
  color: #ffffff;
}

.baroque-dark .onepage-project_slider .swiper-slide .slide-content p.location, .baroque-dark .onepage-project_slider .swiper-slide .slide-content p.year {
  color: #ffffff;
}

.baroque-dark .onepage-project_slider .swiper-slide .slide-content .btn-wrap a {
  color: #ffffff;
}

.baroque-dark .project-details_area .pd-content {
  background-color: #1b1b1b;
}

.baroque-dark .project-details_area .pd-content h1,
.baroque-dark .project-details_area .pd-content h3 {
  color: #ffffff;
}

.baroque-dark .pd-style_three .fixed-sidebar .inner-content {
  background-color: #1b1b1b;
}

.baroque-dark .pd-style_three .fixed-sidebar .pagination-area {
  border-bottom: 1px solid #c7c7c7;
}

.baroque-dark .pd-style_three .fixed-sidebar h1.heading {
  color: #ffffff;
}

.baroque-dark .pd-style_three .fixed-sidebar .project-meta > ul > li:not(:last-child) {
  border-bottom: 1px solid #c7c7c7;
}

.baroque-dark .pd-style_three .fixed-sidebar .project-meta > ul > li h3, .baroque-dark .pd-style_three .fixed-sidebar .project-meta > ul > li span {
  color: #ffffff;
}

.baroque-dark .pd-style_four .project-meta ul li h3,
.baroque-dark .pd-style_four .project-meta ul li span {
  color: #ffffff;
}

.baroque-dark .empty-space {
  background-color: #1b1b1b;
}

.baroque-dark .news-area .single-list_item .single-content {
  background-color: #1b1b1b;
}

.baroque-dark .news-area .single-list_item .single-content h3.heading a {
  color: #ffffff;
}

.baroque-dark .news-area .single-list_item:hover .single-content {
  background-color: #666600;
}

.baroque-dark .news-masonry_area .masonary-item .single-content {
  background-color: #1b1b1b;
}

.baroque-dark .news-masonry_area .masonary-item:hover .single-content {
  background-color: #666600;
}

.baroque-dark .news-masonry_area .masonary-item .btn-wrap {
  background-color: transparent;
}

.baroque-dark .news-text_item .single-content h3.heading a {
  color: #ffffff;
}

.baroque-dark .news-text_item .single-content h3.heading a:hover {
  color: #666600;
}

.baroque-dark .news-post_area h1.heading {
  color: #ffffff;
}

.baroque-dark .news-post_area .additional-content h3.heading {
  color: #ffffff;
}

.baroque-dark blockquote:before {
  color: #ffffff;
}

.baroque-dark blockquote .user-info span.user-name {
  color: #ffffff;
}

.baroque-dark .tags-list span {
  color: #ffffff;
}

.baroque-dark .testimonials-item .quote-header h3.heading {
  color: #ffffff;
}

.baroque-dark .testimonials-item .quote-header .quote-icon i {
  color: #ffffff;
}

.baroque-dark .testimonials-item .quote-content p.feedback {
  color: #ffffff;
}

.baroque-dark .testimonials-item .quote-content .user-info span.user-name {
  color: #ffffff;
}

.baroque-dark .single-item .single-content h3 a {
  color: #ffffff;
}

.baroque-dark .single-item .btn-wrap {
  background-color: #1b1b1b;
}

.baroque-dark .single-item .btn-wrap a,
.baroque-dark .single-item .btn-wrap i {
  color: #ffffff;
}

.baroque-dark .footer-area {
  background-color: #161616;
}

.baroque-dark .footer-area .footer-widget li span.desc {
  color: #ffffff;
}

.baroque-dark .footer-area .footer-widget li span.desc a {
  color: #ffffff;
}

.baroque-dark .footer-area .footer-widget li a {
  color: #ffffff;
}

.baroque-dark .footer-area .footer-widget li.language-menu > ul.language-dropdown {
  background-color: #161616;
}

.baroque-dark .footer-area-2 .footer-widgets_area.with-border {
  border-top: 1px solid #c7c7c7;
}

.baroque-dark .img-hover_effect-3:before {
  background-color: #1b1b1b;
}

.baroque-dark .img-hover_effect-3 .single-img .inner-img .inner-content h3.heading {
  color: #ffffff;
}

.baroque-dark .home-masonry_content .section-area p.short-desc {
  color: #ffffff;
}

.baroque-dark .home-vs_wrapper {
  background-image: url("../images/home-background/vslide-bg-dark.jpg");
}

.baroque-dark .approach-item h3.title {
  color: #ffffff;
}

.baroque-dark form.feedback-form.with-border-bottom .form-field input.input-field {
  background-color: #1b1b1b;
  border-bottom: 1px solid #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark form.feedback-form.with-border-bottom .form-field textarea.textarea-field {
  background-color: #1b1b1b;
  border-bottom: 1px solid #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .form-area h3.heading {
  color: #ffffff;
}

.baroque-dark .form-area .feedback-form .form-field textarea.textarea-field {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .form-area .feedback-form .form-field input.input-field {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .ba-dark_wrapper {
  background-color: #1b1b1b;
}

.baroque-dark .ba-dark_wrapper .main-header_area .header-left_area .header-intro span {
  color: #ffffff;
}

.baroque-dark .ba-dark_wrapper .main-header_area .header-right_area ul li a,
.baroque-dark .ba-dark_wrapper .main-header_area .header-right_area ul li i {
  color: #ffffff;
}

.baroque-dark .text-interactive-area ul.text-interactive li.active .single-content h2.heading {
  color: #ffffff;
}

.baroque-dark .text-interactive-area ul.text-interactive li.active .single-content span.category {
  color: #ffffff;
}

.baroque-dark .main-page_heading {
  color: #ffffff;
}

.baroque-dark .sidebar-area .project-meta .single-item span,
.baroque-dark .sidebar-area .project-meta .single-item h3 {
  color: #ffffff;
}

.baroque-dark .sidebar-area .project-meta .single-item[class*="single-item"]:not(:last-child) {
  border-bottom: 1px solid #c7c7c7;
}

.baroque-dark .sidebar-area .search-box input.search-box_input {
  background-color: #1b1b1b;
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .sidebar-area .widgets-area .widgets-item ul li:not(:last-child) a {
  border-color: #c7c7c7;
}

.baroque-dark .sidebar-area .widgets-area .widgets-item ul li a {
  color: #ffffff;
}

.baroque-dark .pagination-area ul li a {
  color: #ffffff;
}

.baroque-dark .paginatoin-area-2.position-center {
  border-bottom: 1px solid #c7c7c7;
}

.baroque-dark .newsletter-form_wrap .subscribe-form .newsletter-input {
  background-color: #1b1b1b;
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .ba-member .single-content span.member-position {
  color: #ffffff;
}

.baroque-dark .ba-member .single-content h3.member-name {
  color: #ffffff;
}

.baroque-dark .ba-member .single-content ul.social-link li a {
  color: #ffffff;
}

.baroque-dark .ba-member .single-content ul.social-link li a:hover {
  color: #666600;
}

.baroque-dark .error-404_area h1.heading {
  color: #ffffff;
}

.baroque-dark .error-404_area .error-search_box input.input-field {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .contact-area .contact-info h1.heading {
  color: #ffffff;
}

.baroque-dark .contact-area .contact-info span.ba-email {
  color: #ffffff;
}

.baroque-dark .contact-area .contact-info span.ba-number a {
  color: #ffffff;
}

.baroque-dark .contact-area .contact-info .ba-brand span {
  color: #ffffff;
}

.baroque-dark .shop-area .banner-content .inner-content {
  background-color: #1b1b1b;
}

.baroque-dark .shop-area .banner-content .inner-content h1.heading {
  color: #ffffff;
}

.baroque-dark .shop-area .product-tab ul.product-menu li a.active {
  color: #ffffff;
}

.baroque-dark .shop-area .product-tab ul.product-menu li a:hover {
  color: #ffffff;
}

.baroque-dark .shop-area .shorting-menu ul li a {
  color: #ffffff;
}

.baroque-dark .shop-area .shorting-menu ul li a i {
  color: #ffffff;
}

.baroque-dark .shop-area .shorting-menu ul li > ul {
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.baroque-dark .shop-area .shorting-menu ul li > ul li a.active {
  color: #666600;
}

.baroque-dark .shop-area .shorting-menu ul li > ul li a:hover {
  color: #666600;
}

.baroque-dark .shop-area .product-item .product-content h3.product-name a {
  color: #ffffff;
}

.baroque-dark .product-detail_area .product-detail_content h1.heading {
  color: #ffffff;
}

.baroque-dark .nice-select {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
}

.baroque-dark .nice-select ul {
  background-color: #1b1b1b;
  border: 1px solid #c7c7c7;
}

.baroque-dark .quantity .cart-plus-minus .cart-plus-minus-box {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .quantity .cart-plus-minus .qtybutton {
  border-top: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
}

.baroque-dark .product-detail_tab .information-body table td,
.baroque-dark .product-detail_tab .information-body table th {
  border-top: 1px solid #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .product-detail_tab .tab-content .review-body .user-feedback h3.heading {
  color: #ffffff;
}

.baroque-dark .product-detail_tab .tab-content .review-body .user-feedback form.pd-feedback_form .form-field textarea.textarea-field {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .product-detail_tab .tab-content .review-body .user-feedback form.pd-feedback_form .form-field input.input-field {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .product-detail_meta .pd-meta_nav {
  border-color: #c7c7c7;
}

.baroque-dark .product-detail_meta .pd-meta_nav .sku-wrapper span strong,
.baroque-dark .product-detail_meta .pd-meta_nav .posted-in span strong {
  color: #ffffff;
}

.baroque-dark .related-ptoduct_area h6.heading {
  color: #ffffff;
}

.baroque-dark .related-ptoduct_area .product-item .product-content h3.product-name a {
  color: #ffffff;
}

.baroque-dark .checkout-area .coupon-accordion h3 {
  background-color: #000000;
  color: #c7c7c7;
}

.baroque-dark .checkout-area .coupon-accordion .coupon-content {
  border-color: #c7c7c7;
}

.baroque-dark .checkout-area .coupon-accordion .coupon-content .coupon-info p.form-row-first input {
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .checkout-area .coupon-accordion .coupon-content .coupon-info p.form-row-last input {
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .checkout-area .coupon-accordion .coupon-checkout-content .coupon-info .checkout-coupon input {
  border-color: #c7c7c7;
}

.baroque-dark .checkout-area .coupon-accordion .coupon-checkout-content .coupon-info .checkout-coupon input[type="text"] {
  color: #c7c7c7;
}

.baroque-dark .checkout-area .checkbox-form h3 {
  border-color: #c7c7c7;
  color: #ffffff;
}

.baroque-dark .checkout-area .checkbox-form .checkout-form-list input {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
}

.baroque-dark .checkout-area .checkbox-form .checkout-form-list input[type="text"],
.baroque-dark .checkout-area .checkbox-form .checkout-form-list input[type="password"],
.baroque-dark .checkout-area .checkbox-form .checkout-form-list input[type="email"] {
  color: #c7c7c7;
}

.baroque-dark .checkout-area .order-notes textarea {
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .checkout-area .your-order {
  background-color: #1b1b1b;
}

.baroque-dark .checkout-area .your-order h3 {
  border-color: #c7c7c7;
  color: #ffffff;
}

.baroque-dark .checkout-area .your-order .your-order-table table thead {
  background-color: #1b1b1b;
  color: #c7c7c7;
}

.baroque-dark .checkout-area .your-order .your-order-table table td, .baroque-dark .checkout-area .your-order .your-order-table table th {
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .checkout-area .your-order .payment-accordion #accordion .card {
  background-color: #1b1b1b;
}

.baroque-dark .checkout-area .your-order .payment-accordion #accordion .card .card-header {
  background-color: #1b1b1b;
}

.baroque-dark .baroque-cart-area .table-content table {
  color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .table-content table thead th {
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .table-content table th, .baroque-dark .baroque-cart-area .table-content table td {
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .coupon-all .coupon input {
  border-color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .coupon-all .coupon input.input-text {
  color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .cart-page-total > ul {
  border-color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .cart-page-total > ul li {
  border-color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .cart-page-total a {
  border-color: #c7c7c7;
}

.baroque-dark .baroque-cart-area .cart-page-total a:hover {
  border-color: #666600;
}

.baroque-dark .my-account_area .my-account_tab ul.tab-menu li a.active {
  color: #ffffff;
}

.baroque-dark .my-account_area .login-form .form-field input.input-field {
  background-color: #1b1b1b;
  border-color: #c7c7c7;
  color: #c7c7c7;
}

.baroque-dark .demo-switcher_area ul li a.demo-switcher_btn {
  background-color: rgba(255, 255, 255, 0.8);
  color: #1b1b1b;
}

.baroque-dark .demo-switcher_area ul li a.demo-switcher_btn:hover {
  background-color: #fff;
}

.baroque-dark .scroll-to-top-2 i {
  color: #ffffff;
}

/*----------------------------------------*/
/*  35 - Section - Header
/*----------------------------------------*/
/* ---Main Header--- */
.main-header .main-nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-header .main-nav > ul > li:not(:last-child) {
  padding-right: 30px;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown {
  background-color: #ffffff;
  top: calc(100% + 50px);
  position: absolute;
  padding: 30px;
  width: 230px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li:not(:last-child) {
  padding-bottom: 10px;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li a {
  display: block;
  font-size: 14px;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li a i {
  font-size: 11px;
  margin-right: 5px;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li a span.submenu-indicate {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 11px;
  margin-right: 0;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li.submenu-holder {
  position: relative;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li.submenu-holder ul.baroque-submenu {
  background-color: #ffffff;
  width: 230px;
  padding: 10px 20px;
  top: 0;
  left: auto;
  right: calc(100% + 40px);
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li.submenu-holder ul.baroque-submenu > li > a {
  display: block;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li.submenu-holder ul.baroque-submenu > li > a i {
  font-size: 11px;
  vertical-align: middle;
  margin-right: 5px;
}

.main-header .main-nav > ul > li > ul.baroque-dropdown li.submenu-holder:hover > ul.baroque-submenu {
  right: 100%;
  opacity: 1;
  visibility: visible;
}

.main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap {
  background-color: #ffffff;
  position: absolute;
  padding: 30px;
  width: 345px;
  top: calc(100% + 30px);
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap > ul.ba-megamenu {
  display: flex;
  justify-content: space-between;
}

.main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap > ul.ba-megamenu > li {
  width: 50%;
}

.main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap > ul.ba-megamenu > li span.title {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: #000000;
  padding-bottom: 20px;
  display: block;
}

.main-header .main-nav > ul > li.megamenu-holder .ba-megamenu_wrap > ul.ba-megamenu > li a {
  font-size: 14px;
  line-height: 14px;
}

.main-header .main-nav > ul > li.megamenu-holder:hover .ba-megamenu_wrap {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.main-header .main-nav > ul > li > a {
  display: block;
  padding: 45px 0;
  color: inherit;
}

.main-header .main-nav > ul > li:hover ul.baroque-dropdown {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

/* ---Transparent Header--- */
@media (min-width: 768px) {
  .transparent-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
}

.vs-transparent_header,
.px-transparent_header,
.zoom-transparent_header,
.pp-transparent_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

/* ---Fixed Header--- */
@media (min-width: 992px) {
  .fixed-header {
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
  }
}

/* ---Header Left Area--- */
.header-left_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left_area .header-intro {
  max-width: 52%;
}

@media (min-width: 1501px) {
  .header-left_area .header-intro {
    max-width: 48%;
  }
}

.header-left_area .header-intro span {
  color: #000000;
}

/* ---Header Right Area--- */
.header-right_area ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-right_area ul li:not(:last-child) {
  padding-right: 30px;
}

.header-right_area ul li a {
  padding-left: 10px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #000000;
}

.header-right_area ul li a span {
  font-size: 16px;
  line-height: 1.6;
  padding-right: 10px;
}

.header-right_area ul li a span i {
  font-size: 24px;
}

.header-right_area ul li.minicart-wrap {
  position: relative;
  padding-right: 40px;
}

.header-right_area ul li.minicart-wrap span.cart-counter {
  color: #ffffff;
  border-radius: 100%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  line-height: 19px;
  text-align: center;
  position: absolute;
  bottom: -5px;
  right: 20px;
}

.header-right_area ul li.mobile-menu_wrap a.mobile-menu_btn i {
  font-size: 32px;
  vertical-align: middle;
}

/* ---Sticky Header--- */
.header-sticky {
  -webkit-transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -ms-transition: all 300ms ease-in 0s;
  -o-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
}

.header-sticky.main-header {
  display: none;
}

@media (min-width: 576px) {
  .header-sticky.sticky {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
  }
}

/* ---Main Header Area Two--- */
@media (max-width: 991px) {
  .main-header_area-2 .main-header {
    padding: 30px 0;
  }
}

.main-header_area-2 .main-header .main-menu {
  display: flex;
  justify-content: space-between;
}

/* ---Main Header Area Three--- */
.main-header_area-3 .main-header {
  padding-top: 100px;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .main-header_area-3 .main-header {
    padding-top: 30px;
  }
}

.main-header_area-3 .main-header .header-logo {
  text-align: center;
}

@media (max-width: 767px) {
  .main-header_area-3 .main-header .header-logo {
    text-align: left;
  }
}

/* ---Main Header Area Four--- */
@media (max-width: 991px) {
  .main-header_area-4 .main-header {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.main-header_area-4 .main-header .main-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header_area-4 .main-header .main-menu .header-info a.email-address {
  text-transform: none;
  color: #ffffff;
}

.main-header_area-4 .main-header .main-menu .header-info a.email-address:hover {
  color: #ffffff;
}

/* ---Main Header Area Five--- */
.main-header_area-5 {
  position: relative;
  z-index: 3;
}

.main-header_area-5 .main-header .main-header_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ---Main Header Area Six--- */
@media (max-width: 991px) {
  .main-header_area-6 .main-header {
    padding-top: 30px;
  }
}

.main-header_area-6 .main-header .main-menu {
  display: flex;
  justify-content: space-between;
}

/* ---Main Header Area Seven--- */
.main-header_area-7 .main-header.header-sticky {
  display: block;
}

.main-header_area-7 .main-header .main-header_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .main-header_area-7 .main-header .main-header_nav {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.main-header_area-7 .main-header .main-header_nav .main-menu {
  display: flex;
  justify-content: space-between;
}

.main-header_area-7 .main-header .main-header_nav .main-menu ul.onepage-nav li a {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.main-header_area-7 .main-header .main-header_nav .main-menu ul.onepage-nav li.active a {
  color: #666600;
}

.main-header_area-7 .main-header .main-header_nav .main-menu .language-menu {
  padding: 43px 0 0 35px;
}

@media (max-width: 991px) {
  .main-header_area-7 .main-header .main-header_nav .main-menu .language-menu {
    padding: 0;
  }
}

.main-header_area-7 .main-header .main-header_nav .main-menu .language-menu ul li {
  display: inline-block;
}

.main-header_area-7 .main-header .main-header_nav .main-menu .language-menu ul li:not(:last-child) {
  padding-right: 10px;
}

.main-header_area-7 .main-header .main-header_nav .main-menu .language-menu ul li a {
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

/* ---Main header Area Eight--- */
.main-header_area-8 .main-header {
  padding: 40px 0;
}

.main-header_area-8 .main-header .main-header_nav {
  display: flex;
  justify-content: space-between;
}

/*----------------------------------------*/
/*  36 - Section - Footer
/*----------------------------------------*/
@media (min-width: 1200px) {
  .footer-sticky {
    position: static;
  }
}

@media (min-width: 1200px) {
  .footer-sticky.sticky {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 0;
    width: calc(100% - 40px);
    z-index: -1;
  }
}

@media (max-width: 1199px) {
  .footer-widgets_area {
    padding-bottom: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widgets_area .copyright {
    max-width: 50%;
  }
}

.footer-widgets_area .footer-widget li {
  display: flex;
  font-weight: 600;
}

.footer-widgets_area .footer-widget li:not(:last-child) {
  padding-bottom: 5px;
}

.footer-widgets_area .footer-widget li span.desc {
  color: #000000;
  padding-left: 25px;
}

.footer-widgets_area .footer-widget li a {
  color: #000000;
}

.footer-widgets_area .footer-widget li.language-menu {
  display: inline-block;
  position: relative;
}

.footer-widgets_area .footer-widget li.language-menu a {
  text-transform: uppercase;
}

.footer-widgets_area .footer-widget li.language-menu a i {
  vertical-align: text-top;
  font-size: 16px;
  padding-left: 5px;
}

.footer-widgets_area .footer-widget li.language-menu ul.language-dropdown {
  background-color: #f2f2f2;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: calc(100% + 25px);
  left: 0;
  z-index: 1;
}

.footer-widgets_area .footer-widget li.language-menu:hover ul.language-dropdown {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.footer-widgets_area .footer-widget.social-link {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .footer-widgets_area .footer-widget.social-link {
    justify-content: flex-start;
    padding-top: 40px;
  }
}

.footer-widgets_area .footer-widget.social-link li {
  padding-bottom: 0;
}

/* ---Transparent Footer--- */
.transparent-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

/* ---Footer Area Two--- */
.footer-area-2 .footer-widgets_area.with-border {
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 55px;
}

@media (max-width: 1199px) {
  .footer-area-2 .footer-widgets_area.with-border {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1199px) {
  .footer-area-2 .footer-widgets_area .copyright {
    padding-bottom: 10px;
  }
}

.footer-area-2 .footer-widgets_area .copyright span {
  text-transform: none;
  font-weight: 500;
  font-size: 15px;
}

.footer-area-2 .footer-widgets_area .copyright span a em {
  color: #666666;
  font-style: normal;
}

@media (max-width: 1199px) {
  .footer-area-2 .footer-widgets_area .contact-info {
    padding-bottom: 15px;
  }
}

.footer-area-2 .footer-widgets_area .contact-info ul {
  display: flex;
}

@media (max-width: 767px) {
  .footer-area-2 .footer-widgets_area .contact-info ul {
    flex-direction: column;
    align-items: center;
  }
}

.footer-area-2 .footer-widgets_area .contact-info ul li {
  position: relative;
}

.footer-area-2 .footer-widgets_area .contact-info ul li:not(:last-child) {
  padding-right: 20px;
}

@media (min-width: 768px) {
  .footer-area-2 .footer-widgets_area .contact-info ul li:not(:last-child):before {
    content: '/';
    position: absolute;
    top: 0;
    right: 5px;
  }
}

.footer-area-2 .footer-widgets_area .contact-info ul li a,
.footer-area-2 .footer-widgets_area .contact-info ul li span {
  text-transform: none;
  font-weight: 500;
  font-size: 15px;
}

@media (max-width: 479px) {
  .footer-area-2 .footer-widgets_area .contact-info ul li a,
  .footer-area-2 .footer-widgets_area .contact-info ul li span {
    font-size: 14px;
  }
}

/* ---Footer Area Three--- */
.footer-area-3 .footer-widgets_area {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .footer-area-3 .footer-widgets_area {
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
  }
  .footer-area-3 .footer-widgets_area .copyright {
    padding-bottom: 25px;
  }
  .footer-area-3 .footer-widgets_area .header-logo {
    padding-bottom: 25px;
  }
}

/* ---Footer Area Four--- */
.footer-area-4 .footer-widgets_area {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .footer-area-4 .footer-widgets_area {
    flex-direction: column;
    align-items: center;
  }
  .footer-area-4 .footer-widgets_area .copyright {
    padding-bottom: 20px;
  }
}

/* ---Footer Area Five--- */
.footer-area-5 .footer-widgets_area {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
}

@media (max-width: 575px) {
  .footer-area-5 .footer-widgets_area {
    flex-direction: column;
    align-items: center;
  }
}

.footer-area-5 .footer-widgets_area .logo {
  padding-bottom: 20px;
}

/* ---Footer Area Six--- */
.footer-area-6 .footer-widgets_area {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 479px) {
  .footer-area-6 .footer-widgets_area {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 479px) {
  .footer-area-6 .footer-widgets_area .copyright {
    padding-bottom: 15px;
  }
}

@media (max-width: 479px) {
  .footer-area-6 .footer-widgets_area .social-link h5.title {
    font-size: 18px;
  }
}

/*----------------------------------------*/
/*  37 - Pages - Coming Soon
/*----------------------------------------*/
.coming-soon_area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.coming-soon_area.coming-soon-bg {
  background-image: url("../images/coming-soon/bg/1.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 100vh;
}

.coming-soon_area .inner-content {
  padding-top: 100px;
  overflow-y: auto;
  width: 100%;
}

.coming-soon_area .inner-content .logo {
  padding-bottom: 40px;
}

.coming-soon_area .inner-content h1.heading {
  font-size: 60px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .coming-soon_area .inner-content h1.heading {
    font-size: 35px;
    line-height: 1.2;
  }
}

.coming-soon_area .inner-content p.short-desc {
  color: #ffffff;
  max-width: 50%;
  margin: 0 auto;
  padding-bottom: 90px;
  letter-spacing: -0.2px;
}

@media (min-width: 1501px) {
  .coming-soon_area .inner-content p.short-desc {
    max-width: 36%;
  }
}

@media (max-width: 767px) {
  .coming-soon_area .inner-content p.short-desc {
    max-width: 95%;
  }
}

.coming-soon_area .inner-content h2.sub-heading {
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 35px;
  color: #ffffff;
}

.coming-soon_area .inner-content .social-link {
  padding-top: 40px;
  padding-bottom: 95px;
}

.coming-soon_area .inner-content .social-link li a {
  color: #ffffff;
}

.coming-soon_area .inner-content .social-link li a:hover {
  color: #666600;
}

/*----------------------------------------*/
/*  38 - Pages - Error 404
/*----------------------------------------*/
.error-404_area {
  text-align: center;
}

.error-404_area .error-img {
  padding-bottom: 25px;
}

.error-404_area h1.heading {
  font-size: 24px;
  line-height: 28.8px;
  padding-bottom: 20px;
}

.error-404_area p.short-desc {
  font-size: 18px;
  line-height: 28.8px;
  padding-bottom: 25px;
}

.error-404_area p.short-desc a {
  text-decoration: underline;
  color: #666600;
}

.error-404_area .error-search_box {
  position: relative;
  display: inline-block;
}

.error-404_area .error-search_box input.input-field {
  padding: 0 20px;
  width: 370px;
  height: 50px;
}

.error-404_area .error-search_box .search-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

/*----------------------------------------*/
/*  39 - Pages - Contact
/*----------------------------------------*/
/* ---Google Map--- */
#google-map {
  height: 880px;
}

@media (max-width: 767px) {
  #google-map {
    height: 350px;
  }
}

/* ---Contact Information--- */
.contact-info h1.heading {
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 15px;
}

@media (max-width: 479px) {
  .contact-info h1.heading {
    font-size: 25px;
    line-height: 1.2;
  }
}

.contact-info h1.heading span {
  color: #999999;
}

.contact-info p.short-desc {
  max-width: 50%;
  padding-bottom: 45px;
}

.contact-info span {
  text-transform: uppercase;
}

.contact-info span.ba-email {
  color: #000000;
  padding-bottom: 45px;
  display: block;
}

.contact-info span.ba-email a {
  text-transform: lowercase;
}

.contact-info span.ba-number {
  display: block;
}

.contact-info span.ba-number a {
  font-size: 36px;
  line-height: 57px;
  font-weight: 600;
  color: #000000;
  padding-top: 5px;
  display: block;
}

@media (max-width: 479px) {
  .contact-info span.ba-number a {
    font-size: 30px;
    line-height: 1.2;
  }
}

.contact-info .ba-brand {
  padding-top: 45px;
}

.contact-info .ba-brand span {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  padding-bottom: 10px;
  display: block;
}

.contact-info .ba-brand li {
  display: inline-block;
}

.contact-info .ba-brand li:not(:last-child) a:before {
  background-color: #666666;
  content: "";
  position: absolute;
  top: 3px;
  right: -6px;
  width: 1px;
  height: 14px;
}

.contact-info .ba-brand li a {
  position: relative;
  padding-right: 3px;
  margin-right: 8px;
}

.contact-info .ba-brand li a:after {
  background-color: #666600;
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.contact-info .ba-brand li a:hover:after {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------*/
/*  40 - Pages - Shop
/*----------------------------------------*/
/* ---Shorting Menu--- */
.shorting-menu {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .shorting-menu ul {
    width: 100%;
  }
}

.shorting-menu ul li {
  position: relative;
}

.shorting-menu ul li > ul {
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  position: absolute;
  padding: 30px;
  width: 340px;
  top: 100%;
  left: auto;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

@media (max-width: 767px) {
  .shorting-menu ul li > ul {
    width: 100%;
    left: 0;
    right: auto;
  }
}

.shorting-menu ul li > ul li a {
  color: #999999;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 600;
}

.shorting-menu ul li > ul li a.active {
  color: #000000;
}

.shorting-menu ul li > ul li a:hover {
  color: #000000;
}

.shorting-menu ul li > ul li:not(:last-child) a {
  padding-bottom: 10px;
}

.shorting-menu ul li:hover ul {
  opacity: 1;
  visibility: visible;
}

.shorting-menu ul li a {
  font-size: 18px;
  line-height: 28.8px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
}

@media (max-width: 767px) {
  .shorting-menu ul li a {
    width: 100%;
  }
}

.shorting-menu ul li a i {
  color: #000000;
}

/* ---Product Wrap--- */
.product-wrap {
  margin-left: -10px;
  margin-right: -10px;
}

/* ---Product Item--- */
.product-item {
  padding-bottom: 55px;
}

.product-item .product-img {
  position: relative;
  overflow: hidden;
}

.product-item .product-img img.secondary-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.product-item .product-img .add-action {
  background-color: #ffffff;
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: center;
  transform: translateX(70px);
}

.product-item .product-img .add-action a i {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  transform: scale(0);
}

.product-item .product-content {
  padding-top: 20px;
}

.product-item .product-content h3.product-name {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
  padding-bottom: 5px;
}

.product-item .product-content h3.product-name a {
  color: #000000;
  position: relative;
}

.product-item .product-content h3.product-name a:before {
  background-color: #000000;
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.product-item:hover .product-img img.secondary-img {
  opacity: 1;
  visibility: visible;
}

.product-item:hover .product-img .add-action {
  transform: translateX(0);
}

.product-item:hover .product-img .add-action a i {
  transform: scale(1);
}

.product-item:hover .product-content h3.product-name a:before {
  opacity: 1;
  visibility: visible;
}

/* ---Shop Area--- */
.shop-area .banner-content {
  position: relative;
  margin-top: -220px;
}

@media (max-width: 991px) {
  .shop-area .banner-content {
    margin-top: 0;
  }
}

.shop-area .banner-content .inner-content {
  background-color: #ffffff;
  padding: 60px;
  width: 720px;
}

@media (max-width: 991px) {
  .shop-area .banner-content .inner-content {
    padding: 50px 0 0;
    width: 100%;
  }
}

.shop-area .banner-content .inner-content h1.heading {
  font-size: 48px;
  line-height: 57.6px;
  padding-bottom: 15px;
  letter-spacing: -1px;
}

@media (max-width: 767px) {
  .shop-area .banner-content .inner-content h1.heading {
    font-size: 30px;
    line-height: 1.2;
  }
}

.shop-area .banner-content .inner-content p.short-desc {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.2px;
}

@media (max-width: 767px) {
  .shop-area .banner-content .inner-content p.short-desc {
    font-size: 15px;
    line-height: 1.2;
  }
}

.shop-area .tab-section_area {
  padding-top: 95px;
}

@media (max-width: 991px) {
  .shop-area .tab-section_area {
    padding-top: 85px;
  }
}

@media (max-width: 767px) {
  .shop-area .tab-section_area .product-tab ul.product-menu li a {
    font-size: 15px;
    line-height: 1.4;
  }
}

.shop-area.with-sidebar .shop-toolbar {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .shop-area.with-sidebar .shop-toolbar {
    display: block;
  }
}

@media (max-width: 767px) {
  .shop-area.with-sidebar .shop-toolbar .product-found {
    padding-bottom: 15px;
  }
}

/*----------------------------------------*/
/*  41 - Pages - Product Detail
/*----------------------------------------*/
.breadcrumbs-with_pagination {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.breadcrumbs-with_pagination .pagination ul {
  display: flex;
}

.breadcrumbs-with_pagination .pagination ul li:first-child {
  padding-right: 10px;
}

/* ---Product Detial Image--- */
.product-detail_img {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.product-detail_img .product-detail_slider {
  flex-basis: 85%;
  max-width: 85%;
  margin-left: 30px;
  cursor: crosshair;
}

.product-detail_img .pd-slider-nav {
  flex-basis: 15%;
  max-width: 15%;
  height: 100%;
}

.product-detail_img .pd-slider-nav .swiper-wrapper {
  height: 100%;
}

.product-detail_img .pd-slider-nav .swiper-wrapper .swiper-slide {
  height: 100% !important;
  opacity: 0.5;
}

.product-detail_img .pd-slider-nav .swiper-wrapper .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.product-detail_img.vt-right_side {
  flex-direction: unset;
}

/* ---Product Detail Content--- */
@media (max-width: 991px) {
  .product-detail_content {
    padding-top: 45px;
  }
}

.product-detail_content h1.heading {
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  letter-spacing: -1px;
  padding-bottom: 5px;
}

.product-detail_content .product-review-area {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.product-detail_content p.short-desc {
  padding-bottom: 15px;
}

.product-detail_content .price-box span.new-price {
  font-size: 22px;
  line-height: 35.2px;
}

.product-detail_content .product-color {
  padding-top: 20px;
  display: flex;
}

.product-detail_content .quantity-with_btn {
  padding-top: 30px;
  display: flex;
}

.product-detail_content .quantity-with_btn .btn-wrap {
  padding-left: 20px;
}

.product-detail_content .social-link {
  padding-top: 25px;
}

/* ---Product Detail Tab--- */
.product-detail_tab .horizontal-tab ul {
  display: flex;
  justify-content: center;
  padding-bottom: 35px;
}

.product-detail_tab .horizontal-tab ul.tab-menu li:not(:last-child) {
  padding-right: 25px;
}

.product-detail_tab .horizontal-tab ul.tab-menu li a {
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  color: #999999;
}

.product-detail_tab .horizontal-tab ul.tab-menu li a.active {
  color: #666600;
  font-weight: 600;
}

.tab-content .description-body {
  padding-bottom: 45px;
  text-align: center;
}

.tab-content .information-body {
  padding-bottom: 30px;
}

.tab-content .review-body > h2.heading {
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 400;
  padding-bottom: 25px;
}

.tab-content .review-body ul.user-info {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 30px;
}

@media (max-width: 575px) {
  .tab-content .review-body ul.user-info {
    flex-direction: column;
  }
}

.tab-content .review-body ul.user-info li.user-avatar img {
  border-radius: 100%;
}

.tab-content .review-body ul.user-info li.user-comment {
  padding-left: 30px;
}

@media (max-width: 575px) {
  .tab-content .review-body ul.user-info li.user-comment {
    padding-top: 20px;
    padding-left: 0;
  }
}

.tab-content .review-body ul.user-info li.user-comment .meta strong {
  font-weight: 400;
}

.tab-content .review-body .user-feedback h3.heading {
  font-size: 30px;
  line-height: 48px;
  font-weight: 400;
}

.tab-content .review-body .user-feedback p.short-desc {
  padding-bottom: 15px;
}

.tab-content .review-body .user-feedback form.pd-feedback_form {
  padding-top: 15px;
  padding-bottom: 50px;
}

.tab-content .review-body .user-feedback form.pd-feedback_form .form-field {
  padding-bottom: 20px;
}

.tab-content .review-body .user-feedback form.pd-feedback_form .form-field label.label-field {
  padding-bottom: 10px;
}

.tab-content .review-body .user-feedback form.pd-feedback_form .form-field textarea.textarea-field {
  height: 220px;
  width: 100%;
  padding: 25px;
}

.tab-content .review-body .user-feedback form.pd-feedback_form .form-field input.input-field {
  width: 100%;
  height: 50px;
  padding: 0 15px;
}

.tab-content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .tab-content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field {
    flex-direction: column;
  }
}

.tab-content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field .group-input {
  width: 100%;
}

@media (max-width: 767px) {
  .tab-content .review-body .user-feedback form.pd-feedback_form .form-field.form-group_field .group-input[class*="group-input"]:not(:last-child) {
    padding-bottom: 20px;
  }
}

.tab-content .review-body .user-feedback form.pd-feedback_form .form-btn_wrap {
  padding-top: 30px;
}

/* ---Product Detail Meta--- */
.product-detail_meta .pd-meta_nav {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

@media (max-width: 479px) {
  .product-detail_meta .pd-meta_nav {
    flex-direction: column;
  }
}

.product-detail_meta .pd-meta_nav .sku-wrapper {
  padding-right: 30px;
}

.product-detail_meta .pd-meta_nav .sku-wrapper span strong {
  color: #000000;
  font-weight: 400;
}

.product-detail_meta .pd-meta_nav .posted-in span strong {
  color: #000000;
  font-weight: 400;
}

.product-detail_meta .pd-meta_nav .posted-in span a {
  text-transform: lowercase;
}

/* ---Related Product Area--- */
.related-ptoduct_area .product-item {
  padding-bottom: 0;
}

/*----------------------------------------*/
/*  42 - Pages - Checkout
/*----------------------------------------*/
.coupon-accordion h3 {
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: 400;
  list-style: outside none none;
  margin: 0 0 30px;
  padding: 16px 32px 16px 56px;
  position: relative;
  width: auto;
  text-transform: none;
}

@media (max-width: 479px) {
  .coupon-accordion h3 {
    line-height: 24px;
  }
}

.coupon-accordion h3:before {
  content: "\76";
  display: inline-block;
  font-family: 'ElegantIcons';
  left: 24px;
  position: absolute;
  top: 16px;
}

.coupon-accordion span {
  cursor: pointer;
  text-transform: none;
}

.coupon-accordion .coupon-content {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  display: none;
  padding: 20px;
}

.coupon-accordion .coupon-content .coupon-info p {
  margin-bottom: 0;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label, .coupon-accordion .coupon-content .coupon-info p.form-row-last label {
  display: block;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first label span.required, .coupon-accordion .coupon-content .coupon-info p.form-row-last label span.required {
  color: #666600;
}

.coupon-accordion .coupon-content .coupon-info p.form-row-first input, .coupon-accordion .coupon-content .coupon-info p.form-row-last input {
  border: 1px solid #e5e5e5;
  height: 36px;
  margin: 0 0 14px;
  border-radius: 0;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 370px;
  background-color: transparent;
}

.coupon-accordion .coupon-content .coupon-info p.form-row {
  margin-left: 0;
  margin-right: 0;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="submit"] {
  background: #666666 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 30px;
  text-shadow: none;
  text-transform: uppercase;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.form-row input[type="checkbox"] {
  height: inherit;
  position: relative;
  top: 0;
  width: inherit;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password {
  margin-top: 15px;
}

.coupon-accordion .coupon-content .coupon-info p.lost-password a {
  font-size: 14px;
}

.coupon-checkout-content {
  margin-bottom: 30px;
  display: none;
}

.coupon-checkout-content .coupon-info .checkout-coupon {
  margin-bottom: 0;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="text"] {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 36px;
  padding-left: 10px;
  width: 170px;
}

.coupon-checkout-content .coupon-info .checkout-coupon input[type="submit"] {
  background: #666666;
  border: medium none;
  border-radius: 0;
  color: #ffffff;
  height: 36px;
  cursor: pointer;
  margin-left: 6px;
  padding: 5px 10px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: inherit;
}

@media (max-width: 479px) {
  .coupon-checkout-content .coupon-info .checkout-coupon .coupon-inner_btn {
    margin: 28px 0 0 !important;
  }
}

.coupon-checkout-content .coupon-info .checkout-coupon .coupon-inner_btn:hover {
  background-color: #666600;
}

.checkbox-form h3 {
  border-bottom: 1px solid #e5e5e5;
  font-size: 25px;
  margin: 0 0 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .checkbox-form h3 {
    font-size: 18px;
  }
}

.checkbox-form .country-select {
  margin-bottom: 25px;
}

.checkbox-form .checkout-form-list {
  margin-bottom: 30px;
}

.checkbox-form .checkout-form-list input[type="text"],
.checkbox-form .checkout-form-list input[type="password"],
.checkbox-form .checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  width: 100%;
  padding: 0 0 0 10px;
}

.checkbox-form .checkout-form-list.create-acc {
  margin-bottom: 15px !important;
}

.checkbox-form .checkout-form-list.create-acc label {
  display: inline-block;
}

.checkbox-form .country-select label, .checkbox-form.checkout-form-list label {
  margin: 0 0 5px;
  display: block;
}

.checkbox-form .country-select label span.required, .checkbox-form.checkout-form-list label span.required {
  display: inline-block;
  font-size: 24px;
  line-height: 16px;
  position: relative;
  top: 5px;
}

.different-address h3 label {
  display: inline-block;
  margin-right: 20px;
  font-size: 25px;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .different-address h3 label {
    font-size: 18px;
  }
}

@media (max-width: 479px) {
  .different-address h3 label {
    font-size: 14px;
  }
}

.different-address h3 input {
  height: inherit;
  line-height: normal;
  margin: 4px 0 0;
  position: relative;
  top: 0;
  width: auto;
}

.order-notes .checkout-form-list-2 {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .order-notes .checkout-form-list-2 {
    margin-bottom: 30px;
  }
}

.order-notes textarea {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  height: 90px;
  padding: 15px;
  width: 100%;
}

.create-account,
#ship-box-info {
  display: none;
}

.your-order {
  background: #f2f2f2;
  padding: 30px 40px 30px;
}

@media (max-width: 479px) {
  .your-order {
    padding: 25px;
  }
}

.your-order h3 {
  border-bottom: 1px solid #e5e5e5;
  font-size: 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 479px) {
  .your-order h3 {
    font-size: 18px;
  }
}

.your-order .your-order-table table {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  width: 100%;
}

.your-order .your-order-table table thead {
  background-color: #f2f2f2;
}

.your-order .your-order-table table thead tr th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 250px;
}

.your-order .your-order-table table thead tr th,
.your-order .your-order-table table thead tr td {
  border-bottom: 1px solid #e5e5e5;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: center;
}

.your-order .your-order-table table .cart_item:hover {
  background: #f2f2f2;
}

.your-order .your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order .your-order-table table .order-total td span {
  font-size: 20px;
}

.your-order .your-order-table table .order-total th {
  border-bottom: medium none;
  font-size: 18px;
}

.payment-method {
  margin-top: 20px;
}

.payment-method .payment-accordion .card {
  border: 0;
  border-radius: 0;
  background: #f2f2f2;
}

.payment-method .payment-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f2f2f2;
  border-bottom: 0;
}

.payment-method .payment-accordion .card-body {
  padding: 0;
}

.payment-method .payment-accordion .panel.panel-default {
  margin-bottom: 20px;
}

.payment-method .payment-accordion .panel-title {
  font-size: 18px;
  cursor: pointer;
}

.order-button-payment input {
  background: #666666;
  border: medium none;
  color: #ffffff;
  font-size: 17px;
  height: 50px;
  margin: 20px 0 0;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
  border: 1px solid transparent;
  cursor: pointer;
}

/*----------------------------------------*/
/*  43 - Pages - Cart
/*----------------------------------------*/
@media (max-width: 767px) {
  .table-content table {
    text-align: center;
  }
}

.table-content table thead th {
  border-bottom: 1px solid #dee2e6;
}

@media (max-width: 767px) {
  .table-content table thead tr {
    display: none;
  }
}

.table-content table td.product-subtotal {
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .table-content table td {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .table-content table td:nth-child(3) {
    padding: 32.1px 10px;
    display: block;
    width: 100%;
  }
}

.table-content table th,
.table-content table td {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .baroque-cart-area .table-content table td:nth-child(3) {
    padding: 45.1px 10px;
  }
}

.coupon-all {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 479px) {
  .coupon-all {
    display: block;
  }
}

@media (max-width: 479px) {
  .coupon-all .coupon {
    margin-bottom: 30px;
  }
}

.coupon-all .coupon input {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  font-size: 13px;
  border-radius: 0;
  height: 42px;
  width: 120px;
  padding: 0 10px;
}

.coupon-all .coupon input.input-btn,
.coupon-all .coupon2 input.input-btn {
  background-color: #666666;
  border: 0 none;
  border-radius: 2px;
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 25px;
  text-transform: uppercase;
  width: inherit;
}

@media (max-width: 479px) {
  .coupon-all .coupon input.input-btn,
  .coupon-all .coupon2 input.input-btn {
    padding: 0 19.5px;
  }
}

.coupon-all .coupon input.input-btn:hover,
.coupon-all .coupon2 input.input-btn:hover {
  background-color: #666600;
}

.cart-page-total {
  padding-top: 30px;
}

.cart-page-total > h2 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #e5e5e5;
}

.cart-page-total > ul li {
  list-style: none;
  font-size: 15px;
  padding: 10px 30px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
}

.cart-page-total > ul li:last-child {
  border-bottom: 0;
}

.cart-page-total > ul li span {
  float: right;
}

.cart-page-total a {
  background-color: #666666;
  border: 1px solid #e5e5e5;
  color: #ffffff;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  text-transform: capitalize;
}

.cart-page-total a:hover {
  background-color: #666600;
  color: #ffffff;
}

/*----------------------------------------*/
/*  27 - Components - My Account
/*----------------------------------------*/
.my-account_tab {
  padding-bottom: 30px;
}

.my-account_tab ul.tab-menu li:not(:last-child) {
  padding-right: 25px;
}

.my-account_tab ul.tab-menu li a {
  font-size: 48px;
  line-height: 76px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .my-account_tab ul.tab-menu li a {
    font-size: 30px;
    line-height: 1.2;
  }
}

.my-account_tab ul.tab-menu li a.active {
  color: #000000;
}

.login-form .form-field {
  padding-bottom: 25px;
}

.login-form .form-field input.input-field {
  width: 100%;
  height: 50px;
  padding: 0 20px;
}

.login-form .form-field.password-field {
  position: relative;
}

.login-form .form-field.password-field input.input-field {
  padding: 0 95px 0 20px;
}

.login-form .form-field.password-field a.pass-recovery {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-weight: 600;
}

.login-form .check-box {
  padding-top: 40px;
}

.login-form .check-box input.input-checkbox {
  vertical-align: middle;
}

.login-form .check-box label.input-label {
  cursor: pointer;
}

.login-form .form-btn_wrap {
  padding-top: 25px;
}
