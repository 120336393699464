/*----------------------------------------*/
/*  40 - Pages - Shop
/*----------------------------------------*/
/* ---Shorting Menu--- */
.shorting-menu{
    display: flex;
    justify-content: flex-end;
    ul{
        @include breakpoint(max-sm_device){
            width: 100%;
        }
        li{
            position: relative;
            > ul{
                border: 1px solid $border-color;
                background-color: $white-color;
                position: absolute;
                padding: 30px;
                width: 340px;
                top: 100%;
                left: auto;
                right: 0;
                opacity: 0;
                visibility: hidden;
                @extend %basetransition;
                z-index: 2;
                @include breakpoint(max-sm_device){
                    width: 100%;
                    left: 0;
                    right: auto;
                }
                li{
                    a{
                        color: $nobel-color;
                        @include typography(16px, 1.6, 600);
                        &.active{
                            color: $secondary-color;
                        }
                        &:hover{
                            color: $secondary-color;
                        }
                    }
                    &:not(:last-child){
                        a{
                            padding-bottom: 10px;
                        }
                    }
                }
            }
            &:hover{
                ul{
                    opacity: 1;
                    visibility: visible;
                }
            }
            a{
                @include typography(18px, 28.8px, 600);
                text-transform: uppercase;
                color: $secondary-color;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 320px;
                @include breakpoint(max-sm_device){
                    width: 100%;
                }
                i{
                    color: $secondary-color;
                }
            }
        }
    }
}

/* ---Product Wrap--- */
.product-wrap{
    margin-left: -10px;
    margin-right: -10px;
}

/* ---Product Item--- */
.product-item{
    padding-bottom: 55px;
    .product-img{
        position: relative;
        overflow: hidden;
        img{
            &.secondary-img{
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                @extend %basetransition;
            }
        }
        .add-action{
            background-color: $white-color;
            position: absolute;
            bottom: 20px;
            right: 20px;
            text-align: center;
            transform: translateX(70px);
            @extend %basetransition;
            a{
                i{
                    display: block;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    transform: scale(0);
                    @extend %basetransition;
                }
            }
        }
    }
    .product-content{
        padding-top: 20px;
        h3{
            &.product-name{
                @include typography(16px, 19.2px, 400);
                padding-bottom: 5px;
                a{
                    color: $secondary-color;
                    position: relative;
                    &:before{
                        background-color: $secondary-color;
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        visibility: hidden;
                        @extend %basetransition;
                    }
                }
            }
        }
    }
    &:hover{
        .product-img{
            img{
                &.secondary-img{
                    opacity: 1;
                    visibility: visible;
                }
            }
            .add-action{
                transform: translateX(0);
                a{
                    i{
                        transform: scale(1);
                    }
                }
            }
        }
        .product-content{
            h3{
                &.product-name{
                    a{
                        &:before{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

/* ---Shop Area--- */
.shop-area{
    .banner-content{
        position: relative;
        margin-top: -220px;
        @include breakpoint(max-md_device){
            margin-top: 0;
        }
        .inner-content{
            background-color: $white-color;
            padding: 60px;
            width: 720px;
            @include breakpoint(max-md_device){
                padding: 50px 0 0;
                width: 100%;
            }
            h1{
                &.heading{
                    @include typography(48px, 57.6px);
                    padding-bottom: 15px;
                    letter-spacing: -1px;
                    @include breakpoint(max-sm_device){
                        font-size: 30px;
                        line-height: 1.2;
                    }
                }
            }
            p{
                &.short-desc{
                    @include typography(20px, 32px);
                    letter-spacing: -0.2px;
                    @include breakpoint(max-sm_device){
                        font-size: 15px;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
    .tab-section_area{
        padding-top: 95px;
        @include breakpoint(max-md_device){
            padding-top: 85px;
        }
        .product-tab{
            ul{
                &.product-menu{
                    li{
                        a{
                            @include breakpoint(max-sm_device){
                                font-size: 15px;
                                line-height: 1.4;
                            }
                        }
                    }
                }
            }
        }
    }
    &.with-sidebar{
        .shop-toolbar{
            display: flex;
            justify-content: space-between;
            @include breakpoint(max-sm_device){
                display: block;
            }
            .product-found{
                @include breakpoint(max-sm_device){
                    padding-bottom: 15px;
                }
            }
        }
    }
}