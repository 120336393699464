/*----------------------------------------*/
/*  14 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumbs{
	ul{
		display: flex;
		@include breakpoint(max-xs_device){
			flex-wrap: wrap;
		}
		li{
			&:not(:last-child){
				padding-right: 5px;
			}
			a,
			span{
				@include breakpoint(max-sm_device){
					font-size: 14px;
				}
			}
		}
	}
}