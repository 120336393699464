/*----------------------------------------*/
/*  43 - Pages - Cart
/*----------------------------------------*/
.table-content {
	table {
		@include breakpoint(max-sm_device){
			text-align: center;
		}
		thead{
			th{
				border-bottom: 1px solid #dee2e6;
			}
			tr{
				@include breakpoint(max-sm_device){
					display: none;
				}
			}
		}
		td {
			&.product-subtotal {
				font-size: 16px;
				font-weight: 700;
			}
			@include breakpoint (max-sm_device) {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			&:nth-child(3) {
				@include breakpoint (max-sm_device) {
					padding: 32.1px 10px;
					display: block;
					width: 100%;
				}
			}
		}
		th,
		td{
			vertical-align: middle;
		}
	}
}

.baroque-cart-area {
	.table-content {
		table {
			td {
				&:nth-child(3) {
					@include breakpoint (max-sm_device) {
						padding: 45.1px 10px;
					}
				}
			}
		}
	}
}

.coupon-all {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	@include breakpoint(max-xxs_device){
		display: block;
	}
	.coupon {
		@include breakpoint(max-xxs_device){
			margin-bottom: 30px;
		}
		input {
			background-color: transparent;
			border: 1px solid $border-color;
			font-size: 13px;
			border-radius: 0;
			height: 42px;
			width: 120px;
			padding: 0 10px;
		}
	}
	.coupon,
	.coupon2 {
		input {
			&.input-btn {
				background-color: $text-color;
				border: 0 none;
				border-radius: 2px;
				color: $white-color;
				display: inline-block;
				font-size: 13px;
				font-weight: 700;
				cursor: pointer;
				height: 42px;
				letter-spacing: 1px;
				line-height: 42px;
				padding: 0 25px;
				text-transform: uppercase;
				@extend %basetransition;
				width: inherit;
				@include breakpoint(max-xxs_device){
					padding: 0 19.5px;
				}
				&:hover{
					background-color: $primary-color;
				}
			}
		}
	}
}

.cart-page-total {
	padding-top: 30px;
	> h2 {
		font-size: 25px;
		font-weight: 700;
		margin-bottom: 20px;
		text-transform: capitalize;
	}
	> ul {
		border: 1px solid $border-color;
		li {
			list-style: none;
			font-size: 15px;
			padding: 10px 30px;
			border-bottom: 1px solid $border-color;
			font-weight: 700;
			&:last-child {
				border-bottom: 0;
			}
			span {
				float: right;
			}
		}
	}
	a {
		background-color: $text-color;
		border: 1px solid $border-color;
		color: $white-color;
		display: inline-block;
		margin-top: 30px;
		padding: 10px 20px;
		text-transform: capitalize;
		@extend %basetransition;
		&:hover{
			background-color: $primary-color;
			color: $white-color;
		}
	}
}