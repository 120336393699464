/*----------------------------------------*/
/*  37 - Pages - Coming Soon
/*----------------------------------------*/
.coming-soon_area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    &.coming-soon-bg{
        background-image: url('../images/coming-soon/bg/1.jpg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        height: 100vh;
    }
    .inner-content{
        padding-top: 100px;
        overflow-y: auto;
        width: 100%;
        .logo{
            padding-bottom: 40px;
        }
        h1{
            &.heading{
                @include typography(60px, 60px);
                color: $white-color;
                padding-bottom: 25px;
                @include breakpoint(max-sm_device){
                    font-size: 35px;
                    line-height: 1.2;
                }
            }
        }
        p{
            &.short-desc{
                color: $white-color;
                max-width: 50%;
                margin: 0 auto;
                padding-bottom: 90px;
                letter-spacing: -0.2px;
                @include breakpoint(min-xxl_device){
                    max-width: 36%;
                }
                @include breakpoint(max-sm_device){
                    max-width: 95%;
                }
            }
        }
        h2{
            &.sub-heading{
                @include typography(16px, 16px);
                padding-bottom: 35px;
                color: $white-color;
            }
        }
        .social-link{
            padding-top: 40px;
            padding-bottom: 95px;
            li{
                a{
                    color: $white-color;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}