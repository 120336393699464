/*----------------------------------------*/
/*  38 - Pages - Error 404
/*----------------------------------------*/
.error-404_area{
    text-align: center;
    .error-img{
        padding-bottom: 25px;
    }
    h1{
        &.heading{
            @include typography(24px, 28.8px);
            padding-bottom: 20px;
        }
    }
    p{
        &.short-desc{
            @include typography(18px, 28.8px);
            padding-bottom: 25px;
            a{
                text-decoration: underline;
                color: $primary-color;
            }
        }
    }
    .error-search_box{
        position: relative;
        display: inline-block;
        input{
            &.input-field{
                padding: 0 20px;
                width: 370px;
                height: 50px;
            }
        }
        .search-btn{
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;
        }
    }
}