/*----------------------------------------*/
/*  00 - Base - Typography
/*----------------------------------------*/
body {
	font-family: $fontFamily;
	@include typography($size, $lineHeight, $weight);
	color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $secondary-color;
	@include typography($weight: 600);
	font-family: sans-serif;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

img {
	max-width: 100%;
}

.img-full {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
	object-fit: cover;
}

a{
	color: $text-color;
	font-size: $size;
	text-transform: capitalize;
}

a,
button,
i {
	@extend %basetransition;
}

a,
a:hover,
a:focus,
a:active,
button,
button:focus,
.btn,
.btn:focus,
input,
input:focus,
select,
textarea,
.swiper-pagination-bullet,
.swiper-slide,
.swiper-button-next,
.swiper-button-prev {
	text-decoration: none;
	outline: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}

input{
	&.input-field{
		border: 1px solid $border-color;
	}
}
textarea{
	&.textarea-field{
		border: 1px solid $border-color;
	}
}

button,
.btn {
	cursor: pointer;
	border: 0;
	padding: 0;
	background: transparent;
}

.overflow-hidden{
	overflow: hidden;
}

.cursor-pointer{
	cursor: pointer;
}

.required{
	color: $red-color;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

 audio,
 canvas,
 iframe,
 img,
 svg,
 video {
   vertical-align: middle;
 }
 
/*
  * Remove default fieldset styles.
*/
 
 fieldset {
   border: 0;
   margin: 0;
   padding: 0;
 }
 
 /*
* Allow only vertical resizing of textareas.
*/
 
 textarea {
   resize: vertical;
}
