/*----------------------------------------*/
/*  24 - Components - Tab
/*----------------------------------------*/
/* ---Project Tab--- */
.project-tab{
    padding-bottom: 45px;
    ul{
        li{
            &:not(:last-child){
                padding-right: 35px;
                @include breakpoint(max-xxs_device){
                    padding-right: 15px;
                }
            }
            a{
                text-transform: uppercase;
                color: $nobel-color;
            }
        }
    }
}

/* ---Vertical Tab--- */
.vertical-tab{
    @include breakpoint(max-md_device){
        padding-bottom: 45px;
    }
    ul{
        &.tab-menu{
            flex-direction: column;
            li{
                &:not(:last-child){
                    padding-bottom: 15px;
                }
                a{
                    text-transform: uppercase;
                    &.active{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

/* ---Product Tab--- */
.product-tab{
    ul{
        &.product-menu{
            li{
                &:not(:last-child){
                    padding-right: 30px;
                }
                a{
                    @include typography(18px, 18px, 600);
                    text-transform: uppercase;
                    color: $nobel-color;
                    &.active{
                        color: $secondary-color;
                    }
                    &:hover{
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}