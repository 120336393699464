/*----------------------------------------*/
/*  11 - Components - Animation
/*----------------------------------------*/
/* ---Slider Animation--- */
.animation-style-01{
	&.swiper-slide-active{
		.slide-content{
			h1{
				&.heading{
					animation-name: fadeInDown;
					animation-delay: 0.5s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
				&.sub-heading{
					animation-name: fadeInDown;
					animation-delay: 1s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			p{
				animation-name: fadeInDown;
				animation-delay: 1.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.button-wrap{
				animation-name: fadeInDown;
				animation-delay: 2s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}
.animation-style-02{
	&.swiper-slide-active{
		.slide-content{
			span{
				&.date{
					animation-name: baSlideLeft;
					animation-delay: 0.3s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
				&.category{
					animation-name: baSlideUp;
					animation-delay: 0.3s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			h1{
				&.heading{
					animation-name: baSlideLeft;
					animation-delay: 0.3s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.btn-wrap{
				animation-name: baSlideUp;
				animation-delay: 0.3s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.slide-nav_count{
				span{
					animation-name: baSlideLeft;
					animation-delay: 0.3s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.vertical-social_link{
				animation-name: baSlideLeft;
				animation-delay: 0.3s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}

/* ---Baroque's Slide Left--- */
@keyframes baSlideLeft{
	0%{
		transform: translateX(-30px);
		opacity: 0;
	}
	100%{
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes baSlideUp{
	0%{
		transform: translateY(-30px);
		opacity: 0;
	}
	100%{
		transform: translateY(0);
		opacity: 1;
	}
}

/*Baroque's Slide Up Animation*/
@keyframes barSlideInUp {
	0% {
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/*Top Down Keyframes*/

@-webkit-keyframes barSlideInDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100px);
	}
  }
  @keyframes barSlideInDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100px);
	}
  }

  .barSlideInDown {
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

// Background Animation
.kenburns-top {
	animation: kenburns-top 5s linear 1 normal both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-13 17:17:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@keyframes kenburns-top {
  0% {
	transform: scale(1) translateY(0);
	transform-origin: 50% 16%;
  }
  100% {
	transform: scale(1.05) translateY(-15px);
	transform-origin: top;
  }
}