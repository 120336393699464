/*----------------------------------------*/
/*  39 - Pages - Contact
/*----------------------------------------*/
/* ---Google Map--- */
#google-map {
    height: 880px;
    @include breakpoint(max-sm_device){
        height: 350px;
    }
}

/* ---Contact Information--- */
.contact-info{
    h1{
        &.heading{
            @include typography(30px, 30px);
            padding-bottom: 15px;
            @include breakpoint(max-xxs_device){
                font-size: 25px;
                line-height: 1.2;
            }
            span{
                color: $nobel-color;
            }
        }
    }
    p{
        &.short-desc{
            max-width: 50%;
            padding-bottom: 45px;
        }
    }
    span{
        text-transform: uppercase;
        &.ba-email{
            color: $secondary-color;
            padding-bottom: 45px;
            display: block;
            a{
                text-transform: lowercase;
            }
        }
        &.ba-number{
            display: block;
            a{
                @include typography(36px, 57px, 600);
                color: $secondary-color;
                padding-top: 5px;
                display: block;
                @include breakpoint(max-xxs_device){
                    font-size: 30px;
                    line-height: 1.2;
                }
            }
        }
    }
    .ba-brand{
        padding-top: 45px;
        span{
            @include typography(14px, 16.8px, 600);
            text-transform: uppercase;
            color: $secondary-color;
            padding-bottom: 10px;
            display: block;
        }
        li{
            display: inline-block;
            &:not(:last-child){
                a{
                    &:before{
                        background-color: $text-color;
                        content: "";
                        position: absolute;
                        top: 3px;
                        right: -6px;
                        width: 1px;
                        height: 14px;
                    }
                }
            }
            a{
                position: relative;
                padding-right: 3px;
                margin-right: 8px;
                &:after{
                    background-color: $primary-color;
                    @extend %basetransition;
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                }
                &:hover{
                    &:after{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}