/*----------------------------------------*/
/*  30 - Components - Approach
/*----------------------------------------*/
.approach-item{
    padding-bottom: 40px;
    span{
        &.number{
            @include typography(16px, 16px);
            color: $primary-color;
            padding-bottom: 30px;
            display: block;
        }
    }
    h3{
        &.title{
            padding-bottom: 30px;
        }
    }
}