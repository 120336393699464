/*----------------------------------------*/
/*  31 - Components - Text Interactive
/*----------------------------------------*/
.text-interactive-area{
    ul{
        &.text-interactive{
            li{
                display: flex;
                &:not(:last-child){
                    padding-bottom: 45px;
                }
                .single-content{
                    h2{
                        &.heading{
                            @include typography(48px, 48px);
                            @extend %basetransition;
                            color: $silver-color;
                            padding-bottom: 5px;
                            @include breakpoint(max-xxs_device){
                                font-size: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                .fixed-img {
                    background-image: url('../images/text-interactive/1.jpg');
                    background-color: $white-color;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100%;
                    opacity: 0;
                    visibility: hidden;
                    @extend %basetransition;
                    position: fixed;
                    top: 0;
                    right: 0;
                    width: 50%;
                    z-index: 2;
                    @include breakpoint(max-md_device){
                        display: none;
                    }
                }
                &.active{
                    .single-content{
                        h2{
                            &.heading{
                                color: $secondary-color;
                            }
                        }
                    }
                    .fixed-img {
                        opacity: 1;
                        visibility: visible;
                        &.bg-1,
                        &.bg-2,
                        &.bg-3,
                        &.bg-4,
                        &.bg-5,
                        &.bg-6,
                        &.bg-7,
                        &.bg-8,
                        &.bg-9,
                        &.bg-10{
                            background-image: url('../images/text-interactive/1.jpg');
                            &.bg-2{
                                background-image: url('../images/text-interactive/2.jpg');
                            }
                            &.bg-3{
                                background-image: url('../images/text-interactive/3.jpg');
                            }
                            &.bg-4{
                                background-image: url('../images/text-interactive/4.jpg');
                            }
                            &.bg-5{
                                background-image: url('../images/text-interactive/5.jpg');
                            }
                            &.bg-6{
                                background-image: url('../images/text-interactive/6.jpg');
                            }
                            &.bg-7{
                                background-image: url('../images/text-interactive/7.jpg');
                            }
                            &.bg-8{
                                background-image: url('../images/text-interactive/8.jpg');
                            }
                            &.bg-9{
                                background-image: url('../images/text-interactive/9.jpg');
                            }
                            &.bg-10{
                                background-image: url('../images/text-interactive/10.jpg');
                            }
                        }
                    }
                }
            }
        }
    }
}