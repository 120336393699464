/*----------------------------------------*/
/*  21 - Components - Testimonials
/*----------------------------------------*/
.testimonials-item{
    border: 5px solid $primary-color;
    padding: 55px 60px;
    display: flex;
    @include breakpoint(max-sm_device){
        flex-direction: column;
        padding: 25px 30px;
    }
    &.without-border{
        border: 0;
        padding: 0;
    }
    .quote-header{
        display: flex;
        width: 34%;
        flex-direction: column;
        justify-content: space-between;
        @include breakpoint(max-sm_device){
            width: 100%;
        }
        h3{
            &.heading{
                max-width: 75%;
                @include typography(20px, 24px);
                @include breakpoint(max-lg_device){
                    font-size: 16px;
                }
                @include breakpoint(max-sm_device){
                    padding-bottom: 25px;
                }
            }
        }
        .quote-icon{
            background-color: #ced08d;
            border: 1px solid #ced08d;
            border-radius: 100%;
            position: relative;
            width: 97px;
            height: 97px;
            margin-left: 46px;
            margin-bottom: 23px;
            i{
                color: $secondary-color;
                font-size: 180px;
                position: absolute;
                bottom: -65px;
                left: -90px;
            }
        }
    }
    .quote-content{
        width: 75%;
        @include breakpoint(max-sm_device){
            padding-top: 15px;
            width: 100%;
        }
        p{
            &.feedback{
                @include typography(28px, 44.8px);
                font-family: 'Libre Baskerville';
                color: $secondary-color;
                padding-bottom: 60px;
                @include breakpoint(max-sm_device){
                    font-size: 16px;
                    line-height: 32.8px;
                }
            }
        }
        .user-info{
            span{
                @include typography(18px, 28.8px);
                &.user-name{
                    color: $secondary-color;
                }
            }
        }
    }
}