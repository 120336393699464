/*----------------------------------------*/
/*  32 - Components - Baroque Dark
/*----------------------------------------*/
.baroque-dark{
    background-color: $nero-color;
    color: #c7c7c7;
    .main-wrapper{
        // All Defult Element Color
        h2{
            color: $white-color;
        }
        p{
            color: #c7c7c7;
        }
    }

    /* ---Button--- */
    .btn-wrap{
        &.inner-img_style{
            background-color: $nero-color;
            a,
            i{
                color: $white-color;
            }
        }
        .team-btn{
            color: $white-color;
        }
    }
    .intro-btn{
        color: #c7c7c7;
    }

    /* ---Offcanvas--- */
    .mobile-menu_wrapper {
        .offcanvas-body {
            background-image: url('../images/offcanvas/1.jpg');
            background-color: transparent;
            .inner-body {
                position: relative;
                &:before {
                    background-color: rgba(0, 0, 0, .75);
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    z-index: -1;
                }
            }
        }
    }
    .offcanvas-minicart_wrapper{
        .offcanvas-body{
            background-color: $nero-color;
            .minicart-content{
                .minicart-heading{
                    h4{
                        color: $white-color;
                    }
                }
                .minicart-list{
                    li{
                        &.minicart-product{
                            .product-item_content{
                                a{
                                    &.product-item_title{
                                        color: $white-color;
                                    }
                                }
                                .product-item_quantity{
                                    color: $white-color;
                                }
                            }
                        }
                        &:not(:last-child){
                            border-bottom: 1px solid #c7c7c7;
                        }
                    }
                }
            }
            .minicart-item_total{
                span{
                    color: $white-color;
                }
            }
        }
    }

    /* ---Header Area--- */
    .main-header_area-2,
    .main-header_area-4,
    .main-header_area-6{
        .main-header{
            .main-nav{
                > ul{
                    > li{
                        &.megamenu-holder{
                            .ba-megamenu_wrap{
                                background-color: $nero-color;
                                ul{
                                    &.ba-megamenu{
                                        span{
                                            &.title{
                                                color: $white-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .baroque-dropdown{
                            background-color: $nero-color;
                        }
                    }
                }
            }
            &.header-sticky{
                background-color: $nero-color;
            }
        }
    }
    .main-header_area-7{
        .header-sticky{
            background-color: $nero-color;
        }
    }

    /* ---Slider Area--- */
    .slider-area-2{
        .mh-additional_content{
            background-color: #292828;
            .swiper-slide{
                &.swiper-slide-thumb-active{
                    a{
                        span{
                            color: $white-color;
                        }
                    }
                }
                a{
                    &:hover{
                        span{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .home-verticle_slider{
        .swiper-wrapper{
            .swiper-slide{
                .slide-content{
                    h2{
                        &.heading{
                            background-color: transparent;
                        }
                    }
                    span{
                        &.category{
                            color: $white-color;
                        }
                    }
                    .btn-wrap{
                        a{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }

    /* ---Service Item--- */
    .service-item{
        .service-content{
            .inner-content{
                background-color: $nero-color;
                h3{
                    &.heading{
                        a{
                            color: $white-color;
                        }
                    }
                }
                a,
                i{
                    color: $white-color;
                }
            }
        }
    }
    .migrate-item_area{
        .service-title{
            span{
                &.category{
                    color: $white-color;
                }
            }
        }
        .specific-newsletter{
            p{
                &.short-desc{
                    span{
                        color: $white-color;
                    }
                }
            }
            form{
                &.newsletters-form{
                    .subscribe-form{
                        input{
                            &.newsletter-input{
                                background-color: $nero-color;
                                color: #c7c7c7;
                                border-color: #c7c7c7;
                            }
                        }
                    }
                }
            }
        }
    }

    /* ---Introduction--- */
    .introduction-area-4{
        .additional-content{
            background-color: $nero-color;
        }
    }

    /* ---Latest Project--- */
    .latest-project_area{
        .btn-wrap{
            a,
            i{
                color: $white-color;
            }
        }
        .list-project_item{
            .single-content{
                .inner-content{
                    background-color: $nero-color;
                }
            }
        }
    }

    /* ---Project--- */
    .project-item{
        .single-content{
            h3{
                &.heading{
                    a{
                        color: $white-color;
                    }
                }
            }
        }
    }
    .list-project_item{
        .single-content{
            .inner-content{
                background-color: $nero-color;
            }
        }
    }
    .project-parallax_slider{
        .swiper-wrapper{
            .swiper-slide{
                .project-item{
                    .single-content{
                        .inner-content{
                            background-color: $nero-color;
                            h1{
                                &.heading{
                                    color: $white-color;
                                }
                            }
                        }
                        .btn-wrap{
                            a{
                                &.project-btn{
                                    background-color: $nero-color;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .onepage-project_slider{
        .swiper-slide{
            .slide-content{
                h2{
                    &.heading{
                        background-color: transparent;
                    }
                }
                span{
                    &.area{
                        color: $white-color;
                    }
                }
                p{
                    &.location,
                    &.year{
                        color: $white-color;
                    }
                }
                .btn-wrap{
                    a{
                        color: $white-color;
                    }
                }
            }
        }
    }

    /* ---Project Details--- */
    .project-details_area{
        .pd-content{
            background-color: $nero-color;
            h1,
            h3{
                color: $white-color;
            }
        }
    }
    .pd-style_three{
        .fixed-sidebar{
            .inner-content{
                background-color: $nero-color;
            }
            .pagination-area{
                border-bottom: 1px solid #c7c7c7;
            }
            h1{
                &.heading{
                    color: $white-color;
                }
            }
            .project-meta{
                > ul{
                    > li{
                        &:not(:last-child){
                            border-bottom: 1px solid #c7c7c7;
                        }
                        h3,span{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .pd-style_four{
        .project-meta{
            ul{
                li{
                    h3,
                    span{
                        color: $white-color;
                    }
                }
            }
        }
    }

    /* ---Empty Space--- */
    .empty-space{
        background-color: $nero-color;
    }

    /* ---News Area--- */
    .news-area{
        .single-list_item{
            .single-content{
                background-color: $nero-color;
                h3{
                    &.heading{
                        a{
                            color: $white-color;
                        }
                    }
                }
            }
            &:hover{
                .single-content{
                    background-color: $primary-color;
                }
            }
        }
    }
    .news-masonry_area{
        .masonary-item{
            .single-content{
                background-color: $nero-color;
            }
            &:hover{
                .single-content{
                    background-color: $primary-color;
                }
            }
            .btn-wrap{
                background-color: transparent;
            }
        }
    }
    .news-text_item{
        .single-content{
            h3{
                &.heading{
                    a{
                        color: $white-color;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }

    /* ---News Post Area--- */
    .news-post_area{
        h1{
            &.heading{
                color: $white-color;
            }
        }
        .additional-content{
            h3{
                &.heading{
                    color: $white-color;
                }
            }
        }
    }

    /* ---Blockquote--- */
    blockquote {
        &:before{
            color: $white-color;
        }
        .user-info{
            span{
                &.user-name{
                    color: $white-color;
                }
            }
        }
    }

    /* ---Tags List--- */
    .tags-list{
        span{
            color: $white-color;
        }
    }

    /* ---Testimonials Item--- */
    .testimonials-item{
        .quote-header{
            h3{
                &.heading{
                    color: $white-color;
                }
            }
            .quote-icon{
                i{
                    color: $white-color;
                }
            }
        }
        .quote-content{
            p{
                &.feedback{
                    color: $white-color;
                }
            }
            .user-info{
                span{
                    &.user-name{
                        color: $white-color;
                    }
                }
            }
        }
    }

    /* ---Single Item--- */
    .single-item{
        .single-content{
            h3{
                a{
                    color: $white-color;
                }
            }
        }
        .btn-wrap{
            background-color: $nero-color;
            a,
            i{
                color: $white-color;
            }
        }
    }
    
    /* ---Footer Area--- */
    .footer-area{
        background-color: #161616;
        .footer-widget{
            li{
                span{
                    &.desc{
                        color: $white-color;
                        a{
                            color: $white-color;
                        }
                    }
                }
                a{
                    color: $white-color;
                }
                &.language-menu{
                    > ul{
                        &.language-dropdown{
                            background-color: #161616;
                        }
                    }
                }
            }
        }
    }
    .footer-area-2{
        .footer-widgets_area{
            &.with-border{
                border-top: 1px solid #c7c7c7;
            }
        }
    }

    /* ---Image Hover Effect--- */
    .img-hover_effect-3{
        &:before{
            background-color: $nero-color;
        }
        .single-img{
            .inner-img{
                .inner-content{
                    h3{
                        &.heading{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }

    /* ---Home Masonry--- */
    .home-masonry_content{
        .section-area{
            p{
                &.short-desc{
                    color: $white-color;
                }
            }
        }
    }

    /* ---Home Vertical Slider Wrapper--- */
    .home-vs_wrapper {
        background-image: url('../images/home-background/vslide-bg-dark.jpg');
    }

    /* ---Approach--- */
    .approach-item{
        h3{
            &.title{
                color: $white-color;
            }
        }
    }
    /* ---Form--- */
    form{
        &.feedback-form{
            &.with-border-bottom{
                .form-field{
                    input{
                        &.input-field{
                            background-color: $nero-color;
                            border-bottom: 1px solid #c7c7c7;
                            color: #c7c7c7;
                        }
                    }
                }
                .form-field{
                    textarea{
                        &.textarea-field{
                            background-color: $nero-color;
                            border-bottom: 1px solid #c7c7c7;
                            color: #c7c7c7;
                        }
                    }
                }
            }
        }
    }
    .form-area{
        h3{
            &.heading{
                color: $white-color;
            }
        }
        .feedback-form{
            .form-field{
                textarea{
                    &.textarea-field{
                        background-color: $nero-color;
                        border-color: #c7c7c7;
                        color: #c7c7c7;
                    }
                }
                input{
                    &.input-field{
                        background-color: $nero-color;
                        border-color: #c7c7c7;
                        color: #c7c7c7;
                    }
                }
            }
        }
    }

    /* ---Baroque Dark Wrapper--- */
    .ba-dark_wrapper{
        background-color: $nero-color;
        .main-header_area{
            .header-left_area{
                .header-intro{
                    span{
                        color: $white-color;
                    }
                }
            }
            .header-right_area{
                ul{
                    li{
                        a,
                        i{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }

    /* ---Text Interactive Area--- */
    .text-interactive-area{
        ul{
            &.text-interactive{
                li{
                    &.active{
                        .single-content{
                            h2{
                                &.heading{
                                    color: $white-color;
                                }
                            }
                            span{
                                &.category{
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* ---Main Page Heading--- */
    .main-page_heading{
        color: $white-color;
    }

    /* ---Sidebar--- */
    .sidebar-area{
        .project-meta{
            .single-item{
                span,
                h3{
                    color: $white-color;
                }
                &[class*="single-item"]:not(:last-child){
                    border-bottom: 1px solid #c7c7c7;
                }
            }
        }
        .search-box{
            input{
                &.search-box_input{
                    background-color: $nero-color;
                    border: 1px solid #c7c7c7;
                    color: #c7c7c7;
                }
            }
        }
        .widgets-area{
            .widgets-item{
                ul{
                    li{
                        &:not(:last-child){
                            a{
                                border-color: #c7c7c7;
                            }
                        }
                        a{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }

    /* ---Pagination Area--- */
    .pagination-area{
        ul{
            li{
                a{
                    color: $white-color;
                }
            }
        }
    }
    .paginatoin-area-2{
        &.position-center{
            border-bottom: 1px solid #c7c7c7;
        }
    }

    /* ---Newsletter Form Wrap--- */
    .newsletter-form_wrap{
        .subscribe-form{
            .newsletter-input{
                background-color: $nero-color;
                border: 1px solid #c7c7c7;
                color: #c7c7c7;
            }
        }
    }

    /* ---Baroque Member--- */
    .ba-member{
        .single-content{
            span{
                &.member-position{
                    color: $white-color;
                }
            }
            h3{
                &.member-name{
                    color: $white-color;
                }
            }
            ul{
                &.social-link{
                    li{
                        a{
                            color: $white-color;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }

    /* ---Error 404--- */
    .error-404_area{
        h1{
            &.heading{
                color: $white-color;
            }
        }
        .error-search_box{
            input{
                &.input-field{
                    background-color: $nero-color;
                    border-color: #c7c7c7;
                    color: #c7c7c7;
                }
            }
        }
    }

    /* ---Contact Area--- */
    .contact-area{
        .contact-info{
            h1{
                &.heading{
                    color: $white-color;
                }
            }
            span{
                &.ba-email{
                    color: $white-color;
                }
                &.ba-number{
                    a{
                        color: $white-color;
                    }
                }
            }
            .ba-brand{
                span{
                    color: $white-color;
                }
            }
        }
    }

    /* ---Shop Area--- */
    .shop-area{
        .banner-content{
            .inner-content{
                background-color: $nero-color;
                h1{
                    &.heading{
                        color: $white-color;
                    }
                }
            }
        }
        .product-tab{
            ul{
                &.product-menu{
                    li{
                        a{
                            &.active{
                                color: $white-color;
                            }
                            &:hover{
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
        .shorting-menu{
            ul{
                li{
                    a{
                        color: $white-color;
                        i{
                            color: $white-color;
                        }
                    }
                    > ul{
                        background-color: $nero-color;
                        border-color: $nero-color;
                        li{
                            a{
                                &.active{
                                    color: $primary-color;
                                }
                                &:hover{
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .product-item{
            .product-content{
                h3{
                    &.product-name{
                        a{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }

    /* ---Product Detail Area--- */
    .product-detail_area{
        .product-detail_content{
            h1{
                &.heading{
                    color: $white-color;
                }
            }
        }
    }

    /* ---Nice Select--- */
    .nice-select{
        background-color: $nero-color;
        border-color: #c7c7c7;
        ul{
            background-color: $nero-color;
            border: 1px solid #c7c7c7;
        }
    }

    /* ---Quantity--- */
    .quantity{
        .cart-plus-minus{
            .cart-plus-minus-box{
                background-color: $nero-color;
                border-color: #c7c7c7;
                color: #c7c7c7;
            }
            .qtybutton{
                border-top: 1px solid #c7c7c7;
                border-right: 1px solid #c7c7c7;
                border-bottom: 1px solid #c7c7c7;
            }
        }
    }

    /* ---Product Detail Tab--- */
    .product-detail_tab{
        .information-body{
            table{
                td, 
                th{
                    border-top: 1px solid #c7c7c7;
                    color: #c7c7c7;
                }
            }
        }
        .tab-content{
            .review-body{
                .user-feedback{
                    h3{
                        &.heading{
                            color: $white-color;
                        }
                    }
                    form{
                        &.pd-feedback_form{
                            .form-field{
                                textarea{
                                    &.textarea-field{
                                        background-color: $nero-color;
                                        border-color: #c7c7c7;
                                        color: #c7c7c7;
                                    }
                                }
                                input{
                                    &.input-field{
                                        background-color: $nero-color;
                                        border-color: #c7c7c7;
                                        color: #c7c7c7;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /* ---Product Details Meta--- */
    .product-detail_meta{
        .pd-meta_nav{
            border-color: #c7c7c7;
            .sku-wrapper,
            .posted-in{
                span{
                    strong{
                        color: $white-color;
                    }
                }
            }
        }
    }
    
    /* ---Related Product--- */
    .related-ptoduct_area{
        h6{
            &.heading{
                color: $white-color;
            }
        }
        .product-item{
            .product-content{
                h3{
                    &.product-name{
                        a{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }

    /* ---Checkout Area--- */
    .checkout-area{
        .coupon-accordion{
            h3{
                background-color: $secondary-color;
                color: #c7c7c7;
            }
            .coupon-content{
                border-color: #c7c7c7;
                .coupon-info{
                    p{
                        &.form-row-first{
                            input{
                                border-color: #c7c7c7;
                                color: #c7c7c7;
                            }
                        }
                        &.form-row-last{
                            input{
                                border-color: #c7c7c7;
                                color: #c7c7c7;
                            }
                        }
                    }
                }
            }
            .coupon-checkout-content{
                .coupon-info{
                    .checkout-coupon{
                        input{
                            border-color: #c7c7c7;
                        }
                        input[type="text"]{
                            color: #c7c7c7;
                        }
                    }
                }
            }
        }
        .checkbox-form{
            h3{
                border-color: #c7c7c7;
                color: $white-color;
            }
            .checkout-form-list input{
                background-color: $nero-color;
                border-color: #c7c7c7;
            }
            .checkout-form-list input[type="text"],
            .checkout-form-list input[type="password"],
            .checkout-form-list input[type="email"]{
                color: #c7c7c7;
            }
        }
        .order-notes{
            textarea{
                border-color: #c7c7c7;
                color: #c7c7c7;
            }
        }
        .your-order{
            background-color: $nero-color;
            h3{
                border-color: #c7c7c7;
                color: $white-color;
            }
            .your-order-table{
                table{
                    thead{
                        background-color: $nero-color;
                        color: #c7c7c7;
                    }
                    td, th{
                        border-color: #c7c7c7;
                        color: #c7c7c7;
                    }
                }
            }
            .payment-accordion{
                #accordion{
                    .card{
                        background-color: $nero-color;
                        .card-header{
                            background-color: $nero-color;
                        }
                    }
                }
            }
        }
    }

    /* ---Baroque Cart Area--- */
    .baroque-cart-area{
        .table-content{
            table{
                color: #c7c7c7;
                thead{
                    th{
                        border-color: #c7c7c7;
                        color: #c7c7c7;
                    }
                }
                th,td{
                    border-color: #c7c7c7;
                    color: #c7c7c7;
                }
            }
        }
        .coupon-all{
            .coupon{
                input{
                    border-color: #c7c7c7;
                    &.input-text{
                        color: #c7c7c7;
                    }
                }
            }
        }
        .cart-page-total{
            > ul{
                border-color: #c7c7c7;
                li{
                    border-color: #c7c7c7;
                }
            }
            a{
                border-color: #c7c7c7;
                &:hover{
                    border-color: $primary-color;
                }
            }
        }
    }

    /* ---My Account--- */
    .my-account_area{
        .my-account_tab{
            ul{
                &.tab-menu{
                    li{
                        a{
                            &.active{
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
        .login-form{
            .form-field{
                input{
                    &.input-field{
                        background-color: $nero-color;
                        border-color: #c7c7c7;
                        color: #c7c7c7;
                    }
                }
            }
        }
    }

    // Demo Switcher
    .demo-switcher_area ul li a.demo-switcher_btn {
        background-color: rgba(255,255,255,.8);
        color: $nero-color;
        &:hover{
            background-color: #fff;
        }
    }

    .scroll-to-top-2{
        i{
            color: $white-color;
        }
    }
}