/*----------------------------------------*/
/*  25 - Components - Brand
/*----------------------------------------*/
.brand-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(max-sm_device){
        flex-wrap: wrap;
    }
    .single-img{
        &[class*="single-img"]{
            &:not(:last-child){
                @include breakpoint(max-xs_device){
                    padding-right: 25px;
                }
                @include breakpoint(max-sm_device){
                    padding-bottom: 30px;
                }
            }
        }
        @include breakpoint(max-xs_device){
            width: 50%;
        }
        img{
            @extend %basetransition;
        }
        &:hover{
            img{
                opacity: 0.25;
            }
        }
    }
}
.hover-tyle-2{
    .brand-item{
        .single-img{
            &[class*="single-img"]{
                &:not(:last-child){
                    padding-right: 30px;
                    @include breakpoint(max-xs_device){
                        padding-bottom: 30px;
                    }
                }
            }
            img{
                opacity: 0.5;
            }
            &:hover{
                img{
                    opacity: 1;
                }
            }
        }
    }
}