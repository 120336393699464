/*----------------------------------------*/
/*  28 - Components - Home Masonry
/*----------------------------------------*/
.home-masonry_content{
    .section-area{
        text-align: center;
        span{
            @include typography(16px, 19.2px, 600);
            text-transform: uppercase;
            padding-bottom: 45px;
            display: block;
        }
        p{
            &.short-desc{
                color: $secondary-color;
                max-width: 75%;
                @include typography(20px);
                margin: 0 auto;
                @include breakpoint(max-md_device){
                    max-width: 85%;
                }
                @include breakpoint(max-sm_device){
                    max-width: 100%;
                    font-size: 16px;
                }
            }
        }
    }
    .masonry-grid{
        display: flex;
        margin: 0;
        &[class*="masonry-grid"]{
            &:not(:last-child){
                padding-bottom: 80px;
                @include breakpoint(max-xs_device){
                    padding-bottom: 20px;
                }
            }
        }
        .grid-item{
            padding-left: 0;
            padding-right: 20px;
            margin-bottom: 0;
            width: 33.333333%;
            @include breakpoint(max-md_device){
                width: 50%;
            }
            @include breakpoint(max-xs_device){
                width: 100%;
            }
            &[class*="grid-item"]{
                @include breakpoint(min-lg_device){
                    &:last-child{
                        padding-right: 0;
                    }
                }
                @include breakpoint(max-md_device){
                    &:nth-child(2){
                        padding-right: 0;
                    }
                    &:first-child{
                        padding-bottom: 20px;
                    }
                }
                @include breakpoint(max-xs_device){
                    padding-right: 0;
                    &:not(:last-child){
                        padding-bottom: 20px;
                    }
                }
            }
            &.grid-item--height2,
            &.grid-item--height3{
                @include breakpoint(max-xs_device){
                    height: auto;
                }
            }
            .img-hover_effect-3{
                .inner-content{
                    padding: 25px 30px;
                }
            }
        }
    }
}