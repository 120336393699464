/*----------------------------------------*/
/*  27 - Components - My Account
/*----------------------------------------*/
.my-account_tab{
    padding-bottom: 30px;
    ul{
        &.tab-menu{
            li{
                &:not(:last-child){
                    padding-right: 25px;
                }
                a{
                    @include typography(48px, 76px, 600);
                    @include breakpoint(max-sm_device){
                        font-size: 30px;
                        line-height: 1.2;
                    }
                    &.active{
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}
.login-form{
    .form-field{
        padding-bottom: 25px;
        input{
            &.input-field{
                width: 100%;
                height: 50px;
                padding: 0 20px;
            }
        }
        &.password-field{
            position: relative;
            input{
                &.input-field{
                    padding: 0 95px 0 20px;
                }
            }
            a{
                &.pass-recovery{
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    font-weight: 600;
                }
            }
        }
    }
    .check-box{
        padding-top: 40px;
        input{
            &.input-checkbox{
                vertical-align: middle;
            }
        }
        label{
            &.input-label{
                cursor: pointer;
            }
        }
    }
    .form-btn_wrap{
        padding-top: 25px;
    }
}