/*----------------------------------------*/
/*  26 - Components - Team
/*----------------------------------------*/
.team-header{
    display: flex;
    align-items: flex-end;
    @include breakpoint(max-xs_device){
        flex-direction: column;
        align-items: flex-start;
    }
    .heading-wrap{
        width: 50%;
        @include breakpoint(max-xs_device){
            padding-bottom: 10px;
            width: 100%;
        }
        h2{
            &.heading{
                @include typography(36px, 43.2px);
                @include breakpoint(max-xs_device){
                    font-size: 30px;
                    line-height: 1.4;
                }
            }
        }
    }
    .btn-wrap{
        .team-btn{
            i{
                vertical-align: middle;
            }
        }
    }
}

/* ---Member Wrap--- */
.member-wrap{
    margin-right: -10px;
    margin-left: -10px;
    &[class*="member-wrap"]{
        &:not(:last-child){
            padding-bottom: 20px;
        }
        [class*="col-"]{
            &:not(:nth-child(n+3)){
                @include breakpoint(max-md_device){
                    .ba-member{
                        margin-bottom: 20px;
                    }
                }
            }
            &:not(:last-child){
                @include breakpoint(max-xs_device){
                    .ba-member{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    [class*="col-"]{
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* ---Baroque Member--- */
.ba-member{
    .single-content{
        text-align: right;
        padding: 15px 25px 25px;
        span{
            &.member-position{
                @include typography(14px, 22.4px);
                color: $secondary-color;
                display: block;
                padding-bottom: 10px;
            }
        }
        h3{
            &.member-name{
                @include typography(24px, 28.8px);
                padding-bottom: 15px;
                margin-bottom: 20px;
                position: relative;
                &:before{
                    background-color: $primary-color;
                    width: 0;
                    height: 1px;
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    @extend %basetransition;
                }
            }
        }
        ul{
            &.social-link{
                li{
                    &:not(:last-child){
                        padding-right: 10px;
                    }
                    a{
                        color: $secondary-color;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
    &:hover{
        .single-content{
            h3{
                &.member-name{
                    &:before{
                        width: 70px;
                    }
                }
            }
        }
    }
}

/* ---Apply Membership--- */
.apply-membership{
    .membership-item{
        h2{
            &.heading{
                @include typography(48px, 57.6px);
                padding-bottom: 30px;
                @include breakpoint(max-sm_device){
                    font-size: 30px;
                    line-height: 39.6px;
                    padding-bottom: 10px;
                }
            }
        }
        .btn-wrap{
            padding-top: 25px;
        }
    }
}