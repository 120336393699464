/*----------------------------------------*/
/*  22 - Components - News
/*----------------------------------------*/
/* ---Single Item--- */
.single-item{
    .single-content{
        .news-meta{
            display: flex;
            padding-top: 20px;
            span{
                @include typography(14px, 22.4px);
                text-transform: uppercase;
                font-family: serif;
                &.meta-category{
                    padding-left: 5px;
                    a{
                        @include typography(14px, 22.4px);
                        text-transform: uppercase;
                        color: $primary-color;
                    }
                }
            }
        }
        h3{
            &.heading{
                padding-top: 5px;
                a{
                    @include typography(24px, 28.8px);
                    color: $secondary-color;
                    letter-spacing: -0.5px;
                    @include breakpoint(max-lg_device){
                        font-size: 20px;
                    }
                }
            }
        }
        p{
            &.short-desc{
                @include typography(14px, 22.8px);
                padding-top: 20px;
            }
        }
    }
    &[class*="single-item"]{
        &:not(:last-child){
            padding-bottom: 55px;
        }
    }
}

/* ---Latest New Slider--- */
.latest-news_slider{
    .single-item{
        &[class*="single-item"]{
            &:not(:last-child){
                padding-bottom: 0;
            }
        }
    }
}

/* ---Single List Item--- */
.single-list_item{
    display: flex;
    cursor: pointer;
    @include breakpoint(max-sm_device){
        flex-direction: column;
    }
    .single-img{
        width: calc(100% - 400px);
        @include breakpoint(max-sm_device){
            width: 100%;
        }
    }
    .single-content{
        background: $white-smoke_color;
        padding: 50px 55px;
        width: 400px;
        @extend %basetransition;
        @include breakpoint(max-sm_device){
            width: 100%;
        }
        @include breakpoint(max-xxs_device){
            padding: 20px 25px;
        }
        .news-meta{
            padding-bottom: 10px;
            span{
                &.meta-category{
                    a{
                        color: $primary-color;
                    }
                }
            }
        }
        h3{
            &.heading{
                padding-bottom: 30px;
                a{
                    @include typography(24px, 28.8px);
                    color: $secondary-color;
                    letter-spacing: -1px;
                }
            }
        }
        p{
            &.short-desc{
                @include typography(14px, 22.4px);
            }
        }
        .btn-wrap{
            padding-top: 205px;
            @include breakpoint(max-sm_device){
                padding-top: 35px;
            }
        }
    }
    &:hover{
        .single-content{
            background-color: $primary-color;
            h3{
                &.heading{
                    a{
                        color: $white-color;
                    }
                }
            }
            span,
            span.meta-category a,
            p{
                color: $very-light-grey_color;
            }
            .btn-wrap a,
            .btn-wrap a i{
                color: $white-color;
            }
        }
    }
    &[class*="single-list_item"]{
        &:not(:last-child){
            padding-bottom: 50px;
        }
    }
}

/* ---Masonary Item--- */
.masonary-item{
    cursor: pointer;
    .single-img{
        &.grid-img_height{
            height: 290px;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        &.grid-img_height2{
            height: 380px;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .single-content{
        background: $white-smoke_color;
        padding: 25px 30px;
        @extend %basetransition;
        .news-meta{
            padding-bottom: 5px;
            padding-top: 0;
            span{
                &.meta-category{
                    a{
                        color: $primary-color;
                    }
                }
            }
        }
        h3{
            &.heading{
                padding-bottom: 15px;
                a{
                    @include typography(24px, 28.8px);
                    color: $secondary-color;
                    letter-spacing: -1px;
                }
            }
        }
        p{
            &.short-desc{
                @include typography(14px, 22.4px);
            }
        }
        .btn-wrap{
            padding-top: 15px;
        }
    }
    &:hover{
        .single-content{
            background-color: $primary-color;
            h3{
                &.heading{
                    a{
                        color: $white-color;
                    }
                }
            }
            span,
            span.meta-category a,
            p{
                color: $very-light-grey_color;
            }
            .btn-wrap a,
            .btn-wrap a i{
                color: $white-color;
            }
        }
    }
}

/* ---News Text Area--- */
.news-text_item{
    padding-bottom: 45px;
    .single-content{
        h3{
            &.heading{
                padding-bottom: 10px;
                a{
                    @include typography(36px, 43.2px);
                    color: $secondary-color;
                    letter-spacing: -1px;
                    @include breakpoint(max-sm_device){
                        font-size: 25px;
                        line-height: 1.2;
                    }
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
        .news-meta{
            span{
                &.meta-category{
                    a{
                        text-transform: uppercase;
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

/* ---New Post Area--- */
.news-post_area{
    .news-meta{
        padding-bottom: 30px;
        span{
            text-transform: uppercase;
            &.meta-category{
                a{
                    text-transform: uppercase;
                    color: $primary-color;
                }
            }
        }
    }
    h1{
        &.heading{
            @include typography(48px, 57.6px);
            padding-bottom: 80px;
            letter-spacing: -1px;
            @include breakpoint(max-sm_device){
                font-size: 30px;
                line-height: 39.6px;
            }
        }
    }
    h2{
        &.sub-heading{
            @include typography(24px, 38.4px, 400);
            letter-spacing: -0.2px;
            padding-bottom: 40px;
        }
    }
    p{
        &.short-desc{
            padding-bottom: 45px;
        }
    }
    .additional-content{
        h3{
            &.heading{
                @include typography(20px, 24px, 600);
                padding-top: 55px;
                padding-bottom: 45px;
                @include breakpoint(max-xxs_device){
                    font-size: 18px;
                }
            }
        }
        ul{
            &.baroque-list{
                padding-left: 50px;
                list-style: disc;
                padding-bottom: 45px;
                li{
                    &:not(:last-child){
                        padding-bottom: 10px;
                    }
                }
            }
        }
        p{
            &.short-desc{
                a{
                    color: $deep-sky-blue_color;
                    text-decoration: underline;
                }
            }
        }
    }
    .form-area{
        form{
            &.feedback-form{
                .group-input{
                    [class*="input-field"]{
                        &:not(:last-child){
                            @include breakpoint(max-sm_device){
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}