//--- All Variables - Related Stuff Included In This Area ---↓

//----baroque Typography----↓
$fontFamily: 'Work Sans', sans-serif;
$size: 16px;
$lineHeight: 1.6;
$weight: 400;

//----Color----↓
$primary-color:     #666600;
$primary-color-2:   #ee4300;
$secondary-color:   #000000;
$text-color:        #666666;
$border-color:      #e5e5e5;
$nero-color:        #1b1b1b;

//----Additional Color Variable----↓
$white-color:       #ffffff;
$silver-color:      #bbbbbb;
$red-color:         #ff0000;
$snow-color:        #fcfcfc;
$gamboge-color:     #dc9b0e;
$coral-color:       #ff8c46;
$zircon-color:      #DAE5E7;
$radical-red_color :#ff1d5d;
$nobel-color:       #999999;
$atlantis-color:    #8cc63e;
$charcoal-color:    #454545;
$white-smoke_color: #f2f2f2;
$dark-gray_color:   #ababab;
$gray-color:        #7e7e7e;
$very-light-grey_color:#ccc;
$deep-sky-blue_color:#00CCFF;

//----Social Link Color Variables----↓
$facebook-color:    #3b579d;
$twitter-color:     #3acaff;
$google-plus-color: #ca0813;
$linkedin-color:    #0097bd;
$pinterest-color:   #cb2027;
$instagram-color:   #833ab4;
$skype-color:       #00aff0;
$share-color:       #fe6d4c;
$youtube-color:     #d72525;
$vimeo-color:       #00adef;

// Template Color Variation ----↓
$color-1: $primary-color;
$color-2: $primary-color-2;
$colorList: $color-1, $color-2;
