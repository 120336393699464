/*----------------------------------------*/
/*  23 - Components - Sidebar
/*----------------------------------------*/
.sidebar-area{
    @include breakpoint(max-md_device){
        padding-top: 95px;
    }
    .search-box{
        position: relative;
        input{
            &.search-box_input{
                border: 1px solid $border-color;
                width: 100%;
                height: 50px;
                padding: 0 45px 0 15px;
            }
        }
        button{
            &.search-box_btn{
                color: $charcoal-color;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }

    .widgets-area{
        padding-top: 55px;
        h2{
            &.heading{
                @include typography(20px, 24px);
                padding-bottom: 25px;
            }
        }
        .widgets-item{
            ul{
                li{
                    &:not(:last-child){
                        a{
                            border-bottom: 1px solid $border-color;
                            padding-bottom: 10px;
                            margin-bottom: 11px;
                        }
                    }
                    a{
                        @include typography(16px, 24px, 500);
                        color: $secondary-color;
                        display: block;
                        &:hover{
                            color: $primary-color;
                        }
                        i{
                            vertical-align: middle;
                            padding-right: 10px;
                        }
                    }
                    span,
                    i{
                        color: $nobel-color;
                    }
                }
            }
            &.without-border{
                ul{
                    li{
                        &:not(:last-child){
                           a{
                            border-bottom: 0;
                            margin-bottom: 0;
                           }
                        }
                    }
                }
            }
            &.tags-item{
                ul{
                    li{
                        display: inline-block;
                        a{
                            padding-bottom: 0;
                            text-transform: none;
                        }
                    }
                }
            }
            &.color-option{
                ul{
                    li{
                        a{
                            display: flex;
                            justify-content: space-between;
                            span{
                                color: $secondary-color;
                                @extend %basetransition;
                            }
                        }
                        &:hover{
                            a{
                                span{
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .project-meta{
        .single-item{
            text-align: right;
            span{
                @include typography(14px, 16.8px);
                text-transform: uppercase;
                padding-bottom: 5px;
                color: $nobel-color;
                display: block;
            }
            h3{
                &.heading{
                    @include typography(18px, 28px, 400);
                }
            }
            &[class*="single-item"]{
                &:not(:last-child){
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 15px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}