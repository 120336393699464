/*----------------------------------------*/
/*  41 - Pages - Product Detail
/*----------------------------------------*/
.breadcrumbs-with_pagination{
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    .pagination{
        ul{
            display: flex;
            li{
                &:first-child{
                    padding-right: 10px;
                }
            }
        }
    }
}

/* ---Product Detial Image--- */
.product-detail_img{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    .product-detail_slider{
        flex-basis: 85%;
        max-width: 85%;
        margin-left: 30px;
        cursor: crosshair;
    }
    .pd-slider-nav{
        flex-basis: 15%;
        max-width: 15%;
        height: 100%;
        .swiper-wrapper{
            height: 100%;
            .swiper-slide{
                height: 100% !important;
                opacity: 0.5;
                &.swiper-slide-thumb-active{
                    opacity: 1;
                }
            }
        }
    }
    &.vt-right_side{
        flex-direction: unset;
    }
}

/* ---Product Detail Content--- */
.product-detail_content{
    @include breakpoint(max-md_device){
        padding-top: 45px;
    }
    h1{
        &.heading{
            @include typography(30px, 36px, 400);
            letter-spacing: -1px;
            padding-bottom: 5px;
        }
    }
    .product-review-area{
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
    }
    p{
        &.short-desc{
            padding-bottom: 15px;
        }
    }
    .price-box{
        span{
            &.new-price{
                @include typography(22px, 35.2px);
            }
        }
    }
    .product-color{
        padding-top: 20px;
        display: flex;
    }
    .quantity-with_btn{
        padding-top: 30px;
        display: flex;
        .btn-wrap{
            padding-left: 20px;
        }
    }
    .social-link{
        padding-top: 25px;
    }
}

/* ---Product Detail Tab--- */
.product-detail_tab{
    .horizontal-tab{
        ul{
            display: flex;
            justify-content: center;
            padding-bottom: 35px;
            &.tab-menu{
                li{
                    &:not(:last-child){
                        padding-right: 25px;
                    }
                    a{
                        @include typography(18px, 28px);
                        text-transform: uppercase;
                        color: $nobel-color;
                        &.active{
                            color: $primary-color;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
.tab-content{
    .description-body{
        padding-bottom: 45px;
        text-align: center;
    }
    .information-body{
        padding-bottom: 30px
    }
    .review-body{
        > h2{
            &.heading{
                @include typography(24px, 28.8px, 400);
                padding-bottom: 25px;
            }
        }
        ul{
            &.user-info{
                display: flex;
                justify-content: flex-start;
                padding-bottom: 30px;
                @include breakpoint(max-xs_device){
                    flex-direction: column;
                }
                li{
                    &.user-avatar{
                        img{
                            border-radius: 100%;
                        }
                    }
                    &.user-comment{
                        padding-left: 30px;
                        @include breakpoint(max-xs_device){
                            padding-top: 20px;
                            padding-left: 0;
                        }
                        .meta{
                            strong{
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
        .user-feedback{
            h3{
                &.heading{
                    @include typography(30px, 48px, 400);
                }
            }
            p{
                &.short-desc{
                    padding-bottom: 15px;
                }
            }
            form{
                &.pd-feedback_form{
                    padding-top: 15px;
                    padding-bottom: 50px;
                    .form-field{
                        padding-bottom: 20px;
                        label{
                            &.label-field{
                                padding-bottom: 10px;
                            }
                        }
                        textarea{
                            &.textarea-field{
                                height: 220px;
                                width: 100%;
                                padding: 25px;
                            }
                        }
                        input{
                            &.input-field{
                                width: 100%;
                                height: 50px;
                                padding: 0 15px;
                            }
                        }
                        &.form-group_field{
                            display: flex;
                            justify-content: space-between;
                            @include breakpoint(max-sm_device){
                                flex-direction: column;
                            }
                            .group-input{
                                width: 100%;
                                &[class*="group-input"]{
                                    &:not(:last-child){
                                        @include breakpoint(max-sm_device){
                                            padding-bottom: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .form-btn_wrap{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
}

/* ---Product Detail Meta--- */
.product-detail_meta{
    .pd-meta_nav{
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: center;
        padding: 15px 0;
        @include breakpoint(max-xxs_device){
            flex-direction: column;
        }
        .sku-wrapper{
            padding-right: 30px;
            span{
                strong{
                    color: $secondary-color;
                    font-weight: 400;
                }
            }
        }
        .posted-in{
            span{
                strong{
                    color: $secondary-color;
                    font-weight: 400;
                }
                a{
                    text-transform: lowercase;
                }
            }
        }
    }
}

/* ---Related Product Area--- */
.related-ptoduct_area{
    .product-item{
        padding-bottom: 0;
    }
}