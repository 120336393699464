/*----------------------------------------*/
/*  35 - Section - Header
/*----------------------------------------*/

/* ---Main Header--- */
.main-header{
    .main-nav{
        > ul{
            > li{
                display: inline-block;
                position: relative;
                &:not(:last-child){
                    padding-right: 30px;
                }
                > ul{
                    &.baroque-dropdown{
                        background-color: $white-color;
                        @extend %basetransition;
                        top: calc(100% + 50px);
                        position: absolute;
                        padding: 30px;
                        width: 230px;
                        opacity: 0;
                        visibility: hidden;
                        z-index: 1;
                        li {
                            @extend %basetransition;
                            &:not(:last-child){
                                padding-bottom: 10px;
                            }
                            a {
                                display: block;
                                font-size: 14px;
                                i{
                                    font-size: 11px;
                                    margin-right: 5px;
                                }
                                span{
                                    &.submenu-indicate{
                                        position: absolute;
                                        top: 50%;
                                        right: 20px;
                                        transform: translateY(-50%);
                                        font-size: 11px;
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                        li{
                            &.submenu-holder{
                                position: relative;
                                ul{
                                    &.baroque-submenu{
                                        background-color: $white-color;
                                        width: 230px;
                                        padding: 10px 20px;
                                        top: 0;
                                        left: auto;
                                        right: calc(100% + 40px);
                                        position: absolute;
                                        @extend %basetransition;
                                        opacity: 0;
                                        visibility: hidden;
                                        > li{
                                            > a{
                                                display: block;
                                                i{
                                                    font-size: 11px;
                                                    vertical-align: middle;
                                                    margin-right: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover{
                                    > ul{
                                        &.baroque-submenu{
                                            right: 100%;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.megamenu-holder{
                    .ba-megamenu_wrap{
                        background-color: $white-color;
                        position: absolute;
                        padding: 30px;
                        width: 345px;
                        top: calc(100% + 30px);
                        left: 0;
                        z-index: 1;
                        opacity: 0;
                        visibility: hidden;
                        @extend %basetransition;
                        > ul{
                            &.ba-megamenu{
                                display: flex;
                                justify-content: space-between;
                                > li{
                                    width: 50%;
                                    span{
                                        &.title{
                                            @include typography(16px, 16px, 600);
                                            color: $secondary-color;
                                            padding-bottom: 20px;
                                            display: block;
                                        }
                                    }
                                    a{
                                        @include typography(14px, 14px);
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        .ba-megamenu_wrap{
                            top: 100%;
                            opacity: 1;
                            visibility: visible;;
                        }
                    }
                }
                > a{
                    display: block;
                    padding: 45px 0;
                    color: inherit;
                }
                &:hover{
                    ul{
                        &.baroque-dropdown {
                            top: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

/* ---Transparent Header--- */
.transparent-header{
    @include breakpoint(min-md_device){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
    }
}
.vs-transparent_header,
.px-transparent_header,
.zoom-transparent_header,
.pp-transparent_header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}
// End Here

/* ---Fixed Header--- */
.fixed-header {
    @include breakpoint(min-lg_device){
        background: $white-color;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
    }
}

/* ---Header Left Area--- */
.header-left_area{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-intro{
        max-width: 52%;
        @include breakpoint(min-xxl_device){
            max-width: 48%;
        }
        span{
            color: $secondary-color;
        }
    }
}

/* ---Header Right Area--- */
.header-right_area{
    ul{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        li{
            &:not(:last-child){
                padding-right: 30px;
            }
            a{
                padding-left: 10px;
                @include typography(24px, 34px, 600);
                color: $secondary-color;
                span{
                    @include typography(16px, 1.6);
                    padding-right: 10px;
                    i{
                        font-size: 24px;
                    }
                }
            }
            &.minicart-wrap{
                position: relative;
                padding-right: 40px;
                span{
                    &.cart-counter{
                        color: $white-color;
                        border-radius: 100%;
                        font-size: 12px;
                        width: 20px;
                        height: 20px;
                        line-height: 19px;
                        text-align: center;
                        position: absolute;
                        bottom: -5px;
                        right: 20px;
                    }
                }
            }
            &.mobile-menu_wrap{
                a{
                    &.mobile-menu_btn{
                        i{
                            font-size: 32px;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}

/* ---Sticky Header--- */
.header-sticky{
    @include transition(all 300ms ease-in 0s);
    &.main-header{
        display: none;
    }
    &.sticky {
        @include breakpoint(min-sm_device){
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
            background-color: $white-color;
            animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
            position: fixed;
            z-index: 99;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
        }
    }
}

/* ---Main Header Area Two--- */
.main-header_area-2{
    .main-header{
        @include breakpoint(max-md_device){
            padding: 30px 0;
        }
        .main-menu{
            display: flex;
            justify-content: space-between;
        }
    }
}

/* ---Main Header Area Three--- */
.main-header_area-3{
    .main-header{
        padding-top: 100px;
        padding-bottom: 0;
        @include breakpoint(max-sm_device){
            padding-top: 30px;
        }
        .header-logo{
            text-align: center;
            @include breakpoint(max-sm_device){
                text-align: left;
            }
        }
    }
}

/* ---Main Header Area Four--- */
.main-header_area-4{
    .main-header{
        @include breakpoint(max-md_device){
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .main-menu{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .header-info{
                a{
                    &.email-address{
                        text-transform: none;
                        color: $white-color;
                        &:hover{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

/* ---Main Header Area Five--- */
.main-header_area-5{
    position: relative;
    z-index: 3;
    .main-header{
        .main-header_nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

/* ---Main Header Area Six--- */
.main-header_area-6{
    .main-header{
        @include breakpoint(max-md_device){
            padding-top: 30px;
        }
        .main-menu{
            display: flex;
            justify-content: space-between;
        }
    }
}

/* ---Main Header Area Seven--- */
.main-header_area-7{
    .main-header{
        &.header-sticky{
            display: block;
        }
        .main-header_nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include breakpoint(max-md_device){
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .main-menu{
                display: flex;
                justify-content: space-between;
                ul{
                    &.onepage-nav{
                        li{
                            a{
                                @include typography(14px, 18px, 600);
                                text-transform: uppercase;
                            }
                            &.active{
                                a{
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
                .language-menu{
                    padding: 43px 0 0 35px;
                    @include breakpoint(max-md_device){
                        padding: 0;
                    }
                    ul{
                        li{
                            display: inline-block;
                            &:not(:last-child){
                                padding-right: 10px;
                            }
                            a{
                                @include typography(14px, 22.4px, 600);
                                text-transform: uppercase;
                                color: $secondary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Main header Area Eight--- */
.main-header_area-8{
    .main-header{
        padding: 40px 0;
        .main-header_nav{
            display: flex;
            justify-content: space-between;
        }
    }
}