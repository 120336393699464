/*----------------------------------------*/
/*  36 - Section - Footer
/*----------------------------------------*/
.footer-sticky{
	@include breakpoint(min-xl_device){
		position: static;
	}
	&.sticky{
		@include breakpoint(min-xl_device){
			position: fixed;
			bottom: 20px;
			left: 20px;
			right: 0;
			width: calc(100% - 40px);
			z-index: -1;
		}
	}
}
.footer-widgets_area{
	@include breakpoint(max-lg_device){
		padding-bottom: 35px;
	}
	.copyright{
		@include breakpoint(lg-device){
			max-width: 50%;
		}
	}
	.footer-widget{
		li {
			display: flex;
			font-weight: 600;
			&:not(:last-child){
				padding-bottom: 5px;
			}
			span {
				&.desc {
					color: $secondary-color;
					padding-left: 25px;
				}
			}
			a{
				color: $secondary-color;
			}
			&.language-menu{
				display: inline-block;
				position: relative;
				a{
					text-transform: uppercase;
					i{
						vertical-align: text-top;
						font-size: 16px;
						padding-left: 5px;
					}
				}
				ul{
					&.language-dropdown{
						background-color: $white-smoke_color;
						position: absolute;
						opacity: 0;
						visibility: hidden;
						top: calc(100% + 25px);
						left: 0;
						@extend %basetransition;
						z-index: 1;
					}
				}
				&:hover{
					ul{
						&.language-dropdown{
							top: 100%;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
		&.social-link{
			display: flex;
			justify-content: flex-end;
			@include breakpoint(max-xs_device){
				justify-content: flex-start;
				padding-top: 40px;
			}
			li{
				padding-bottom: 0;
			}
		}
	}
}

/* ---Transparent Footer--- */
.transparent-footer{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

/* ---Footer Area Two--- */
.footer-area-2{
	.footer-widgets_area{
		&.with-border{
			border-top: 1px solid $border-color;
			display: flex;
			justify-content: space-between;;
			padding-top: 50px;
			padding-bottom: 55px;
			@include breakpoint(max-lg_device){
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
		.copyright{
			@include breakpoint(max-lg_device){
				padding-bottom: 10px;
			}
			span{
				text-transform: none;
				font-weight: 500;
				font-size: 15px;
				a{
					em{
						color: $text-color;
						font-style: normal;
					}
				}
			}
		}
		.contact-info{
			@include breakpoint(max-lg_device){
				padding-bottom: 15px;
			}
			ul{
				display: flex;
				@include breakpoint(max-sm_device){
					flex-direction: column;
					align-items: center;
				}
				li{
					position: relative;
					&:not(:last-child){
						padding-right: 20px;
						&:before{
							@include breakpoint(min-md_device){
								content: '/';
								position: absolute;
								top: 0;
								right: 5px;
							}
						}
					}
					a,
					span{
						text-transform: none;
						font-weight: 500;
						font-size: 15px;
						@include breakpoint(max-xxs_device){
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

/* ---Footer Area Three--- */
.footer-area-3{
	.footer-widgets_area{
		display: flex;
		justify-content: space-between;
		@include breakpoint(max-sm_device){
			flex-direction: column;
			align-items: center;
			padding-bottom: 25px;
			.copyright{
				padding-bottom: 25px;
			}
			.header-logo{
				padding-bottom: 25px;
			}
		}
	}
}

/* ---Footer Area Four--- */
.footer-area-4{
	.footer-widgets_area{
		display: flex;
		justify-content: space-between;
		@include breakpoint(max-sm_device){
			flex-direction: column;
			align-items: center;
			.copyright{
				padding-bottom: 20px;
			}
		}
	}
}

/* ---Footer Area Five--- */
.footer-area-5{
	.footer-widgets_area{
		display: flex;
		justify-content: space-between;
		padding-bottom: 0;
		@include breakpoint(max-xs_device){
			flex-direction: column;
			align-items: center;
		}
		.logo{
			padding-bottom: 20px;
		}
	}
}

/* ---Footer Area Six--- */
.footer-area-6{
	.footer-widgets_area{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		@include breakpoint(max-xxs_device){
			flex-direction: column;
			align-items: center;
		}
		.copyright{
			@include breakpoint(max-xxs_device){
				padding-bottom: 15px;
			}
		}
		.social-link{
			h5{
				&.title{
					@include breakpoint(max-xxs_device){
						font-size: 18px;
					}
				}
			}
		}
	}
}