/*----------------------------------------*/
/*  10 - Components - Navigation
/*----------------------------------------*/
/* ---Navigation and pagination wrap--- */
.nav-pagination_wrap{
	.swiper-pagination{
		display: none;
		@include breakpoint(max-md_device){
			display: block;
		}
	}
	.swiper-button-next,
	.swiper-button-prev{
		display: flex;
		@include breakpoint(max-md_device){
			display: none;
		}
	}
	.swiper-button-next{
		right: 60px;
		@include breakpoint(max-lg_device){
			right: 20px;
		}
	}
	.swiper-button-prev{
		left: 60px;
		@include breakpoint(max-lg_device){
			left: 20px;
		}
	}
}
/* ---Swiper Arrow--- */
.swiper-button-next,
.swiper-button-prev{
	background-color: rgba(0, 0, 0, 0.5);
	color: $white-color;
	font-size: 14px;
	width: 70px;
	height: 70px;
	line-height: 70px;
	@extend %basetransition;
	&:after{
		font-size: 25px;
	}
	&:hover{
		background-color: $secondary-color;
	}
}

/* ---Verticle Arrows--- */
.verticle-arrow{
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 1;
	.swiper-button-next{
		display: block;
	}
}

/* ---Swiper Arrow Wrap--- */
.swiper-arrow_wrap{
	&.bottom-left{
		position: absolute;
		bottom: 20px;
		left: 0;
		.swiper-button-next{
			left: 70px;
		}
		.swiper-button-prev{
			left: 0;
		}
	}
	&.without-background{
		.swiper-button-next,
		.swiper-button-prev{
			background-color: transparent;
			color: $very-light-grey_color;
			width: auto;
			height: auto;
			&:after{
				font-size: 40px;
			}
			&:hover{
				color: $secondary-color;
			}
		}
	}
}

/* ---Offcanvas--- */
.mobile-menu_wrapper,
.offcanvas-minicart_wrapper {
	.offcanvas-body {
		background-color: $white-color;
		position: fixed;
		top: 0;
		left: auto;
		right: -660px;
		width: 660px;
		z-index: 9999;
		visibility: hidden;
		opacity: 0;
		overflow: visible;
		@include transition(all 500ms ease);
		height: 100%;
		overflow-y: auto;
		margin: 0 -20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include breakpoint(max-sm_device){
			width: 100%;
		}
		.inner-body{
			padding: 82.5px 100px 0;
			@include breakpoint(max-sm_device){
				width: 100%;
				padding: 82.5px 65px 0 45px;
			}
			.offcanvas-top{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 40px;
				.offcanvas-language{
					ul{
						display: flex;
						li{
							&:not(:last-child){
								padding-right: 20px;
							}
							a{
								@include typography(14px, 22.4px, 500);
							}
						}
					}
				}
				.btn-close {
					font-size: 24px;
				}
			}
			.offcanvas-menu_area{
				.offcanvas-navigation {
					overflow-y: auto;
					height: 263px;
					.mobile-menu {
						& > li {
							height: 100%;
							& > a {
								span {
									position: relative;
									display: flex;
									justify-content: space-between;
									align-items: center;
									@extend %basetransition;
								}
							}
						}
						li {
							position: relative;
							&:not(:last-child){
								padding-bottom: 20px;
							}
							& > .menu-expand {
								position: absolute;
								right: 0;
								top: 2px;
								width: 50px;
								height: 100%;
								line-height: 40px;
								cursor: pointer;
								text-align: center;
								margin-right: 4px;
								@extend %basetransition;
								font-size: 20px;
							}
							a {
								@include typography(36px, 36px, 600);
								display: block;
							}
							.sub-menu {
								padding: 20px 0 0;
								li {
									a {
										@include typography(18px, 18px);
										span{
											display: flex;
											justify-content: space-between;
											align-items: center;
										}
									}
								}
							}
							&.menu-item-has-children{
								&.menu-open{
									ul{
										&.inner-sub{
											li{
												padding-left: 15px;
												a{
													@include typography(16px, 16px);
												}
											}
										}
									}
								}
							}
						}
					}
					/* ---Light Offcanvas--- */
					&.light-offcanvas{
						.mobile-menu {
							& > li {
								& > a {
									span {
										&:hover{
											color: $secondary-color;
										}
									}
								}
								&.active{
									& > a {
										span {
											color: $secondary-color;
										}
									}
								}
							}
							li {
								.sub-menu {
									li {
										&:hover{
											> a{
												color: $secondary-color;
											}
										}
									}
								}
								&.menu-open {
									> a{
										color: $secondary-color;
									}
								}
							}
						}
					}
					/* ---Dark Offcanvas--- */
					&.dark-offcanvas{
						.mobile-menu {
							& > li {
								& > a {
									span {
										&:hover{
											color: $white-color;
										}
									}
								}
								&.active{
									& > a {
										span {
											color: $white-color;
										}
									}
								}
							}
							li {
								.sub-menu {
									li {
										&:hover{
											> a{
												color: $white-color;
											}
										}
									}
								}
								&.menu-open {
									> a{
										color: $white-color;
									}
								}
							}
						}
					}
				}
			}
			.offcanvas-bottom{
				padding-top: 45px;
				.copyright{
					padding-top: 15px;
					max-width: 50%;
					padding-bottom: 87.5px;
					@include breakpoint(max-xxs_device){
						max-width: 100%;
					}
				}
			}
		}
	}
	&.open {
		.offcanvas-body {
			visibility: visible;
			left: auto;
			right: 0;
			opacity: 1;
			z-index: 999;
		}
	}
}

/* ---Offcanvas Minicart Area--- */
.offcanvas-minicart_wrapper {
	.offcanvas-body {
		box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
		padding: 60px 60px 0;
		width: 450px;
		@include breakpoint(max-xs_device){
			width: 100%;
			padding: 60px 55px 0 30px;
		}
		.minicart-content {
			.minicart-heading {
				display: flex;
				justify-content: space-between;
				padding-bottom: 25px;
				h4 {
					font-size: 18px;
					line-height: 21.6px;
				}
			}
			.minicart-list {
				max-height: 345px;
				position: relative;
				overflow: auto;
				li {
					&:not(:last-child){
						border-bottom: 1px solid $border-color;
						padding-bottom: 30px;
						margin-bottom: 30px;
					}
					&.minicart-product {
						display: flex;
						@include breakpoint(max-xxs_device){
							flex-direction: column;
						}
						a {
							&.product-item_remove {
								i{
									position: absolute;
									font-size: 12px;
									top: auto;
									right: 15px;
								}
							}
						}
						.product-item_img {
							border: 1px solid $border-color;
							flex-basis: 70px;
							max-width: 70px;
							@extend %basetransition;
						}
						.product-item_content {
							flex-basis: calc(100% - 70px);
							max-width: calc(100% - 70px);
							padding-left: 20px;
							padding-right: 10px;
							@include breakpoint(max-xxs_device){
								padding-left: 0;
								padding-right: 0;
								padding-top: 15px;
							}
							a {
								&.product-item_title {
									line-height: 1.4;
									font-size: 16px;
									width: 90%;
									display: block;
									@include breakpoint(max-xxs_device){
										width: 100%;
									}
								}
							}
							.product-item_quantity {
								display: block;
								padding-top: 10px;
								font-size: 13px;
								line-height: 24.7px;
								color: $charcoal-color;
							}
						}
					}
				}
			}
		}
		.minicart-item_total {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 25px 0 30px;
		}
		.group-btn_wrap {
			padding-bottom: 60px;
		}
	}
}

/* ---Demo Switcher Area--- */
.demo-switcher_area {
    position: fixed;
    top: 170px;
	right: 0;
	z-index: 2;
	ul{
		li{
			a{
				&.demo-switcher_btn{
					background-color: rgba(0,0,0,.8);
					color: $white-color;
					width: 65px;
					line-height: 1;
					padding: 17px 0;
					text-align: center;
					display: block;
					font-weight: 600;
					font-size: 15px;
					img{
						width: 100%;
						object-fit: cover;
					}
					&:hover{
						background-color: $secondary-color;
					}
				}

			}
		}
	}
}
.demo-switcher_wrapper {
	display: flex;
	justify-content: center;
	.demo-switcher_body {
		background-color: $white-color;
		padding: 30px;
		position: fixed;
		top: 0;
		left: 90px;
		width: calc(100% - 180px);
		height: 100%;
		overflow-y: auto;
		z-index: 9999;
		opacity: 0;
		visibility: hidden;
		@include transition(all 500ms ease);
		@include breakpoint(max-sm_device){
			width: calc(100% - 60px);
			left: 30px;
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			li{
				flex: 0 0 16.666%;
				max-width: 16.666%;
				padding: 10px;
				@extend %basetransition;
				@include breakpoint(max-lg_device){
					flex: 0 0 25%;
					max-width: 25%;
				}
				@include breakpoint(max-md_device){
					flex: 0 0 33.333333%;
					max-width: 33.333333%;
				}
				@include breakpoint(max-sm_device){
					flex: 0 0 50%;
					max-width: 50%;
				}
				@include breakpoint(max-xxs_device){
					flex: 0 0 100%;
					max-width: 100%;
				}
				a{
					img{
						@extend %basetransition;
						box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
						width: 100%;
						object-fit: cover;
					}
					span{
						display: block;
						text-align: center;
						padding-top: 25px;
					}
				}
				&:hover{
					a{
						img{
							box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .2);
							transform: translateY(-2px);
						}
					}
				}
			}
		}
	}
	&.open {
		.demo-switcher_body {
			opacity: 1;
			visibility: visible;
		}
	}
}