/*----------------------------------------*/
/*  03 - Utilities - Helper
/*----------------------------------------*/
/* ---Scroll Behavior--- */
html {
	scroll-behavior: smooth;
}

/* ---Custom Container--- */
.custom-container{
	&.custom-pl-60{
		padding-left: 60px;
		@include breakpoint(max-lg_device){
			padding-left: 20px;
		}
	}
	&.custom-pr-60{
		padding-right: 60px;
		@include breakpoint(max-lg_device){
			padding-right: 20px;
		}
	}
	&.max-lg-p-0{
		@include breakpoint(max-lg_device){
			padding: 0;
		}
	}
	&.max-sm-p-0{
		@include breakpoint(max-sm_device){
			padding: 0;
		}
	}
}

/* ---Main Wrapper--- */
.main-wrapper{
	.container-fluid{
		padding-left: 80px;
		padding-right: 80px;
		@include breakpoint(max-lg_device){
			padding-left: 40px;
			padding-right: 40px;
		}
		@include breakpoint(max-sm_device){
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	&.box-layout{
		.container-fluid{
			padding-left: 60px;
			padding-right: 60px;
			@include breakpoint(max-lg_device){
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&.masonry-wrapper {
		.container-fluid {
			@include breakpoint(max-lg_device) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}

/* ---Box Layout--- */
.box-layout{
	background-color: $white-color;
	width: calc(100% - 40px);
	position: relative;
	margin: 20px auto;
	&.with-footer_sticky{
		@include breakpoint(min-xl_device){
			margin: 20px auto 350px;
		}
	}
}

/* ---Home Vertical Slider Wrapper--- */
.home-vs_wrapper{
	background-image: url('../images/home-background/vslide-bg-light.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
}

/* ---Dark Nav--- */
.lightNav{
	.light-header{
		display: block;
	}
	.dark-header{
        display: none;
	}
}
.darkNav{
	.dark-header{
        display: block;
	}
	.light-header{
		display: none;
	}
}

/* ---Baroque Home Video--- */
.baroque-video_area{
	position: relative;
	margin-left: -20px;
	margin-right: -20px;
	video{
		width: 100%;
		height: 100vh;
		object-fit: cover;
		@include breakpoint(max-sm_device){
			height: 60vh;
		}
	}
	.video-content{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		@include breakpoint(max-sm_device){
			width: 95%;
		}
		h1{
			&.heading{
				@include typography(100px, 100px);
				color: $white-color;
				padding-bottom: 20px;
				@include breakpoint(max-sm_device){
					font-size: 40px;
					line-height: 1.2;
				}
			}
		}
		p{
			&.short-desc{
				@include typography(26px, 41.6px);
				color: $white-color;
				padding-bottom: 20px;
				@include breakpoint(max-sm_device){
					font-size: 16px;
					line-height: 31.6px;
				}
			}
		}
		#playpause{
			display: inline-flex;
			justify-content: center;
			i{
				background-color: rgba(0, 0, 0, .3);
				color: $white-color;
				border-radius: 4px;
				cursor: pointer;
				width: 50px;
				height: 50px;
				line-height: 48px;
				text-align: center;
				display: inline-block;
				opacity: 0;
				visibility: hidden;
				@extend %basetransition;
				font-size: 30px;
				display: block;
			}
			&.playing{
				i{
					&:before{
						content: '\60';
					}
				}
			}
		}
		.btn-wrap{
			padding-top: 90px;
			a{
				text-transform: uppercase;
				color: $white-color;
				font-weight: 600;
				display: block;
				span{
					padding-bottom: 10px;
					display: block;
				}
			}
		}
	}
	&:hover{
		.video-content{
			#playpause{
				i{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

/* ---Section Space--- */

/* --Padding Top-- */
.pt-100{
	padding-top: 100px;
}
.pt-95{
	padding-top: 95px;
}
.pt-90{
	padding-top: 90px;
}
.pt-85{
	padding-top: 85px;
}
.pt-80{
	padding-top: 80px;
}
.pt-75{
	padding-top: 75px;
}
.pt-70{
	padding-top: 70px;
}
.pt-65{
	padding-top: 65px;
}
.pt-60{
	padding-top: 60px;
}
.pt-55{
	padding-top: 55px;
}
.pt-50{
	padding-top: 50px;
}
.pt-45{
	padding-top: 45px;
}
.pt-40{
	padding-top: 40px;
}
.pt-35{
	padding-top: 35px;
}
.pt-30{
	padding-top: 30px;
}
.pt-25{
	padding-top: 25px;
}
.pt-20{
	padding-top: 20px;
}
.pt-15{
	padding-top: 15px;
}
.pt-10{
	padding-top: 10px;
}

/* --Padding Bottom-- */
.pb-100{
	padding-bottom: 100px;
}
.pb-95{
	padding-bottom: 95px;
}
.pb-90{
	padding-bottom: 90px;
}
.pb-85{
	padding-bottom: 85px;
}
.pb-80{
	padding-bottom: 80px;
}
.pb-75{
	padding-bottom: 75px;
}
.pb-70{
	padding-bottom: 70px;
}
.pb-65{
	padding-bottom: 65px;
}
.pb-60{
	padding-bottom: 60px;
}
.pb-55{
	padding-bottom: 55px;
}
.pb-50{
	padding-bottom: 50px;
}
.pb-45{
	padding-bottom: 45px;
}
.pb-40{
	padding-bottom: 40px;
}
.pb-35{
	padding-bottom: 35px;
}
.pb-30{
	padding-bottom: 30px;
}
.pb-25{
	padding-bottom: 25px;
}
.pb-20{
	padding-bottom: 20px;
}
.pb-15{
	padding-bottom: 15px;
}
.pb-10{
	padding-bottom: 10px;
}

/* ---Base Transition--- */
%basetransition {
	@include transition(all .3s ease-in);
}

/* ---Main Page Heading--- */
.main-page_heading{
	max-width: 56%;
	margin: 0 auto;
	@include typography(40px, 48px);
	letter-spacing: -1px;
	@include breakpoint(max-md_device){
		max-width: 85%;
	}
	@include breakpoint(max-sm_device){
		max-width: 100%;
		font-size: 25px;
		line-height: 1.4;
	}
}
/* ---Main Page Decription--- */
.main-page_desc {
	text-align: center;
	max-width: 76%;
	margin: 0 auto;
	@include breakpoint(max-sm_device){
		max-width: 100%;
	}
}
.main-page_desc-2{
	@include typography(18px, 28.8px);
	max-width: 40%;
	margin: 0 auto;
	@include breakpoint(max-lg_device){
		max-width: 60%;
	}
	@include breakpoint(max-lg_device){
		max-width: 100%;
	}
}

/* ---Slider Nav Count--- */
.slide-nav_count{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 150px;
	span{
		display: block;
		&.data-count{
			position: relative;
			&:before{
				content: attr(data-count);
				@include typography(51px, 51px, 600);
				@include breakpoint(max-lg_device){
					font-size: 40px;
					line-height: 1.2;
				}
			}
		}
		sup{
			@include typography(21px, 21px);
			vertical-align: super;
		}
	}
}

/* ---Sticker---*/
.sticker {
	background-color: $coral-color;
	color: $white-color;
	position: absolute;
	padding: 0 15px;
	height: 25px;
	@include typography(12px, 25px, 500);
	border-radius: 3px;
	text-align: center;
	top: 20px;
	right: 20px;
	cursor: pointer;
	&.radical-red_color{
		background-color: #ff1d5d;
	}
	&.secodary-color{
		background-color: $secondary-color;
	}
}

/* ---Tooltip--- */
.tooltip{
	z-index: 10;
}
.tooltip-inner {
	padding: 0 8px;
	height: 25px;
	line-height: 22px;
	text-align: center;
	border-radius: 3px;
	font-size: 12px;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	content: "";
	border-width: 5px 5px 0;
}

.tooltip-inner {
	background-color: $secondary-color;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	border-top-color: $secondary-color;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: $secondary-color;
}
.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
	border-right-color: $secondary-color;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-left-color: $secondary-color;
}

/* ---Spacing Between Slide Item---*/
.slick-gutter{
	&-20{
		.slick-list {
			margin: 0 -10px;
			.slick-slide {
				padding: 0 10px;
			}
		}
	}
	&-30{
		.slick-list {
			margin: 0 -15px;
			.slick-slide {
				padding: 0 15px;
			}
		}
	}
	&-60{
		.slick-list {
			margin: 0 -30px;
			.slick-slide {
				padding: 0 30px;
			}
		}
	}
}

/* ---Spacing Between Two Row---*/
.baroque-element-carousel {
	.slick-slide {
		div {
			&:not(:last-child) {
				.project-item,
				.single-item {
					padding-bottom: 55px;
				}
			}
		}
	}
	&.row-space-20{
		.slick-slide {
			div {
				&:not(:last-child) {
					.project-item {
						padding-bottom: 20px;
					}
				}
			}
		}
	}
	&.row-space-10{
		.slick-slide {
			div {
				&:not(:last-child) {
					.project-item {
						padding-bottom: 100px;
					}
				}
			}
		}
	}
}

/* ---Grid Item--- */
.masonry-grid{
	margin-left: -10px;
	margin-right: -10px;
	.grid-item {
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 20px;
		.single-img{
			height: 100%;
			.inner-img{
				height: 100%;
				img{
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
		}
		&.grid-item--height2{
			height: 500px;
		}
		&.grid-item--height3{
			height: 310px;
		}
		&.grid-item--height4{
			height: 170px;
		}
	}
}

/* ---Entry Banner--- */
.entry-banner,
.about-bg{
	background-image: url('../images/project/bg/1-1880x1080.jpg');
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;
	height: 445px;
	position: relative;
	&.rectangle-house-bg{
		background-image: url('../images/project/bg/1-1880x1413.jpg');
		height: 660px;
		&:before{
			background-color: rgba(0,0,0,.35);
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
		.inner-text{
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			span{
				@include typography(24px, 38.4px);
				padding-bottom: 35px;
				color: $white-color;
				display: block;
				@include breakpoint(max-sm_device){
					font-size: 20px;
					line-height: 34.4px;
				}
			}
			h2{
				&.heading{
					@include typography(60px, 72px);
					color: $white-color;
					@include breakpoint(max-sm_device){
						font-size: 28px;
						line-height: 30px;
					}
				}
			}
		}
		&.without-parallax{
			background-image: url('../images/project/bg/1-1170x613.jpg');
			height: 613px;
			@include breakpoint(max-sm_device){
				height: 320px;
			}
		}
	}
	&.about-bg{
		background-image: url('../images/about/bg/1-1880x820.jpg');
		height: 540px;
	}
	&.product-bg{
		background-image: url('../images/product/bg/1-1880x1225.jpg');
		@include breakpoint(max-sm_device){
			height: 350px;
		}
	}
}

/* ---Entry Gallery--- */
.entry-gallery{
	.single-img{
		&.entry-bg-1,
		&.entry-bg-2,
		&.entry-bg-3{
			background-image: url('../images/entry-gallery/bg/1-1880x680.jpg');
			background-repeat: no-repeat;
			background-position: bottom;
			background-size: cover;
			height: 680px;
			@include breakpoint(max-sm_device){
				height: 320px;
			}
			&.entry-bg-2{
				background-image: url('../images/entry-gallery/bg/2-1880x680.jpg');
			}
			&.entry-bg-3{
				background-image: url('../images/entry-gallery/bg/3-1880x680.jpg');
			}
		}
	}
}

/* ---Blockquote--- */
blockquote {
	font-size: 24px;
	padding: 0 0 30px 120px;
	margin: 0;
	position: relative;
	@include breakpoint(max-xxs_device){
		padding: 0 0 30px 0;
	}
    &:before {
        color: $secondary-color;
        content: '\7b';
        font-family: ElegantIcons;
        font-size: 89px;
        position: absolute;
        top: -6px;
        left: -24px;
        line-height: 1;
		z-index: 9;
		@include breakpoint(max-xxs_device){
			position: relative;
		}
    }
    &:after {
        content: '';
        width: 48px;
        height: 48px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #ced08d;
        position: absolute;
        left: 20px;
        top: 0;
    }
    p{
        @include typography(24px, 36px);
        letter-spacing: -0.2px;
        color: $secondary-color;
    }
    .user-info{
        span{
            @include typography(14px, 21px);
            letter-spacing: -0.2px;
            &.user-name{
                color: $secondary-color;
                font-weight: 600;
            }
            &.cite{
                color: $nobel-color;
            }
        }
    }
}

/* ---Tab Content & Pane Fix--- */
.tab-content {
	width: 100%;
	.tab-pane {
		display: block;
		height: 0;
		max-width: 100%;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		&.active {
			height: auto;
			visibility: visible;
			opacity: 1;
			overflow: visible;
			@extend %basetransition;
		}
	}
}

/*-- Scroll To Top --*/
.scroll-to-top{
    background-color: $primary-color;
    color: $white-color;
    position: fixed;
    right: 20px;
	bottom: 20px;
	width: 35px;
	height: 35px;
	line-height: 35px;
    z-index: 999;
    font-size: 20px;
    overflow: hidden;
    display: block;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	border-radius: 5px;
	animation:  barSlideInDown 1s normal;
	@include transition(all 300ms ease);
	&.show{
		opacity: 1;
		visibility: visible;
		animation: barSlideInUp 1s normal;
	}
	i{
		@extend %basetransition;
		color: $white-color;
		font-size: 16px;
		display: inline-block;
	}
}
.scroll-to-top-2{
    position: fixed;
    right: 20px;
	bottom: 20px;
    z-index: 999;
    overflow: hidden;
    display: block;
	opacity: 0;
	visibility: hidden;
	animation:  barSlideInDown 1s normal;
	@include transition(all 300ms ease);
	&.show{
		opacity: 1;
		visibility: visible;
		animation: barSlideInUp 1s normal;
	}
	i{
		color: $secondary-color;
		font-size: 48px;
	}
}

/* ---Rating Box--- */
.rating-box {
	ul {
		li {
			display: inline-block;
			i {
				color: $gamboge-color;
				font-size: 12px;
			}
			&.silver-color{
				i{
					color: $silver-color;
				}
			}
		}
	}
}

/* ---Price Box--- */
.price-box{
	span{
		&.new-price{
			@include typography(18px, 18px);
			&.radical-red_color{
				color: $radical-red_color;
			}
			&.primary-color{
				color: $primary-color;
			}
		}
		&.old-price{
			@include typography(14px, 14px);
			text-decoration: line-through;
			color: $nobel-color;
		}
	}
}


/* ---Entry Meta--- */
.entry-meta{
	border-top: 1px solid $border-color;
	padding: 20px 0;
	ul{
		display: flex;
		justify-content: center;
		li{
			&:not(:last-child){
				padding-right: 25px;
				@include breakpoint(max-lg_device){
					padding-right: 15px;
				}
			}
			a{
				@include typography(14px, 24.3px);
				color: $atlantis-color;
				&:hover{
					color: $primary-color;
				}
				i{
					vertical-align: middle;
					color: $nobel-color;
					margin-right: 3px;
				}
				span{
					@extend %basetransition;
					color: $nobel-color;
					&:hover{
						color: $primary-color;
					}
				}
			}
		}
	}
}

/* ---Pagination--- */
.pagination-area {
	padding-bottom: 90px;
	ul{
		display: flex;
		justify-content: space-between;
		li{
			a{
				@include typography(24px, 38px, 600);
				text-transform: uppercase;
				color: $secondary-color;
				@include breakpoint(max-xxs_device){
					font-size: 18px;
					line-height: 32px;
					text-transform: capitalize;
				}
				&:hover{
					color: inherit;
				}
			}
		}
	}
}

/* ---Pagination Two--- */
.paginatoin-area-2 {
	padding-top: 45px;
	&.with-border{
		border-bottom: 1px solid $border-color;
		padding-bottom: 100px;
	}
	&.position-center{
		display: flex;
		justify-content: center;
	}
	.pagination-box {
		li {
			display: inline-block;
			&:not(:last-child){
				margin-right: 5px;
			}
			> a {
				background-color: $white-smoke_color;
				color: $secondary-color;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				display: block;
				i{
					font-size: 12px;
				}
			}
			&.active{
				> a{
					cursor: not-allowed;
				}
			}
		}
	}
}

/* ---Tag--- */
.tags-list {
	display: flex;
	@include breakpoint(max-xxs_device){
		flex-direction: column;
	}
	span{
		text-transform: uppercase;
		color: $secondary-color;
		font-weight: 600;
		padding-right: 5px;
	}
	li {
		display: inline-block;
		a{
			text-transform: lowercase;
		}
	}
}

/* ---Quantity--- */
.quantity {
	.cart-plus-minus {
		position: relative;
		width: 76px;
		height: 55px;
		text-align: left;
		> .cart-plus-minus-box {
			border: 1px solid $border-color;
			height: 55px;
			text-align: center;
			width: 48px;
			background: $white-color;
		}
		> .qtybutton {
			border-bottom: 1px solid $border-color;
			border-right: 1px solid $border-color;
			border-top: 1px solid $border-color;
			cursor: pointer;
			position: absolute;
			text-align: center;
			@extend %basetransition;
			width: 28px;
			height: 28px;
			line-height: 28px;
			font-size: 10px;
			&.dec {
				bottom: 0;
				right: 0;
			}
			&.inc {
				border-bottom: none;
				top: 0;
				right: 0;
			}
		}
	}
}

/* ---Range Slider--- */
.price-filter {
	.ui-widget-content {
		background-color: $border-color;
		border: none;
		border-radius: 0;
		box-shadow: none;
		cursor: pointer;
		height: 10px;
		margin: 0 0 25px;
		width: 100%;
		border-top-left-radius: 25px;
		-moz-border-radius-topleft: 25px;
		border-top-right-radius: 25px;
		-moz-border-radius-topright: 25px;
		border-bottom-left-radius: 25px;
		-moz-border-radius-bottomleft: 25px;
		border-bottom-right-radius: 25px;
		-moz-border-radius-bottomright: 25px;
	}
	.ui-slider-range {
		background-color: $primary-color;
		border: none;
		box-shadow: none;
		height: 100%;
	}
	.ui-slider-handle {
		border: 4px solid $primary-color;
		background: $white-color;
		display: block;
		outline: none;
		width: 20px;
		height: 20px;
		line-height: 15px;
		margin: 0;
		text-align: center;
		top: 50%;
		border-radius: 100%;
		box-shadow: 0px 0px 6.65px .35px rgba(0, 0, 0, 0.15);
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%);
		cursor: e-resize;
		@extend %basetransition;
		&:last-child {
			-webkit-transform: translate(-100%, -50%);
			transform: translate(-100%, -50%);
		}
		&:hover {
			background-color: $primary-color;
		}
	}
	.price-slider-amount {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.filter-btn {
			border: 2px solid $text-color;
			width: 110px;
			height: 40px;
			line-height: 39px;
			@extend %basetransition;
			@include breakpoint(lg-device){
				width: 40px;
				font-size: 14px;
			}
			&:hover{
				background-color: $primary-color;
                border-color: $primary-color;
                color: $white-color;
			}
		}
		.label-input {
			label {
				font-size: 14px;
				margin: 0;
				text-transform: capitalize;
			}
			input {
				background: transparent none repeat scroll 0 0;
				border: medium none;
				box-shadow: none;
				font-size: 14px;
				height: inherit;
				padding-left: 5px;
				width: 99px;
			}
		}
	}
}

/* ---Counter Item--- */
.counter-item{
	padding-top: 85px;
	display: flex;
	@include breakpoint(max-xxs_device){
		flex-wrap: wrap;
	}
	li{
		width: 33.333%;
		@include breakpoint(max-xxs_device){
			width: 50%;
		}
		&:not(:last-child){
			padding-right: 90px;
			@include breakpoint(max-xxs_device){
				padding-bottom: 20px;
			}
		}
		span{
			&.count{
				@include typography(56px, 56px, 600);
				padding-bottom: 5px;
				display: block;
			}
			&.count-title{
				@include typography(14px, 16.8px);
				text-transform: uppercase;
			}
		}
	}
	&.with-four_item{
		li{
			width: 25%;
		}
	}
}

/* ---Countdown--- */
.countdown-wrap {
	.countdown{
		&.item-4{
			.countdown__item{
				display: inline-block;
				position: relative;
				text-align: center;
				margin-left: 40px;
				position: relative;
				@include breakpoint(max-xs_device){
					margin-left: 20px;
				}
				&:first-child{
					margin-left: 0;
				}
				span {
					text-transform: capitalize;
					line-height: 1;
					color: $white-color;
					&.countdown__time {
						font-size: 49px;
						@include breakpoint(max-xs_device){
							font-size: 25px;
						}
					}
					&.countdown__text {
						display: block;
						font-size: 18px;
						padding-top: 15px;
						@include breakpoint(max-xs_device){
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	&.white-text_color{
		span {
			color: $white-color;
		}
	}
}

/* ---Google Map Related Stuff--- */
#google-map {
	height: 400px;
}

/* ---Post Blockquote--- */
.post-blockquote {
	padding: 25px 0 0 60px;
	@include breakpoint(max-md_device){
		padding: 20px 0 0;
	}
	blockquote {
		position: relative;
		padding-left: 30px;
		margin: 0 0 20px;
		font-style: italic;
		&:before {
			content: "";
			background: $atlantis-color;
			position: absolute;
			width: 5px;
			height: 100%;
			top: 0;
			left: 0;
		}
		p {
			@include typography(24px, 40px);
			letter-spacing: 1px;
			color: $nobel-color;
		}
	}
}

/* ---Feedback Form--- */
.feedback-form{
	.feedback-post_box{
		padding-top: 20px;
		.group-input{
			display: flex;
			@include breakpoint(max-xxs_device){
				flex-direction: column;
			}
			input{
				&.input-field{
					background-color: $white-smoke_color;
					border: 1px solid $white-smoke_color;
					@extend %basetransition;
					margin-bottom: 30px;
					border-radius: 5px;
					padding: 0 15px;
					font-size: 14px;
					width: 100%;
					height: 50px;
					&::placeholder{
						color: $nobel-color;
					}
					&:hover{
						border-color: $text-color;
					}
					&:focus{
						border-color: $text-color;
					}
				}
			}
		}
		textarea{
			&.textarea-field{
				background-color: $white-smoke_color;
				border: 1px solid $white-smoke_color;
				@extend %basetransition;
				margin-bottom: 25px;
				border-radius: 5px;
				padding: 15px;
				width: 100%;
				height: 235px;
				&::placeholder{
					color: $nobel-color;
				}
				&:hover{
					border-color: $text-color;
				}
				&:focus{
					border-color: $text-color;
				}
			}
		}
	}
}

/* ---Image Hover Effect--- */
.img-hover_effect{
	.single-img{
		position: relative;
		overflow: hidden;
		&:before{
			background-color: #1b1b1b;
			content: "";
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
			pointer-events: none;
			z-index: 1;
			@extend %basetransition;
		}
		img{
			@extend %basetransition;
		}
	}
	&:hover{
		.single-img{
			&:before{
				opacity: 0.3;
			}
			img{
				transform: scale(1.05);
			}
		}
	}
}
.img-hover_effect-2{
	.single-img{
		position: relative;
		overflow: hidden;
		img{
			@extend %basetransition;
			&.primary-img{
				position: relative;
				top: 0;
				left: 0;
			}
			&.secondary-img{
				position: absolute;
				top: 0;
				left: 0;
				transform: translateY(-100%);
			}
		}
	}
	&:hover{
		.single-img{
			img{
				&.primary-img{
					top: 100%;
				}
				&.secondary-img{
					transform: none;
				}
			}
		}
	}
}
.img-hover_effect-3{
	position: relative;
	cursor: pointer;
	height: 100%;
	&:before{
		background-color: $white-color;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
		opacity: 0;
		@extend %basetransition;
		pointer-events: none;
	}
	.single-img{
		position: relative;
		&:before{
			background-color: $primary-color;
			content: "";
			width: 0;
			height: 4px;
			top: 0;
			left: auto;
			right: 0;
			position: absolute;
			z-index: 1;
			transition: 0.3s;
		}
		&:after{
			background-color: $primary-color;
			content: "";
			width: 4px;
			height: 0;
			top: auto;
			bottom: 0;
			right: 0;
			position: absolute;
			z-index: 1;
			transition: 0.3s 0.25s;
		}
		.inner-img{
			&:before{
				background-color: $primary-color;
				content: "";
				width: 0;
				height: 4px;
				bottom: 0;
				left: 0;
				right: auto;
				position: absolute;
				z-index: 1;
				transition: 0.3s 0.50s;
			}
			&:after{
				background-color: $primary-color;
				content: "";
				width: 4px;
				height: 0;
				top: 0;
				bottom: auto;
				left: 0;
				position: absolute;
				z-index: 1;
				transition: 0.3s 0.75s;
			}
			.inner-content{
				position: absolute;
				left: 0;
				bottom: -30px;
				padding: 25px 50px;
				z-index: 2;
				@extend %basetransition;
				opacity: 0;
				overflow: hidden;
				@include breakpoint(max-lg_device){
					padding: 25px;
				}
				span{
					&.project-category{
						padding-bottom: 15px;
						color: $nobel-color;
						display: block;
					}
				}
				h3{
					&.heading{
						@include typography(24px, 29.6px);
					}
				}
			}
		}
	}
	&:hover{
		&:before{
			opacity: 1;
		}
		.single-img{
			&:before{
				width: 100%;
				right: auto;
				left: 0;
			}
			&:after{
				height: 100%;
				bottom: auto;
				top: 0;
			}
			.inner-img{
				&:before{
					width: 100%;
					right: 0;
					left: auto;
				}
				&:after{
					height: 100%;
					top: auto;
					bottom: 0;
				}
				.inner-content{
					bottom: 0;
					opacity: 1;
					overflow: visible;
				}
			}
		}
	}
}
.img-hover_effect-4{
	position: relative;
	&:before{
		background-color: $primary-color;
		content: "";
		width: 0;
		height: 4px;
		top: 0;
		left: auto;
		right: 0;
		position: absolute;
		z-index: 1;
		transition: 0.3s;
	}
	&:after{
		background-color: $primary-color;
		content: "";
		width: 4px;
		height: 0;
		top: auto;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: 1;
		transition: 0.3s 0.25s;
	}
	.hover-border{
		&:before{
			background-color: $primary-color;
			content: "";
			width: 0;
			height: 4px;
			bottom: 0;
			left: 0;
			right: auto;
			position: absolute;
			z-index: 1;
			transition: 0.3s 0.50s;
		}
		&:after{
			background-color: $primary-color;
			content: "";
			width: 4px;
			height: 0;
			top: 0;
			bottom: auto;
			left: 0;
			position: absolute;
			z-index: 1;
			transition: 0.3s 0.75s;
		}
	}
	&:hover{
		&:before{
			width: 100%;
			right: auto;
			left: 0;
		}
		&:after{
			height: 100%;
			bottom: auto;
			top: 0;
		}
		.hover-border{
			&:before{
				width: 100%;
				right: 0;
				left: auto;
			}
			&:after{
				height: 100%;
				top: auto;
				bottom: 0;
			}
		}
	}
}

/* ---Global Overlay--- */
.global-overlay {
	background-color: rgba(0,0,0,.5);
	@extend %basetransition;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 99;
	visibility: hidden;
	&.overlay-open {
		visibility: visible;
		cursor: pointer;
	}
}

/* ---Swiper Pagination--- */
.swiper-pagination {
	span{
		&.swiper-pagination-bullet{
			background-color: $primary-color;
		}
	}
	&.position-middle_right{
		top: 50%;
		right: 80px;
		transform: translateY(-50%);
		width: auto;
		left: auto;
		bottom: auto;
		@include breakpoint(max-lg_device){
			right: 40px;
		}
		@include breakpoint(max-sm_device){
			right: 20px;
		}
		span{
			&.swiper-pagination-bullet {
				background-color: $white-color;
				@extend %basetransition;
				position: relative;
				width: 12px;
				height: 12px;
				display: block;
				cursor: pointer;
				opacity: 1;
				&:not(:last-child){
					margin-bottom: 30px;
				}
				&.swiper-pagination-bullet-active{
					background-color: $primary-color-2;
					&:before {
						background-color: rgba(238, 67, 0, 0.25);
						content: "";
						width: 18px;
						height: 18px;
						border-radius: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
				&:hover {
					background-color: $primary-color-2;
					&:before{
						background-color: rgba(238, 67, 0, 0.25);
						content: "";
						width: 18px;
						height: 18px;
						border-radius: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
}

/* ---baroque's Newsletters Popup--- */
.popup_wrapper {
	background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
	height: 100%;
	opacity: 0;
	position: fixed;
	@extend %basetransition;
	visibility: hidden;
	width: 100%;
	z-index: 9999999;
	@include breakpoint (max-md_device) {
		display: none;
	}
	.test {
		background: $white-color;
		background-position: right;
		background-size: contain;
		background-repeat: no-repeat;
		bottom: 0;
		height: 425px;
		left: 0;
		margin: auto;
		max-width: 870px;
		padding: 50px 50px 50px;
		position: absolute;
		right: 0;
		top: 0;
		@include breakpoint (max-large) {
			max-width: 810px;
		}
		.popup_off {
			background: $text-color;
			color: $white-color;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;
			line-height: 43px;
			display: block;
			cursor: pointer;
			position: absolute;
			text-align: center;
			text-transform: uppercase;
			@extend %basetransition;
			font-size: 20px;
			&:hover{
				background-color: $primary-color;
			}
		}
	}
	.subscribe-area {
		display: flex;
		align-items: center;
		height: 100%;
		.subscribe-img{
			background-color: #f0daca;
			width: 40%;
			.subscribe-inner_img{
				margin: -20px -20px 20px 20px;
			}
		}
		.subscribe-content{
			padding-left: 80px;
			width: 60%;
			text-align: center;
			> h2 {
				color: $secondary-color;
				font-weight: 600;
				font-size: 30px;
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 20px;
			}
			> p {
				margin: 0;
				max-width: 580px;
				padding-bottom: 25px;
				color: $secondary-color;
				font-size: 16px;
			}
			.subscribe-form-group {
				display: flex;
				justify-content: center;
				form {
					&.subscribe-form{
						position: relative;
						input {
							background: $white-color;
							border: 1px solid $border-color !important;
							color: $secondary-color;
							border: 0 none;
							height: 50px;
							padding: 0 150px 0 20px;
							width: 100%;
							&::placeholder{
								color: $secondary-color;
							}
						}
						button {
							background: $primary-color;
							color: $white-color;
							top: 0;
							right: 0;
							position: absolute;
							width: 130px;
							height: 50px;
							line-height: 50px;
							border: 0;
							display: block;
							text-transform: uppercase;
							@extend %basetransition;
							&:hover{
								background-color: $primary-color;
							}
						}
					}
					}
			}
			.subscribe-bottom {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 25px;
				input {
					margin-right: 5px;
				}
				label {
					margin-bottom: 0;
					color: $secondary-color;
				}
			}
		}
	}
}