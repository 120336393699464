/*----------------------------------------*/
/*  16 - Components - Newsletter
/*----------------------------------------*/
/* ---Newsletter Header--- */
.news-header{
    @include breakpoint(max-sm_device){
        padding-bottom: 25px;
        text-align: center;
    }
    h2{
        &.heading{
            @include typography(24px, 28.8px);
            padding-bottom: 5px;
        }
    }
}

/* ---Newsletter Form--- */
.newsletter-form_wrap {
	.subscribe-form {
		position: relative;
		.newsletter-input {
			border: 1px solid $border-color;
			height: 50px;
			width: 100%;
            padding: 0 15px;
            @extend %basetransition;
            &:focus{
                border: 1px solid rgba(0,0,0,.15);
            }
		}
		.newsletter-btn {
			background-color: $primary-color;
            @extend %basetransition;
			color: $white-color;
            padding: 0 30px;
            height: 50px;
            line-height: 50px;
			text-align: center;
            position: absolute;
            top: 0;
            right: 0;
            @include breakpoint(max-xxs_device){
                margin-top: 20px;
                position: unset;
                width: 100%;
            }
			&:hover{
                background-color: $secondary-color;
            }
		}
	}
}