/*----------------------------------------*/
/*  12 - Components - Button
/*----------------------------------------*/
/* ---baroque's Button Position--- */
.button-wrap{
	display: flex;
	&.position-center{
		justify-content: center;
	}
}

/* ---baroque's Button--- */
.baroque-btn {
	background-color: $primary-color;
    color: $white-color;
	width: 200px;
	height: 55px;
	line-height: 55px;
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-size: 20px;
	@extend %basetransition;
	font-family: 'Work Sans';
	@include breakpoint(max-sm_device){
		width: 180px;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
	}
	&:hover{
		color: $white-color;
	}
}

/* ---Button Size--- */
.fullwidth-btn{
	width: 100%;
}
.btn-md_size{
	width: 150px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
}
.btn-lg_size{
	width: 210px;
}
.btn-xl_size{
	width: auto;
	padding: 0 30px;
}

/* ---Additional Button--- */

/* ---Group Button--- */
.group-btn_wrap{
	display: flex;
	[class*="baroque-btn"]{
		&:not(:last-child){
			margin-right: 25px;
		}
	}
}

/* --Read More Button-- */
.btn-wrap{
	&.inner-img_style{
		background-color: $white-color;
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 10px 15px;
		z-index: 1;
	}
	.readmore-btn{
		font-family: 'Work Sans';
		color: $secondary-color;
		i{
			color: $secondary-color;
			font-size: 12px;
		}
		&.with-border{
			border-bottom: 1px solid $primary-color;
			@include typography(18px, 28.8px, 500);
			text-transform: uppercase;
			color: $primary-color;
		}
	}
}

/* ---Fixed Button--- */
.fixed-btn_wrap {
	display: none;
	@media (min-width: 1367px) {
		display: block;
		position: fixed;
		top: 50%;
		left: 10px;
		transform: translateY(-50%) rotate(-90deg);
		a{
			text-transform: uppercase;
			color: $secondary-color;
		}
	}
}