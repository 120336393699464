/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-charcoal_color{
    background-color: $charcoal-color;
}
.bg-denim_color{
    background-color: $primary-color;
}
.bg-smoke_color{
    background-color: $white-smoke_color;
}
.bg-white_color{
    background-color: $white-color;
}
.bg-snow_color{
    background-color: $snow-color;
}
.bg-dark_color{
    background-color: $secondary-color;
}
.bg-nero_color{
    background-color: $nero-color;
}

/* ---Text Color Variation--- */
.text-white{
    *{
        color: $white-color;
    }
}
.text-secondary_color{
    *{
        color: $secondary-color;
    }
}

/* ---Template Color Variation--- */
@for $i from 1 through length($colorList) {
    .template-color-#{$i} {
        a{
            &:hover {
				color: nth($colorList, $i);
			}
        }
        [class*="baroque-btn"]{
            &:hover{
                color: $white-color;
            }
        }
        /* ---Button--- */
        .btn-wrap{
            .readmore-btn{
                &:hover{
                    color: nth($colorList, $i);
                }
            }
        }
        /* ---Dropdown--- */
        .baroque-dropdown{
            li {
                a {
                    color: $nobel-color;
                    &:hover{
                        color: nth($colorList, $i);
                    }
                }
            }
            li{
                &.submenu-holder{
                    &:hover{
                        a{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }
        /* ---Megamenu--- */
        .ba-megamenu{
            > li{
                a{
                    color: $nobel-color;
                    &:hover{
                        color: nth($colorList, $i);
                    }
                }
            }
        }
        /* ---Minicart--- */
        .minicart-wrap{
            span{
                &.cart-counter{
                    background-color: nth($colorList, $i);
                }
            }
        }
        /* ---Offcanvas Language--- */
        .offcanvas-language{
            ul{
                li{
                    &.active{
                        a{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }
        /* ---Project Tab--- */
        .project-tab{
            ul{
                li{
                    a{
                        &.active{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }
        /* ---Paginatoin Area--- */
        .paginatoin-area-2{
            .pagination-box{
                li{
                    &.active{
                        > a{
                            background-color: nth($colorList, $i);
                            border-color: nth($colorList, $i);
                            color: $white-color;
                        }
                    }
                    &:hover{
                        > a{
                            background-color: nth($colorList, $i);
                            border-color: nth($colorList, $i);
                            color: $white-color;
                        }
                    }
                }
            }
        }
        /* ---Footer--- */
        .footer-widgets_area{
            .footer-widget{
                li{
                    a{
                        &:hover{
                            color: nth($colorList, $i);
                        }
                    }
                    &.language-menu{
                        &:hover{
                            > a{
                                color: nth($colorList, $i);
                            }
                        }
                    }
                }
            }
        }
    }
}