/*----------------------------------------*/
/*  19 - Components - Service
/*----------------------------------------*/
.service-area{
    .service-title{
        @include breakpoint(max-md_device){
            padding-bottom: 25px;
        }
        h2{
            &.heading{
                @include breakpoint(max-sm_device){
                    padding-bottom: 20px;
                }
            }
        }
    }
}
.service-item{
    position: relative;
    .service-content{
        .inner-content{
            background-color: $white-color;
            width: calc(100% - 100px);
            padding: 30px 30px 0;
            margin-top: -100px;
            position: relative;
            @include breakpoint(max-md_device){
                width: 100%;
                padding: 30px 0 0;
                margin-top: 0;
            }
            span{
                @include typography(18px, 28.8px);
                padding-bottom: 15px;
                display: block;
            }
            h3{
                &.heading{
                    padding-bottom: 35px;
                    a{
                        @include typography(24px, 33.6px);
                        text-transform: uppercase;
                        font-family: 'Work Sans';
                        color: $secondary-color;
                        @include breakpoint(max-lg_device){
                            font-size: 19px;
                            line-height: 29.6px;
                        }
                    }
                }
            }
            &.full-width{
                width: 100%;
            }
        }
    }
    &.style-two{
        @include breakpoint(max-md_device){
            padding-top: 90px;
        }
        .service-img{
            position: relative;
        }
        .service-content{
            .inner-content{
                margin-top: 0;
                padding: 30px 0 0;
            }
        }
    }
}
.migrate-item_area{
    .service-title{
        span{
            &.category{
                @include typography(16px, 16px);
                color: $secondary-color;
                padding-bottom: 40px;
                display: block;
            }
        }
        h2{
            &.heading{
                @include typography(48px, 57px);
                letter-spacing: -1.5px;
                padding-bottom: 65px;
                max-width: 95%;
                @include breakpoint(max-md_device){
                    font-size: 30px;
                    line-height: 39px;
                }
            }
        }
        .btn-wrap{
            a{
                font-size: 25px;
            }
        }
    }
    .service-item{
        .service-content{
            .inner-content{
                span{
                    @include typography(14px, 22.4px, 600);
                    text-transform: uppercase;
                    color: $primary-color-2;
                    em{
                        &.meta{
                            color: $text-color;
                            @include typography(14px, 22px, 400);
                            font-style: normal;
                        }
                    }
                }
                h3{
                    &.heading{
                        max-width: 84%;
                        padding-bottom: 0;
                        a{
                            @include typography(30px, 36px);
                            letter-spacing: -0.5px;
                            text-transform: none;
                            @include breakpoint(max-md_device){
                                font-size: 25px;
                                line-height: 31px;
                            }
                        }
                    }
                }
                &.md-size{
                    @include breakpoint(min-lg_device){
                        width: calc(100% - 120px);
                    }
                    span{
                        @include typography(14px, 22.8px);
                    }
                    h3{
                        &.heading{
                            max-width: 100%;
                        }
                    }
                }
                &.sm-size{
                    @include breakpoint(min-lg_device){
                        width: 280px;
                    }
                    span{
                        @include typography(14px, 22.8px);
                    }
                    h3{
                        &.heading{
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
    .specific-newsletter{
        @include breakpoint(max-sm_device){
            padding-bottom: 100px;
        }
        h2{
            &.heading{
                @include typography(48px, 57.6px);
                padding-bottom: 20px;
                @include breakpoint(max-md_device){
                    font-size: 30px;
                    line-height: 39.6px;
                }
            }
        }
        p{
            &.short-desc{
                @include typography(14px, 22.4px);
                letter-spacing: -0.2px;
                padding-bottom: 55px;
                max-width: 76%;
                @include breakpoint(max-sm_device){
                    padding-bottom: 0;
                }
                span{
                    color: $secondary-color;
                    font-weight: 600;
                }
            }
        }
        form{
            &.newsletters-form{
                .subscribe-form{
                    position: relative;
                    input{
                        &.newsletter-input{
                            border: 0;
                            border-bottom: 1px solid $secondary-color;
                            width: 100%;
                            height: 50px;
                            padding-right: 30px;
                        }
                    }
                    .newsletter-btn{
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        i{
                            color: $primary-color-2;
                        }
                    }
                }
            }
        }
    }
}