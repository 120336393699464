/*----------------------------------------*/
/*  29 - Components - Grid Background
/*----------------------------------------*/
.mouseover-effect{
    ul{
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        li{
            display: inline-block;
            width: 25%;
            position: relative;
            height: 350px;
            cursor: pointer;
            &:not(:nth-child(n+9)){
                margin-bottom: 30px;
            }
            @include breakpoint(max-lg_device){
                width: 33.333333%;
                &:not(:nth-child(n+10)){
                    margin-bottom: 30px;
                }
            }
            @include breakpoint(max-md_device){
                width: 50%;
            }
            @include breakpoint(max-xs_device){
                width: 100%;
                &:not(:last-child){
                    margin-bottom: 30px;
                }
            }
            .inner-list{
                border: 1px solid $border-color;
                padding: 30px;
                height: 100%;
                margin-left: 15px;
                margin-right: 15px;
                .single-img{
                    height: 100%;
                    .grid-bg{
                        background-repeat: no-repeat;
                        background-position: bottom;
                        background-size: cover;
                        height: 100vh;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        z-index: -1;
                        opacity: 0.5;
                        overflow: hidden;
                        @extend %basetransition;
                    }
                    .grid-content{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        height: 100%;
                        span{
                            @include typography(14px, 22.4px);
                            padding-bottom: 10px;
                            display: block;
                        }
                        h2{
                            &.title{
                                a{
                                    @include typography(30px, 30px);
                                    letter-spacing: -0.5px;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
            &.active{
                .inner-list{
                    background-color: $primary-color-2;
                    border-color: $primary-color-2;
                    .single-img{
                        .grid-bg{
                            background-image: url('../images/grid-background/1.jpg');
                            opacity: 1;
                            overflow: visible;
                            animation: kenburns-top 5s linear 1 normal both;
                            &.grid-bg-2{
                                background-image: url('../images/grid-background/2.jpg');
                            }
                            &.grid-bg-3{
                                background-image: url('../images/grid-background/3.jpg');
                            }
                            &.grid-bg-4{
                                background-image: url('../images/grid-background/4.jpg');
                            }
                            &.grid-bg-5{
                                background-image: url('../images/grid-background/5.jpg');
                            }
                            &.grid-bg-6{
                                background-image: url('../images/grid-background/6.jpg');
                            }
                            &.grid-bg-7{
                                background-image: url('../images/grid-background/7.jpg');
                            }
                            &.grid-bg-8{
                                background-image: url('../images/grid-background/8.jpg');
                            }
                            &.grid-bg-9{
                                background-image: url('../images/grid-background/9.jpg');
                            }
                            &.grid-bg-10{
                                background-image: url('../images/grid-background/10.jpg');
                            }
                            &.grid-bg-11{
                                background-image: url('../images/grid-background/11.jpg');
                            }
                        }
                    }
                }
            }
            &:hover{
                .inner-list{
                    background-color: $primary-color-2;
                    border-color: $primary-color-2;
                }
            }
        }
    }
}